import * as React from 'react';
import styled from 'styled-components';
import { IconMap, IconType } from '@adecco/base-app/src/components/atoms/Icon/iconMap';

export interface IIcon {
  name: IconType;
  height?: number;
  width?: number;
  className?: string;
  fill?: string;
  opacity?: number;
}

interface IconStyledProps {
  width?: number | null;
  height?: number | null;
  fill?: string | null;
  opacity?: number;
}

export const IconStyled = styled.span<IconStyledProps>`
  display: inline-block;
  height: 100%;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;

    > g {
      ${(props) => (props.opacity ? `opacity: ${props.opacity}` : null)};
    }

    path {
      ${(props) => (props.fill ? `fill: ${props.fill}` : null)};
    }
  }
`;

const Icon: React.FunctionComponent<IIcon> = ({
  name,
  width = null,
  height = null,
  className,
  fill = null,
  opacity,
}) => {
  const style = {
    width: width ? `${width}px` : 'none',
    height: height ? `${height}px` : 'none',
  };

  return (
    <IconStyled className={className} fill={fill} opacity={opacity} style={style}>
      {React.createElement<{}>(IconMap[name] || IconMap.time)}
    </IconStyled>
  );
};

export default Icon;
