function getDeviceType(userAgent: string) {
  const tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
  const mobRegex =
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

  if (tabletRegex.test(userAgent)) return 'tablet';
  if (mobRegex.test(userAgent)) return 'mobile';
  return 'desktop';
}

function getSystemVersion(userAgent: string) {
  if (userAgent.indexOf('Win') !== -1) return 'Windows OS';
  if (userAgent.indexOf('Mac') !== -1) return 'MacOS';
  if (userAgent.indexOf('X11') !== -1) return 'UNIX OS';
  if (userAgent.indexOf('Linux') !== -1) return 'Linux OS';
  return null;
}

function getBrowserVersion(userAgent: string) {
  const re = /(firefox|msie|chrome|safari)[/\s]([\d.]+)/gi;
  const version = userAgent.match(re);
  return Array.isArray(version) ? version[0] : null;
}

export const getClientInfo = () => {
  const clientInfo: string[] = [];

  if (typeof window !== 'undefined') {
    const { userAgent } = window.navigator;
    const deviceType = getDeviceType(userAgent);
    clientInfo.push(deviceType);
    const systemVersion = getSystemVersion(userAgent);
    if (systemVersion) clientInfo.push(systemVersion);
    const browserVersion = getBrowserVersion(userAgent);
    if (browserVersion) clientInfo.push(browserVersion);
  }

  return clientInfo.join();
};
