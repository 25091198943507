import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { moreThan, lessThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import CircleIcon from '@adecco/base-app/src/components/atoms/CircleIcon/CircleIcon';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import { IconType } from '@adecco/base-app/src/components/atoms/Icon/iconMap';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';

export interface IProgressBarItem {
  icon: IconType;
  label: string;
  state: 'done' | 'current' | 'future' | 'finished';
  showTitleMobile?: boolean;
}

export interface IProgressBar {
  items: Array<IProgressBarItem>;
}

const ProgressBarStyled = styled.div`
  border-radius: ${(props) => props.theme.radius.panel};
  border: ${(props) => props.theme.borders.progressBar};
  padding: ${(props) => props.theme.paddings.panel};
  border-radius: ${(props) => props.theme.radius.progressBar};
  display: flex;
  padding: 0;
  margin-bottom: ${(props) => props.theme.margins.progressBar};
  overflow: hidden;
  background-color: ${(props) => props.theme.backgrounds.progressBar};

  ${lessThan('md')} {
    margin-inline: ${({ theme }) => theme.margins.progressBarSmallScreen};
  }

  @media print {
    display: none;
  }
`;

interface IProgressBarItemStyled {
  state: 'done' | 'current' | 'future' | 'finished';
}

interface IItemContent {
  state: 'done' | 'current' | 'future' | 'finished';
  current: boolean;
}

const ProgressBarItem = styled.div<IProgressBarItemStyled>`
  display: flex;
  position: relative;
  padding: 0 20px 0 10px;
  background-color: ${(props) => props.theme.backgrounds.progressBarItem[props.state]};

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 30px;
    height: 140%;
    left: 0;
    background-color: ${(props) => props.theme.backgrounds.progressBarItem[props.state]};
    border-left: ${(props) =>
      `${props.theme.borders.progressBarItemWidth} solid ${props.theme.borders.progressBarItemColor}`};
  }

  &:before {
    bottom: ${(props) => `calc(50% - ${props.theme.borders.progressBarItemWidth} / 2)`};
    transform-origin: bottom left;
    transform: rotate(-25deg);
  }

  &:after {
    top: ${(props) => `calc(50% - ${props.theme.borders.progressBarItemWidth} / 2)`};
    transform-origin: top left;
    transform: rotate(25deg);
  }

  &:first-child {
    &:before,
    &:after {
      display: none;
    }
  }

  &:last-child {
    flex: 1 0 auto;
    padding: 0 0 0 10px;
  }

  ${lessThan('md')} {
    flex: ${(props) => (props.state === 'future' ? '0 1 auto' : '1 0 auto')};

    &:last-child {
      flex: 0 1 auto;
    }
  }
`;

const ItemIcon = styled.div`
  padding: ${(props) => props.theme.paddings.progressBarIcon};
  position: relative;
  z-index: 2;

  ${moreThan('md')} {
    padding: ${(props) => props.theme.paddings.progressBarIconWideScreen};
  }
`;

const ItemContent = styled.div<IItemContent>`
  font-family: ${(props) => props.theme.fonts.Copy};
  font-size: ${calcrem(14)};
  line-height: ${calcrem(17)};
  align-items: center;
  display: none;
  color: ${({ theme, state }) => theme.colors.progressBarItem[state]};
  ${(props) => (props.current ? 'display:flex' : null)};

  &:last-child {
    margin-right: ${(props) => (props.current ? '20px' : '0')};
  }

  ${moreThan('md')} {
    display: flex;
  }
`;

const Done = styled.span`
  display: ${({ theme }) => theme.displays.progressBarDone};
  width: 14px;
  height: 14px;
  background-color: ${(props) => props.theme.backgrounds.progressBarDone};
  border-radius: ${(props) => props.theme.radius.rounded};
  position: absolute;
  inset: ${(props) => props.theme.positions.doneIcon};

  ${moreThan('md')} {
    width: 24px;
    height: 24px;
    right: 10px;
    bottom: 10px;
  }
`;

const ProgressBar: React.FunctionComponent<IProgressBar> = ({ items }) => {
  const theme = useTheme();

  return (
    <ProgressBarStyled>
      {items.map(({ icon, label, state, showTitleMobile }, index) => (
        <ProgressBarItem key={`item-${index}`} state={state}>
          <ItemIcon>
            <CircleIcon
              name={icon}
              fill={theme.colors.progressBarIcon[state]}
              background={state === 'finished' ? theme.backgrounds.circleIcon : 'transparent'}
            />
            {state === 'done' ? (
              <Done>
                <Icon name="checkmark" fill="#FFFFFF" />
              </Done>
            ) : null}
          </ItemIcon>
          <ItemContent
            color={theme.colors.progressBarIcon[state]}
            current={state === 'current' || state === 'finished' || showTitleMobile || false}
            state={state}
          >
            {label}
          </ItemContent>
        </ProgressBarItem>
      ))}
    </ProgressBarStyled>
  );
};
export default ProgressBar;
