import * as React from 'react';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import TextField from '@adecco/base-app/src/components/atoms/TextField/TextField';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { DatalistInputType } from './FormElementRenderer.types';
import { configInputFields } from './configInputFields';

const DatalistInput: React.FunctionComponent<DatalistInputType> = ({
  type,
  register,
  errors,
  size,
  required,
  branches,
}) => {
  const { t } = useAppContext();

  const filteredBranches = branches?.filter((branch) => Boolean(branch?.standortExtern));

  if (!filteredBranches) return null;

  const setBranchValue = (value: string) => {
    const branch = filteredBranches.find((branch) => branch?.standortExtern === value);
    return branch?.sid;
  };

  return (
    <FieldGroup size={1 / size}>
      <TextField
        register={register(configInputFields[type].name, {
          setValueAs: setBranchValue,
          required,
        })}
        name={configInputFields[type].name}
        label={t(configInputFields[type].label)}
        helpText={configInputFields[type].help ? t(configInputFields[type].help!) : undefined}
        required={required}
        list="branchesList"
        error={!!errors[configInputFields[type].name]}
      />
      <datalist id="branchesList">
        {filteredBranches?.map((branch) => {
          return (
            <option key={branch?.sid} value={branch?.standortExtern}>
              {`${branch?.strasse || ''} ${branch?.plz || ''} ${branch?.ort || ''}`}
            </option>
          );
        })}
      </datalist>
      {errors[configInputFields[type].name] && (
        <ErrorMessage
          text={t(configInputFields[type].error)}
          elementName={configInputFields[type].name}
        />
      )}
    </FieldGroup>
  );
};

export default DatalistInput;
