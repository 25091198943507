import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  useForm,
  FormProvider,
  SubmitErrorHandler,
  FieldValues,
  SubmitHandler,
} from 'react-hook-form';
import { useRouter } from 'next/router';
import Form from '@adecco/base-app/src/components/atoms/Form/Form';
import FormRow from '@adecco/base-app/src/components/atoms/FormRow/FormRow';
import TextField from '@adecco/base-app/src/components/atoms/TextField/TextField';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import FormSection from '@adecco/base-app/src/components/atoms/FormSection/FormSection';
import FormHeader from '@adecco/base-app/src/components/atoms/FormHeader/FormHeader';
import Button from '@adecco/base-app/src/components/atoms/Button/Button';
import InfoText from '@adecco/base-app/src/components/atoms/InfoText/InfoText';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { FileUploadBlock } from '@adecco/base-app/src/components/molecules/FileUploadBlock/FileUploadBlock';
import { saveData } from '@adecco/base-app/src/utilities/forms/savedata';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import LegalNoticeModal from '@adecco/base-app/src/components/molecules/LegalNoticeModal/LegalNoticeModal';
import { labeltext } from '@adecco/base-app/src/styles/typography';
import { isMobile, moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { useCreateAppMutation } from '@adecco/base-app/src/gql/schema/generated';
import transformJobUrl from '@adecco/base-app/src/utilities/transformJobUrl';
import PhoneInput from '@adecco/base-app/src/components/atoms/PhoneInput/PhoneInput';
import { emailPattern } from '@adecco/base-app/src/utilities/forms/regExPatterns';
import transformLink from '@adecco/base-app/src/utilities/transformLink';
import { routeNames } from '@adecco/base-app/src/constants/routeNames/routeNames';
import LegalCheckboxes from '@adecco/base-app/src/components/molecules/LegalCheckboxes/LegalCheckboxes';
import { validatedFiles } from '@adecco/base-app/src/utilities/forms/validateFiles';
import { PageType } from '@adecco/base-app/pages/job/apply/apply';
import { usePatterns } from '@adecco/base-app/src/hooks/usePatterns';
import PlacesAutocomplete from '@adecco/base-app/src/components/molecules/PlacesAutocompete/PlacesAutocomplete';
import {
  IErrors,
  useApplicationFieldErrors,
} from '@adecco/base-app/src/hooks/useApplicationFieldErrors';
import MonthsConsentDropdown from '@adecco/base-app/src/components/atoms/MonthsConsentDropdown/MonthsConsentDropdown';
import SelectField from '@adecco/base-app/src/components/atoms/SelectField/SelectField';
import { getGreetValues } from '@adecco/base-app/src/utilities/transformText/getGreetValues';
import { getModalHeaderTitle, getModalContent } from '@adecco/base-app/src/utilities/getModalData';
import { getZipCodeHelpText } from '@adecco/base-app/src/utilities/getZipCodeHelpText';
import { useGetQueryString } from '@adecco/base-app/src/hooks/useRouterQueries';
import { monaAi } from '@adecco/base-app/src/utilities/forms/monaAi';
import { useBrowserStorage } from '@adecco/base-app/src/hooks/useBrowserStorage';

export interface IApplicationForm {
  branchId?: string;
  cvMandatory?: boolean;
  id?: string;
  jobLocation?: string | null;
  kldbNumber?: string;
  occupation?: string;
  officeId?: string;
  schaltungsId?: string;
  setFormIsDirty?: (value: boolean) => void;
  sourceAdId?: string;
  sourceJobId?: string;
  monaAiId?: string;
  monaAiPostingId?: string;
  whatsappAvailable?: boolean;
}

export const AppForm = styled(Form)`
  margin-bottom: ${(props) => props.theme.margins.appForm};
  padding-bottom: 0;
  padding-top: 0;

  ${moreThan('lg')} {
    margin-bottom: ${(props) => props.theme.margins.appFormWideScreen};
  }
`;

export const AppButton = styled(Button)`
  border-radius: ${(props) => props.theme.radius.smallButton};
  font-size: ${(props) => props.theme.fontSizes.button};
  margin-bottom: ${(props) => props.theme.margins.button};
  padding: ${calcrem(10)} ${calcrem(40)} ${calcrem(14)} ${calcrem(40)};
  width: 100%;

  ${moreThan('lg')} {
    border-radius: ${(props) => props.theme.radius.panel};
    margin-bottom: ${(props) => props.theme.margins.buttonWideScreen};
    font-size: ${(props) => props.theme.fontSizes.buttonWideScreen};
    padding: ${calcrem(15)} ${calcrem(56)} ${calcrem(19)} ${calcrem(56)};
    width: auto;
  }
`;

export const AppLegend = styled.legend`
  display: block;
  ${labeltext};
  padding-bottom: ${calcrem(8)};
`;

export const AppInfoText = styled(InfoText)`
  margin-bottom: ${(props) => props.theme.margins.appInfo};

  ${moreThan('md')} {
    margin-bottom: ${(props) => props.theme.margins.appInfoWideScreen};
  }
`;

export const FormRowStyled = styled(FormRow)`
  flex-direction: ${(props) => props.theme.displays.infoButton};
`;

const ApplicationForm: React.FunctionComponent<IApplicationForm> = ({
  id,
  cvMandatory,
  schaltungsId,
  kldbNumber,
  officeId,
  branchId,
  sourceAdId,
  sourceJobId,
  occupation,
  jobLocation,
  setFormIsDirty,
  monaAiId,
  monaAiPostingId,
  whatsappAvailable,
}) => {
  const methods = useForm();
  const { formState, setValue } = methods;
  const router = useRouter();
  const {
    withDoi,
    withAgeConfirm,
    brand,
    t,
    privacyConsentVersion,
    withEmailConfirmation,
    googleMapsApiKey,
    varSource: contextVarSource,
    mobileCVException,
  } = useAppContext();
  const currentCountry = router.locale?.split('-')[0];
  const [modalLegalNoticeOpen, setModalLegalNoticeOpen] = useState(false);
  const [ageConfirmed, setAgeConfirmed] = useState(!withAgeConfirm);
  const [isSubmitted, setSubmit] = useState(false);
  const [isOptional, setIsOptional] = useState(!cvMandatory ?? true);
  const [createApplication] = useCreateAppMutation();
  const patterns = usePatterns();
  const [errorMessage, setErrorMessage] = useState(false);
  const errorMessageRef = useRef<HTMLDivElement>(null);
  const [errorsInApplication, setErrorsInApplication] = useState<IErrors>([]);
  const { privacyPolicy, termsOfUse } = methods.getValues();
  useApplicationFieldErrors({ errors: errorsInApplication, setErrorMessage, formMethods: methods });
  const qs = useGetQueryString();
  const { setItem } = useBrowserStorage();

  useEffect(() => {
    if (errorMessage) {
      if (errorMessageRef?.current) {
        window.scrollTo(0, errorMessageRef.current.offsetTop - window.screen.height / 2);
      }
    }
  }, [errorMessage]);

  const ageCheckboxHandler = () => {
    setAgeConfirmed(!ageConfirmed);
    setValue('ageconfirm', !ageConfirmed);
  };

  useEffect(() => {
    if (isMobile() && mobileCVException) {
      setIsOptional(true);
    }
  }, []);

  const submitClickHandler = () => {
    if (!termsOfUse || !privacyPolicy) {
      setModalLegalNoticeOpen(true);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    let onSubmitError = false;

    setErrorMessage(false);

    if (isSubmitted) {
      return;
    }

    setSubmit(true);

    if (!validatedFiles(values.file1) || !validatedFiles(values.file4)) {
      methods.setFocus('file1');
      return;
    }
    const sendValues = {
      ...values,
      schaltungsId,
      varType: `CDX-Jobdetailpage-${kldbNumber}`,
      sid: officeId,
      branchId: brand === 'Dis' ? branchId : null,
      fileCv: values.file1,
      fileOthers: values.file4,
      sourceAdId,
      brand,
    };

    try {
      if (monaAiId) {
        const { applicantId } = await monaAi({
          ...sendValues,
          externalID: monaAiId,
          jobPostingID: monaAiPostingId,
        });

        if (applicantId) {
          setItem('transaction_id', applicantId);
          return;
        }
      }

      const { transactionId, applicationErrors, error } = await saveData({
        mutation: createApplication,
        values: sendValues,
        id,
        countryCode: router.locale ?? '',
        privacyConsentVersion,
        contextVarSource,
      });

      if (error) {
        setErrorMessage(true);
        onSubmitError = true;
        return;
      }

      if (applicationErrors) {
        setErrorsInApplication(applicationErrors);
        const errorsFields = applicationErrors.map((error) => error?.field);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'form_error',
          event_category: errorsFields.join(', '),
          event_action: PageType ?? 'na',
        });
      }

      if (transactionId) {
        setItem('transaction_id', transactionId);
      }

      if (withDoi && values.MarketingOptIn === true) {
        await fetch('/api/salesforceDoi', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: values.email, source: brand }),
        });
      }

      if (withEmailConfirmation) {
        await fetch('/api/sendEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          }),
        });
      }
    } finally {
      setSubmit(false);

      if (!onSubmitError) {
        const hasCV = values.file1?.length > 0 ? 'cv' : 'na';
        const hasOthers = values.file4?.length > 0 ? 'doc' : 'na';
        const fileFieldType = isOptional ? 'optional' : 'required';
        const eventCategory = [hasCV, hasOthers, fileFieldType].join('_');

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'form_submit',
          event_category: eventCategory,
          event_action: 'Standard Job Form',
        });

        const path = transformJobUrl(
          sourceJobId ?? '',
          occupation ?? '',
          jobLocation ?? '',
          kldbNumber ?? ''
        );

        const pathname = transformLink(path, routeNames[currentCountry!].applicationSentRoute, {
          queryString: false,
        });

        router.push(pathname, `${pathname}${qs}`);
      }
    }
  };

  useEffect(() => {
    if (formState.isDirty) {
      if (setFormIsDirty) setFormIsDirty(true);
    }
  }, [formState.isDirty]);

  const onError: SubmitErrorHandler<FieldValues> = (errors) => {
    const errorsFields = Object.keys(errors);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'form_error',
      event_category: errorsFields.join(', '),
      event_action: PageType ?? 'na',
    });
  };

  const greetTranslationKey = 'greet-values';
  const greetTranslation = t(greetTranslationKey);
  const greetValues =
    greetTranslation === greetTranslationKey ? null : getGreetValues(greetTranslation);

  return (
    <>
      {methods.formState.errors && Object.entries(methods.formState.errors).length ? (
        <ErrorMessage text={t('form-missing-values')} elementName="job-form" />
      ) : null}
      <FormProvider {...methods}>
        <AppForm onSubmit={methods.handleSubmit(onSubmit, onError)} formName="standardJob">
          <FormHeader headline={t('form-headline')} />
          <FormSection>
            {greetValues && (
              <FormRow>
                <FieldGroup size={1 / 2}>
                  <SelectField
                    label={t('form-field-greetings')}
                    name="greetings"
                    values={greetValues}
                    register={methods.register('greeting')}
                    error={!!methods.formState.errors.greeting}
                  />
                  {methods.formState.errors.greeting && (
                    <ErrorMessage text={t('error-greetings')} elementName="greetings" />
                  )}
                </FieldGroup>
              </FormRow>
            )}
            <FormRow>
              <FieldGroup size={1 / 2}>
                <TextField
                  label={t('form-field-first-name')}
                  name="firstName"
                  register={methods.register('firstName', { required: true })}
                  error={!!methods.formState.errors.firstName}
                  required
                />
                {methods.formState.errors.firstName && (
                  <ErrorMessage text={t('error-first-name')} elementName="firstName" />
                )}
              </FieldGroup>
              <FieldGroup size={1 / 2}>
                <TextField
                  label={t('form-field-last-name')}
                  name="lastName"
                  register={methods.register('lastName', { required: true })}
                  error={!!methods.formState.errors.lastName}
                  required
                />
                {methods.formState.errors.lastName && (
                  <ErrorMessage text={t('error-last-name')} elementName="lastName" />
                )}
              </FieldGroup>
            </FormRow>
            {googleMapsApiKey && (
              <PlacesAutocomplete apiKey={googleMapsApiKey} setValue={setValue} />
            )}
            <FormRow>
              <FieldGroup size={1 / 2}>
                <FieldGroup size={4 / 5} tabletSize={8 / 10} mobileSize={3 / 4}>
                  <TextField
                    label={t('form-field-street')}
                    name="street"
                    register={methods.register('street', { required: true })}
                    error={!!methods.formState.errors.street}
                    required
                  />
                  {methods.formState.errors.street && (
                    <ErrorMessage text={t('error-street-name')} elementName="street" />
                  )}
                </FieldGroup>
                <FieldGroup size={1 / 5} tabletSize={2 / 10} mobileSize={1 / 4}>
                  <TextField
                    label={t('form-field-number')}
                    name="number"
                    register={methods.register('number', { required: true })}
                    error={!!methods.formState.errors.number}
                    required
                  />
                  {methods.formState.errors.number && (
                    <ErrorMessage text={t('error-house-number')} elementName="number" />
                  )}
                </FieldGroup>
              </FieldGroup>
              <FieldGroup size={1 / 2}>
                <FieldGroup size={1 / 2} tabletSize={1 / 2} mobileSize={2 / 5}>
                  <TextField
                    helpText={getZipCodeHelpText(
                      currentCountry,
                      !!methods.formState.errors.zipcode
                    )}
                    label={t('form-field-zipcode')}
                    name="zipcode"
                    register={methods.register('zipcode', {
                      required: true,
                      pattern: patterns['zip-code'] || undefined,
                    })}
                    error={!!methods.formState.errors.zipcode}
                    required
                  />
                  {methods.formState.errors.zipcode && (
                    <ErrorMessage text={t('error-zip-code')} elementName="zipcode" />
                  )}
                </FieldGroup>
                <FieldGroup size={1 / 2} tabletSize={1 / 2} mobileSize={3 / 5}>
                  <TextField
                    label={t('form-field-city')}
                    name="city"
                    register={methods.register('city', { required: true })}
                    error={!!methods.formState.errors.city}
                    required
                  />
                  {methods.formState.errors.city && (
                    <ErrorMessage text={t('error-city')} elementName="city" />
                  )}
                </FieldGroup>
              </FieldGroup>
            </FormRow>
            <FormRow>
              <FieldGroup size={1 / 2}>
                <PhoneInput
                  control={methods.control}
                  register={methods.register}
                  error={!!methods.formState.errors.phone}
                  required
                />
              </FieldGroup>
              <FieldGroup size={1 / 1}>
                {methods.formState.errors.phone && (
                  <ErrorMessage text={t('error-phone-number')} elementName="phone" />
                )}
              </FieldGroup>
            </FormRow>
            <FormRow>
              <FieldGroup size={1 / 2} tabletSize={1 / 2} mobileSize={1}>
                <TextField
                  label={t('form-field-email')}
                  name="email"
                  register={methods.register('email', {
                    required: true,
                    pattern: emailPattern,
                  })}
                  error={!!methods.formState.errors.email}
                  required
                />
                {methods.formState.errors.email && (
                  <ErrorMessage text={t('error-email')} elementName="email" />
                )}
              </FieldGroup>
              <FieldGroup size={1 / 2} tabletSize={1 / 2} mobileSize={1}>
                <TextField
                  label={t('form-field-email-validation')}
                  name="email_validation"
                  register={methods.register('email_validation', {
                    required: true,
                    pattern: emailPattern,
                    validate: {
                      sameEmailadress: () => {
                        const values = methods.getValues();
                        return (
                          values.email?.toLowerCase() === values.email_validation?.toLowerCase()
                        );
                      },
                    },
                  })}
                  error={!!methods.formState.errors.email}
                  required
                />
                {methods.formState.errors.email_validation && (
                  <ErrorMessage text={t('error-email-validation')} elementName="email_validation" />
                )}
              </FieldGroup>
            </FormRow>
          </FormSection>
          <FormSection>
            <FileUploadBlock
              optionalHeadline={t('form-field-optional-add-files')}
              isOptional={brand === 'Lhh' ? false : isOptional ?? false}
              errors={{
                file1: !!methods.formState.errors.file1,
                file4: !!methods.formState.errors.file4,
              }}
            />
          </FormSection>
          <FormSection>
            <LegalCheckboxes
              register={methods.register}
              setValue={methods.setValue}
              errors={methods.formState.errors}
              ageCheckboxHandler={ageCheckboxHandler}
              whatsappAvailable={whatsappAvailable}
            />
            <MonthsConsentDropdown size={1} register={methods.register} />
            <FormRowStyled>
              <FieldGroup size={1 / 2}>
                <AppButton
                  type="button"
                  variant="primary"
                  isLoading={isSubmitted}
                  onClick={() => submitClickHandler()}
                  datacy="standard-job-apply-button"
                >
                  {t('form-button-apply-now')}
                </AppButton>
              </FieldGroup>
              <FieldGroup size={1 / 2}>
                <InfoText
                  text={`${t('form-field-job-id')}: ${id}`}
                  iconName={brand === 'ProServ' ? 'information' : 'information-filled'}
                />
                <AppInfoText
                  text={t('form-info-required')}
                  iconName={brand === 'ProServ' ? 'information' : 'information-filled'}
                />
              </FieldGroup>
            </FormRowStyled>
          </FormSection>
          {errorMessage && (
            <FormSection>
              <ErrorMessage text={t('application-error-message')} elementName="job-form" />
              <div ref={errorMessageRef} />
            </FormSection>
          )}
        </AppForm>
      </FormProvider>
      <LegalNoticeModal
        headerTitle={getModalHeaderTitle(privacyPolicy, termsOfUse)}
        content={getModalContent(privacyPolicy, termsOfUse)}
        mainButtonTitle={t('modal-age-confirmation-button-confirm')}
        closeModal={() => {
          setModalLegalNoticeOpen(false);
        }}
        isOpen={modalLegalNoticeOpen}
      />
    </>
  );
};

export default ApplicationForm;
