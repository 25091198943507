import { useEffect } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { getClientInfo } from '@adecco/base-app/src/utilities/getClientInfo/getClientInfo';
import { getTransactionId } from '@adecco/base-app/src/utilities/tracking/transactionid';
import { getCookie } from '@adecco/base-app/src/utilities/cookie/cookie';

export const getContractType = (employmentType?: string) => {
  return employmentType === 'Festanstellung' ? 'contract' : 'temporary';
};

export const useDataLayer = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jobData: any,
  pageType: string,
  withTransactionID = false,
  withEmptyJobContractType = false,
  customParams: { [key: string]: string } | undefined = undefined
) => {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();
  const { t, varSource, withTracking } = useAppContext();

  useEffect(() => {
    if (!withTracking || !router.isReady) return;

    // cdx_ is appended when auto-tagging is enabled (gclid/Google Click Identifier/Google Click ID)
    const {
      cdx_source,
      cdx_medium,
      cdx_content,
      cdx_campaign,
      utm_source,
      utm_medium,
      utm_content,
      utm_campaign,
    } = router.query;

    const valuesMap = new Map(
      Object.entries({
        Festanstellung: t('job-fact-item-values-contract-permanent-position'),
        Zeitarbeit: t('job-fact-item-values-contract-temporary-1'),
        Befristet: t('job-fact-item-values-contract-temporary-2'),
        Studentenjob: t('job-fact-item-values-contract-student-job'),
        AÜ: t('job-fact-item-values-placement-temporary-1'),
        AÜO: t('job-fact-item-values-placement-temporary-2'),
        PV: t('job-fact-item-values-placement-permanent'),
        'Interne Karriere': t('job-fact-item-values-placement-internal'),
        WV: t('job-fact-item-values-placement-contract'),
      })
    );

    const mapValueTranslation = (value: string) => {
      return valuesMap.get(value) ? valuesMap.get(value) : value;
    };

    const pathParts = router.asPath.split('/');
    const folder1 = pathParts.length > 1 ? pathParts[1] : '';
    const folder2 = pathParts.length > 2 ? pathParts[2] : '';
    const jobPostingId =
      // eslint-disable-next-line no-unsafe-optional-chaining
      jobData.sourceJobId?.substr(1)?.substr(0, jobData.sourceJobId?.indexOf('-') - 1) ?? '';
    const jobContractType = withEmptyJobContractType
      ? ''
      : getContractType(jobData?.employmentType);

    window.dataLayer = window.dataLayer || [];

    window.dataLayer?.push({
      user_type: getCookie('usertype', 'prospect'),
      pageCategory: folder1,
      pageSubCategory: folder2,
      page_title: jobData?.title || '',
      pageType: pageType || '',
      pageLanguage: router.locale?.split('-')[0],
      page_path: window.location.pathname || '',
      pageURL: window.location.href || '',
      pageCountry: publicRuntimeConfig.COUNTRY.toUpperCase(),
      pageDomain: window.location.host || '',
      job_id: jobData?.sourceAdId || '',
      job_name: jobData?.title || '',
      job_city: jobData?.jobLocation || '',
      job_category_id: jobData?.kldbNumber || '',
      job_contract_type: jobContractType,
      job_posting_id: jobPostingId,
      job_category: jobData?.occupationGroup ?? '',
      job_working_day: jobData?.workHours ?? '',
      job_transaction_id: withTransactionID ? getTransactionId() : undefined,
      browsing_user_type: 'candidate',
      visitor_type: 'logged out',
      name_company: jobData.brand || '',
      postal_code: jobData.jobZipCode || '',
      type_help: jobData.placementType ? mapValueTranslation(jobData.placementType) : '',
      type_work: jobData.occupationalType || '',
      job_reference_number: jobData.sourceJobId || '',
      varSource: cdx_source || utm_source || varSource,
      varCampaign: cdx_campaign || utm_campaign,
      varContent: cdx_content || utm_content,
      varMedium: cdx_medium || utm_medium,
      varTerm: getClientInfo(),
      ...customParams,
    });
  }, [router.isReady]);
};
