const fonts = `
  @font-face {
    font-family: Houschka-Medium;
    font-weight: normal;
    font-display: swap;
    src: url('/fonts/houschka-medium.woff2') format('woff2');
    src: url('/fonts/houschka-medium.woff') format('woff');
  }
  @font-face {
    font-family: Houschka-Demibold;
    font-weight: normal;
    font-display: swap;
    src: url('/fonts/houschka-demibold.woff2') format('woff2');
    src: url('/fonts/houschka-demibold.woff') format('woff');
  }
  @font-face {
    font-family: Nunito;
    font-weight: normal;
    font-display: swap;
    src: url('/fonts/nunito.regular.woff2') format('woff2');
    src: url('/fonts/nunito.regular.woff') format('woff');
  }
  @font-face {
    font-family: Nunito;
    font-weight: bold;
    font-display: swap;
    src: url('/fonts/nunito.bold.woff2') format('woff2');
    src: url('/fonts/nunito.bold.woff') format('woff');
  }
  @font-face {
    font-family: Nunito;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/nunito.semibold.woff2') format('woff2');
    src: url('/fonts/nunito.semibold.woff') format('woff');
  }
  @font-face {
    font-family: Nunito;
    font-style: italic;
    font-weight: normal;
    font-display: swap;
    src: url('/fonts/nunito.italic.woff2') format('woff2');
    src: url('/fonts/nunito.italic.woff') format('woff');
  }
  @font-face {
    font-family: Open Sans;
    font-weight: normal;
    font-display: swap;
    src: url('/fonts/OpenSans-Regular.woff2') format('woff2');
    src: url('/fonts/OpenSans-Regular.woff') format('woff');
  }
  @font-face {
    font-family: Open Sans;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/OpenSans-Semibold.woff2') format('woff2');
    src: url('/fonts/OpenSans-Semibold.woff') format('woff');
  }
  @font-face {
    font-family: Open Sans;
    font-weight: bold;
    font-display: swap;
    src: url('/fonts/OpenSans-Bold.woff2') format('woff2');
    src: url('/fonts/OpenSans-Bold.woff') format('woff');
  }
  @font-face {
    font-family: Open Sans;
    font-weight: normal;
    font-display: swap;
    font-style: italic;
    src: url('/fonts/OpenSans-Italic.woff2') format('woff2');
    src: url('/fonts/OpenSans-Italic.woff') format('woff');
  }
`;

export default fonts;
