import styled from 'styled-components';
import * as React from 'react';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import Paragraph from '@adecco/base-app/src/components/atoms/Paragraph/Paragraph';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import { CloudinaryImageType } from '@adecco/base-app/src/gql/schema/generated';
import DynamicImage from '@adecco/base-app/src/components/atoms/DynamicImage/DynamicImage';

export interface IRecoTeaser {
  headline: string;
  subheadline: string;
  centeredHeadline?: boolean;
  text: string;
  img: {
    src?: string;
    alt: string;
  };
  link: {
    href?: string;
    description?: string;
  };
  cloudinaryImage?: CloudinaryImageType;
}

const RecoTeaserWrapper = styled.div`
  ${moreThan('md')} {
    display: flex;
  }
`;

const RecoTeaserContent = styled.div`
  ${moreThan('md')} {
    width: 65%;
    padding-right: 15px;
  }
`;

const RecoTeaserImage = styled.div`
  display: none;
  ${moreThan('md')} {
    display: block;
    width: 35%;

    img {
      width: 100%;
    }
  }
`;

const Image = styled(DynamicImage)`
  display: block;
  margin-bottom: ${(props) => props.theme.margins.image};
  width: 100%;
  ${moreThan('md')} {
    display: none;
  }
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: ${(props) => props.theme.margins.headLineBottom};
`;

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${(props) => props.theme.margins.paragraphBottom};
`;

const StyledLink = styled.a`
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: ${(props) => props.theme.radius.link};
  border: ${(props) => props.theme.borders.link};
  padding: 5px 10px;
  height: 45px;
  font-family: ${(props) => props.theme.fonts.Copy};
  font-weight: ${(props) => props.theme.fontWeights.link};
  font-size: ${(props) => props.theme.fontSizes.link};
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.black};
    opacity: 0;
    border-radius: ${(props) => props.theme.radius.link};
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: ${(props) => props.theme.opacities.buttonHover};
    }
  }
`;

const LinkText = styled.span`
  padding-left: 10px;
`;

const RecoTeaser: React.FunctionComponent<IRecoTeaser> = ({
  headline,
  subheadline,
  text,
  link,
  img,
  cloudinaryImage,
  centeredHeadline,
}) => (
  <Container>
    <RecoTeaserWrapper>
      <RecoTeaserContent>
        <Headline centered={centeredHeadline} skin={2}>
          {headline}
        </Headline>
        <StyledHeadline centered={centeredHeadline} skin={3}>
          {subheadline}
        </StyledHeadline>
        <Image image={img.src} alt={img.alt} cloudinaryImage={cloudinaryImage} />
        <StyledParagraph>{text}</StyledParagraph>
        <StyledLink href="https://cloud.info.adeccogroup.de/adecco_empfehlungen">
          <Icon name="plus" height={15} width={15} />
          <LinkText>{link.description}</LinkText>
        </StyledLink>
      </RecoTeaserContent>
      <RecoTeaserImage>
        <DynamicImage image={img.src} cloudinaryImage={cloudinaryImage} alt={img.alt} />
      </RecoTeaserImage>
    </RecoTeaserWrapper>
  </Container>
);

export default RecoTeaser;
