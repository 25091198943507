import * as React from 'react';
import styled, { css } from 'styled-components';
import { buttontext } from '@adecco/base-app/src/styles/typography';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import Spinner from '@adecco/base-app/src/components/atoms/Spinner/Spinner';
import getConfig from 'next/config';

const {
  publicRuntimeConfig: { BASEPATH: basePath },
} = getConfig();

export interface IButton {
  children: React.ReactNode;
  href?: string;
  onClick?: (event: React.MouseEvent) => void;
  variant?: string;
  type?: string;
  className?: string;
  newTab?: boolean;
  brandColor?: string;
  isLoading?: boolean;
  datacy?: string;
}

interface IButtonStyled {
  variant?: string;
  as?: 'a' | 'button';
  'aria-label'?: string;
  brandColor?: string;
}

export const buttonBase = css`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.baseButton};
  cursor: pointer;
  position: relative;
  transition: background-color 0.5s ease-in-out;
  text-align: center;
  border: 0;

  svg {
    stroke: ${(props) => props.theme.colors.baseButton};
  }

  &:hover {
    &:before {
      opacity: ${(props) => props.theme.opacities.buttonHover};
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.black};
    opacity: 0;
    border-radius: ${(props) => props.theme.radius.baseButton};
    transition: opacity 0.3s ease-in-out;
  }
`;

const ButtonStyled = styled.a<IButtonStyled>`
  ${buttonBase};
  ${buttontext};
  display: inline-block;
  border-radius: ${(props) => props.theme.radius.baseButton};
  text-decoration: none;
  padding: ${(props) => props.theme.paddings.defaultButton};
  overflow: hidden;

  @media print {
    display: none;
  }

  ${(props) =>
    props.theme.brandColor
      ? `
      background-color: white;
      color:  #333;
      border: 3px solid ${props.theme.brandColor};
      transition: box-shadow 0.2s ease-out 0s;

      svg {
        stroke: #333;
      }
      
      &:before {
        background-color: white;
        border-radius: ${props.variant === 'primary' ? '10px' : '0px'};
      }
      
      &:hover {
        box-shadow: 5px 5px 25px ${props.theme.brandColor}50;
      }
    }
    `
      : `
      svg {
        stroke: ${props.theme.colors.baseButtonText};
      }
    `}

  ${(props) =>
    props.variant === 'small'
      ? `
      padding: ${props.theme.paddings.smallButton};
      font-size: ${props.theme.fontSizes.smallButton};
      line-height:  ${props.theme.lineHeights.smallButton};
      border-radius: ${props.theme.radius.smallButton};

      &:before {
        border-radius: ${props.theme.brandColor ? '0px' : props.theme.radius.smallButton};
      }
  `
      : null}

  ${(props) =>
    props.variant === 'secondary'
      ? `
    background-color: ${props.theme.colors.secondaryButton};
    border: none;
  `
      : null}

  ${(props) =>
    props.variant === 'small-ghost'
      ? `
      padding: ${props.theme.paddings.smallButton};
      font-size: ${props.theme.fontSizes.smallButton};
      line-height:  ${props.theme.lineHeights.smallButton};
      border-radius: ${props.theme.radius.smallButton};

      &:before {
        border-radius: ${props.theme.radius.smallButton};
      }
      background-color: ${props.theme.backgrounds.smallGhostButton};
      color: ${props.theme.colors.smallGhostButton};
      border: ${props.theme.borders.smallGhostButton};

      ${moreThan('md')} {
        padding: ${props.theme.paddings.ghostButtonWideScreen};
      }

      svg {
        stroke: ${props.theme.colors.smallGhostButton};
      }
      `
      : null}

  ${(props) =>
    props.variant === 'small-secondary'
      ? `
      padding: ${props.theme.paddings.smallButton};
      font-size:${props.theme.fontSizes.smallButton};
      line-height: ${props.theme.lineHeights.smallButton};
      border-radius: ${props.theme.radius.smallButton};

      &:before {
        border-radius: ${props.theme.radius.smallButton};
      }
      background-color: ${props.theme.colors.textGrey};
  `
      : null}
`;

const ButtonContent = styled.span`
  position: relative;
  z-index: 2;
`;

const Button: React.FunctionComponent<IButton> = ({
  brandColor,
  children,
  className,
  href,
  newTab = false,
  onClick = () => undefined,
  type,
  variant = 'default',
  isLoading = false,
  datacy,
}: IButton) => {
  if (type === 'button') {
    return (
      <ButtonStyled
        onClick={onClick}
        variant={variant}
        as="button"
        className={className}
        aria-label={String(children)}
        brandColor={brandColor}
        data-cy={datacy}
      >
        <ButtonContent>{isLoading ? <Spinner /> : children}</ButtonContent>
      </ButtonStyled>
    );
  }

  const hrefWithBasePath = href && `${basePath}${href}`;

  return (
    <ButtonStyled
      href={hrefWithBasePath}
      onClick={onClick}
      variant={variant}
      className={className}
      role="button"
      target={newTab ? '_blank' : '_self'}
      brandColor={brandColor}
      data-cy={datacy}
    >
      <ButtonContent>{isLoading ? <Spinner /> : children}</ButtonContent>
    </ButtonStyled>
  );
};

export default Button;
