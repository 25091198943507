import * as React from 'react';
import styled from 'styled-components';
import { copytext } from '@adecco/base-app/src/styles/typography';
import { textFormatter } from '@adecco/base-app/src/utilities/transformText/textFormatter';

interface IParagraph {
  children: string;
  className?: string;
  margin?: boolean;
  noTransform?: boolean;
}

interface IParagraphStyled {
  margin: boolean;
}

export const ParagraphStyled = styled.div<IParagraphStyled>`
  ${copytext};
  ${(props) => (props.margin ? 'margin-bottom: 1em' : null)};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.colors.paragraph};
  }

  a {
    ${copytext};
    text-decoration: underline;
    font-family: ${(props) => props.theme.fonts.paragraphLink};
  }

  li {
    list-style-image: ${(props) => props.theme.urls?.listMarker};
    padding-left: ${(props) => props.theme.paddings.listMarker};
  }

  img {
    max-width: 100%;
  }
`;

const Paragraph: React.FunctionComponent<IParagraph> = ({
  children,
  className,
  margin = false,
  noTransform = false,
}) => {
  return (
    <ParagraphStyled
      dangerouslySetInnerHTML={{ __html: noTransform ? children : textFormatter(children) }}
      className={className}
      margin={margin}
    />
  );
};

Paragraph.defaultProps = {
  className: '',
};

export default Paragraph;
