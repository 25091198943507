import * as React from 'react';
import styled from 'styled-components';
import { labeltext } from '@adecco/base-app/src/styles/typography';

interface IFormRow {
  children: string;
}

const ActualCheckBoxLabel = styled.div`
  display: block;
  ${labeltext};
  font-size: ${(props) => props.theme.fontSizes.checkboxLabel};
  padding-bottom: 6px;
  margin-bottom: 14px;
  line-height: ${({ theme }) => theme.lineHeights.checkboxLabel};
`;

const CheckBoxLabel: React.FunctionComponent<IFormRow> = ({ children }) => (
  <ActualCheckBoxLabel>{children}</ActualCheckBoxLabel>
);

export default CheckBoxLabel;
