import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

const AdeccoLogo = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/AdeccoLogo')
);
const ArrowRightIconRed = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/ArrowRightIconRed')
);
const CalenderIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/CalenderIcon')
);
const CheckmarkIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/CheckmarkIcon')
);
const CheckmarkIconRed = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/CheckmarkIconRed')
);
const ClockAdeccoIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/ClockAdeccoIcon')
);
const CloseXIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/CloseXIcon')
);
const DocumentIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/DocumentIcon')
);
const DocumentTileAdeccoIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/DocumentTileAdeccoIcon')
);
const EditIcon = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/EditIcon'));
const EmailFilledIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/EmailFilledIcon')
);
const EmailIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/EmailIcon')
);
const FlagDeIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/FlagDeIcon')
);
const FlagEnIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/FlagEnIcon')
);
const FlagPlIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/FlagPlIcon')
);
const HeadsetIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/HeadsetIcon')
);
const HeartIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/HeartIcon')
);
const HeartLineIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/HeartLineIcon')
);
const Icon404 = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/Icon404'));
const Icon410 = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/Icon410'));
const Icon500 = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/Icon500'));
const InformationIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/InformationIcon')
);
const InformationIconFilled = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/InformationIconFilled')
);
const LocationAdeccoIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/LocationAdeccoIcon')
);
const LogoFacebookIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoFacebook')
);
const LogoInstagram = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoInstagram')
);
const LogoKununuIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoKununuIcon')
);
const LogoLinkedInIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoLinkedInIcon')
);
const LogoMapsIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoMapsIcon')
);
const LogoXingIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/LogoXingIcon')
);
const NoCVIcon = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/NoCVIcon'));
const PinIcon = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/PinIcon'));
const PlacementTypeIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/PlacementTypeIcon')
);
const PlusIcon = dynamic(() => import('@adecco/base-app/src/components/atoms/Icon/Icons/PlusIcon'));
const SearchIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/SearchIcon')
);
const SuitCaseIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/SuitCaseIcon')
);
const ThxBriefCaseAdeccoIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/ThxBriefCaseAdeccoIcon')
);
const ThxContactAdeccoIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/ThxContactAdeccoIcon')
);
const ThxHeartAdeccoIcon = dynamic(
  () => import('@adecco/adecco-app/src/components/atoms/Icon/Icons/ThxHeartAdeccoIcon')
);
const HelpTextInfoIcon = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Icon/Icons/InformationIcon')
);

export type IconMapType = {
  logo: ComponentType<{}>;
  'help-text-info': ComponentType<{}>;
  'arrow-right-red': ComponentType<{}>;
  calender: ComponentType<{}>;
  checkmark: ComponentType<{}>;
  'checkmark-red': ComponentType<{}>;
  clock: ComponentType<{}>;
  'close-x': ComponentType<{}>;
  document: ComponentType<{}>;
  'document-tile': ComponentType<{}>;
  edit: ComponentType<{}>;
  email: ComponentType<{}>;
  thx_email_confirmation: ComponentType<{}>;
  'email-filled': ComponentType<{}>;
  'flag-de': ComponentType<{}>;
  'flag-en': ComponentType<{}>;
  'flag-pl': ComponentType<{}>;
  headset: ComponentType<{}>;
  heart: ComponentType<{}>;
  'heart-line': ComponentType<{}>;
  information: ComponentType<{}>;
  'information-filled': ComponentType<{}>;
  location: ComponentType<{}>;
  logo_facebook: ComponentType<{}>;
  logo_instagram: ComponentType<{}>;
  logo_kununu: ComponentType<{}>;
  logo_linkedin: ComponentType<{}>;
  logo_maps: ComponentType<{}>;
  logo_xing: ComponentType<{}>;
  'no-cv': ComponentType<{}>;
  pin: ComponentType<{}>;
  'placement-type': ComponentType<{}>;
  plus: ComponentType<{}>;
  thx_profile: ComponentType<{}>;
  search: ComponentType<{}>;
  suitcase: ComponentType<{}>;
  thx_briefcase: ComponentType<{}>;
  thx_contact: ComponentType<{}>;
  thx_heart: ComponentType<{}>;
  '404': ComponentType<{}>;
  '410': ComponentType<{}>;
  '500': ComponentType<{}>;
  'job-location': ComponentType<{}>;
};

export const IconMap: IconMapType = {
  'job-location': PinIcon,
  'help-text-info': HelpTextInfoIcon,
  logo: AdeccoLogo,
  'arrow-right-red': ArrowRightIconRed,
  calender: CalenderIcon,
  checkmark: CheckmarkIcon,
  'checkmark-red': CheckmarkIconRed,
  clock: ClockAdeccoIcon,
  'close-x': CloseXIcon,
  document: DocumentIcon,
  'document-tile': DocumentTileAdeccoIcon,
  edit: EditIcon,
  email: EmailIcon,
  thx_email_confirmation: ThxContactAdeccoIcon,
  'email-filled': EmailFilledIcon,
  'flag-de': FlagDeIcon,
  'flag-en': FlagEnIcon,
  'flag-pl': FlagPlIcon,
  headset: HeadsetIcon,
  heart: HeartIcon,
  'heart-line': HeartLineIcon,
  information: InformationIcon,
  'information-filled': InformationIconFilled,
  location: LocationAdeccoIcon,
  logo_facebook: LogoFacebookIcon,
  logo_instagram: LogoInstagram,
  logo_kununu: LogoKununuIcon,
  logo_linkedin: LogoLinkedInIcon,
  logo_maps: LogoMapsIcon,
  logo_xing: LogoXingIcon,
  'no-cv': NoCVIcon,
  pin: PinIcon,
  'placement-type': PlacementTypeIcon,
  plus: PlusIcon,
  thx_profile: ThxBriefCaseAdeccoIcon,
  search: SearchIcon,
  suitcase: SuitCaseIcon,
  thx_briefcase: ThxBriefCaseAdeccoIcon,
  thx_contact: ThxHeartAdeccoIcon,
  thx_heart: ThxHeartAdeccoIcon,
  '404': Icon404,
  '410': Icon410,
  '500': Icon500,
};

export type IconType = keyof IconMapType;
