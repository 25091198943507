import * as React from 'react';
import styled, { css } from 'styled-components';
import Modal from 'styled-react-modal';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { panel } from '@adecco/base-app/src/styles/panels';
import Headline, { HeadlineStyled } from '@adecco/base-app/src/components/atoms/Headline/Headline';
import Paragraph from '@adecco/base-app/src/components/atoms/Paragraph/Paragraph';
import Button from '@adecco/base-app/src/components/atoms/Button/Button';

// TODO: DRY with LegalNoticeModal
// content is obtained as props
// DRY will need logic for single or double button footer
export interface IDataProtectionModal {
  isOpen: boolean;
  closeModal: (accept: boolean) => void;
  headerTitle: string;
  content: string;
  mainButtonTitle: string;
  secondaryButtonTitle?: string;
}

const modalStyles = css`
  margin-top: calc(${(props) => props.theme.headerSpacing.logoHeight} * 2.5);
  width: 85vw;
  height: auto;
  max-width: 800px;
  max-height: 75vh;
  background-color: ${(props) => props.theme.colors.white};
  ${panel};
  display: flex;
  flex-direction: column;
  padding: 0;

  ${moreThan('md')} {
    max-height: 70vh;
  }
`;

const StyledModal = Modal.styled`
 ${modalStyles};
`;

const ModalSection = styled.div`
  padding: 10px 20px;
  border-bottom: ${(props) => props.theme.borders.regular};
`;

const ModalContent = styled(ModalSection)`
  overflow: auto;
  flex: 0 1 auto;

  p {
    margin-bottom: ${(props) => props.theme.margins.modalContentP};
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }
`;

const ModalHeader = styled(ModalSection)`
  ${HeadlineStyled} {
    margin-bottom: 0;
  }
`;

const ModalFooter = styled(ModalSection)`
  flex: 0 0 auto;
  border-bottom: 0;
  display: flex;
  justify-content: space-between;
`;

const DataProtectionModal: React.FunctionComponent<IDataProtectionModal> = ({
  isOpen,
  closeModal,
  headerTitle,
  content,
  mainButtonTitle,
  secondaryButtonTitle,
}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={() => closeModal(false)}
      onEscapeKeydown={() => closeModal(false)}
    >
      <ModalHeader>
        <Headline skin={2}>{headerTitle}</Headline>
      </ModalHeader>
      <ModalContent>
        <Paragraph noTransform>{content}</Paragraph>
      </ModalContent>
      <ModalFooter>
        <Button variant="small-secondary" onClick={() => closeModal(false)}>
          {secondaryButtonTitle}
        </Button>
        <Button variant="small" onClick={() => closeModal(true)}>
          {mainButtonTitle}
        </Button>
      </ModalFooter>
    </StyledModal>
  );
};

export default DataProtectionModal;
