import { FunctionComponent } from 'react';
import styled from 'styled-components';
import FormField from '@adecco/base-app/src/components/atoms/FormField/FormField';
import { inputtext, placeholdertext } from '@adecco/base-app/src/styles/typography';

export interface ISelectValue {
  name: string;
  value: string;
}

interface ISelectField {
  label?: string;
  name: string;
  required?: boolean;
  redStar?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  error?: boolean;
  className?: string;
  values: ISelectValue[];
  helpText?: string;
  selected?: string;
}

export interface ISelectWithError {
  error?: boolean;
}

const Select = styled.select<ISelectWithError>`
  ${inputtext};
  background: ${(props) => props.theme.backgrounds.input};
  width: 100%;
  border-radius: ${(props) => props.theme.radius.input};
  border: ${(props) => props.theme.borders.inputField};
  box-shadow: ${(props) => props.theme.boxShadow.materialMid};
  padding: 0 14px;
  height: ${(props) => props.theme.heights.input};

  ${(props) =>
    props.error &&
    `
  border: ${props.theme.borders.errorField};
  `};

  &::placeholder {
    ${placeholdertext}
  }

  &:focus {
    background-color: ${(props) => props.theme.backgrounds.inputFocus};
    outline: none;
  }
`;

const SelectField: FunctionComponent<ISelectField> = ({
  label,
  name,
  required,
  register,
  className,
  values = [],
  error,
  helpText,
  selected = values.length === 1 ? values[0].value : '',
}) => (
  <FormField label={label} name={name} required={required} redStar={error} helpText={helpText}>
    <Select
      name={name}
      id={name}
      className={className}
      error={error}
      {...register}
      data-cy={`select-${name}`}
      defaultValue={selected}
    >
      <option disabled hidden value="">
        {' '}
      </option>
      {values.map((item, index) => (
        <option key={`${item.name}-${index}`} value={item.value} data-cy={`option-${name}`}>
          {item.name}
        </option>
      ))}
    </Select>
  </FormField>
);

export default SelectField;
