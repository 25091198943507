import * as React from 'react';
import styled from 'styled-components';
import { errortext } from '@adecco/base-app/src/styles/typography';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface IErrorMessage {
  text: string;
  heading?: boolean;
  paddingTop?: boolean;
  elementName: string; // element to which the error is attached
}

const ErrorMessageStyled = styled.div<{ heading?: boolean; paddingTop?: boolean }>`
  padding-bottom: 20px;
  margin: ${(props) =>
    props.heading ? props.theme.margins.errorMessageHeading : props.theme.margins.errorMessage};

  ${moreThan('md')} {
    display: ${(props) => (props.heading ? 'flex' : 'initial')};
    padding: ${(props) => (props.heading ? '17px 20px' : 0)};
    padding-top: ${(props) => (props.paddingTop ? '10px' : '0')};
    margin: ${(props) =>
      props.heading
        ? props.theme.margins.errorMessageHeadingWideScreen
        : props.theme.margins.errorMessageWideScreen};
  }
`;

const ErrorMessageText = styled.div<{ heading?: boolean }>`
  ${errortext};
  font-size: ${(props) =>
    props.heading
      ? props.theme.fontSizes.errorMessageTextHeading
      : props.theme.fontSizes.errorMessageText};
`;

const ErrorMessage: React.FunctionComponent<IErrorMessage> = ({
  text,
  heading,
  paddingTop,
  elementName,
}) => (
  <ErrorMessageStyled heading={heading} paddingTop={paddingTop}>
    <ErrorMessageText
      dangerouslySetInnerHTML={{ __html: text }}
      heading={heading}
      data-cy={`error-message-${elementName}`}
    />
  </ErrorMessageStyled>
);

export default ErrorMessage;
