import { CloudinaryImage } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import cld from './createCloudinaryInstance';

const transformImage = (name: string, width?: number, height?: number): CloudinaryImage => {
  const image = cld.image(name);

  if (width && height) {
    image.resize(fill().width(width).height(height));
  }

  return image.format('auto').quality('auto');
};

export default transformImage;
