import { useEffect, useState } from 'react';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { useRouterQueries } from '@adecco/base-app/src/hooks/useRouterQueries';
import { ITEM_BRANDS } from '@adecco/base-app/src/constants/itemBrands';
import { useBrowserStorage } from './useBrowserStorage';

type EventType =
  | 'add_to_cart'
  | 'begin_checkout'
  | 'candidature_confirm_topjob'
  | 'candidature_sent_clientjob'
  | 'candidature_sent_linkform'
  | 'candidature_sent_proactive'
  | 'candidature_sent_pureclientjob'
  | 'candidature_sent_topjob'
  | 'candidature_sent'
  | 'candidature_start_clientjob'
  | 'candidature_start_linkform'
  | 'candidature_start_proactive'
  | 'candidature_start_pureclientjob'
  | 'candidature_start_topjob'
  | 'candidature_start'
  | 'form_error'
  | 'form_submit'
  | 'ga_event'
  | 'page_view'
  | 'purchase'
  | 'view_cart'
  | 'view_item';

type AffiliationType = 'standardjob' | 'proactivejob' | 'clientjob' | 'pureclientjob' | 'topjob';

export interface EventData {
  ecommerce?: {
    affiliation?: AffiliationType;
    transaction_id?: string;
    currency?: string;
    value?: number | string;
    items?: {
      item_name: string;
      item_id: string;
      item_category: string;
      item_category2: string;
      item_category3: string;
      item_category4: string;
      item_category5: string;
      item_variant: string;
      item_brand: (typeof ITEM_BRANDS)[keyof typeof ITEM_BRANDS];
      client: string;
    }[];
  };
  desiredJob?: string;
  varCampaign?: string | string[];
  event_category?: string;
  event_action?: string;
  search_keyword?: string;
}

interface IUseDataLayerPushEvent {
  event: EventType;
  data?: EventData;
  deps?: React.DependencyList;
  checker?: boolean; // Condition that has to be true for pushing to DataLayer
}

export function useDataLayerPushEvent({
  event,
  data,
  deps = [],
  checker = true,
}: IUseDataLayerPushEvent) {
  const { withTracking } = useAppContext();
  const { getItem, removeItem } = useBrowserStorage();
  const { cdx_campaign, utm_campaign, desiredPosition } = useRouterQueries([
    'cdx_campaign',
    'utm_campaign',
    'desiredPosition',
  ]);

  const [transactionId, settransactionId] = useState('');

  useEffect(() => {
    const transaction_id = getItem('transaction_id');
    settransactionId(transaction_id);
  }, []);

  if (!withTracking) return;
  const finalData = { ...data };

  const isTransactionIdEvent = [
    'purchase',
    'candidature_sent',
    'candidature_sent_topjob',
    'candidature_sent_clientjob',
    'candidature_sent_linkform',
    'candidature_sent_proactive',
    'candidature_sent_pureclientjob',
  ].some((el) => el === event);

  // Events that should be fired in every view
  useEffect(() => {
    if (!checker) return;

    if (!isTransactionIdEvent) {
      const varCampaign = cdx_campaign || utm_campaign;

      if (varCampaign) {
        finalData.varCampaign = varCampaign;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event, ...finalData });
    }
  }, [...deps]);

  // Events that should be fired only once when there is transaction id
  useEffect(() => {
    if (!checker) return;

    if (isTransactionIdEvent) {
      if (!transactionId) return;

      removeItem('transaction_id');

      if (finalData?.ecommerce) {
        finalData.ecommerce.transaction_id = transactionId;
      }

      const varCampaign = cdx_campaign || utm_campaign;

      if (varCampaign) {
        finalData.varCampaign = varCampaign;
      }

      if (event === 'candidature_sent_proactive') {
        finalData.desiredJob = desiredPosition;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event, ...finalData });
    }
  }, [...deps, transactionId]);
}
