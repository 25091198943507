import * as React from 'react';
import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import ThankYouTeaser from '@adecco/base-app/src/components/atoms/ThankYouTeaser/ThankYouTeaser';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import { CloudinaryImageType } from '@adecco/base-app/src/gql/schema/generated';

export interface IThankYouTeaserList {
  teasers?: readonly (
    | {
        title: string;
        description: string;
        image?: string;
        link?: string;
        linkText?: string;
        cloudinaryImage?: CloudinaryImageType;
      }
    | undefined
  )[];
}

const ThankYouTeaserListStyled = styled.div`
  margin: ${(props) => props.theme.margins.list};
  padding: 20px;
  display: ${(props) => props.theme.displays.thankYouTeaserList};
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 40px;

  ${moreThan('md')} {
    grid-template-columns: 1fr 1fr;
  }
`;

const ThankYouTeaserListItem = styled.div`
  ${moreThan('md')} {
    &:last-child {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

const ThankYouTeaserList: React.FunctionComponent<IThankYouTeaserList> = ({ teasers = [] }) => (
  <Container>
    <ThankYouTeaserListStyled>
      {teasers.map((teaser, index) => (
        teaser && (
          <ThankYouTeaserListItem key={`teaser-${index}`}>
            <ThankYouTeaser {...teaser} />
          </ThankYouTeaserListItem>
        )
      ))}
    </ThankYouTeaserListStyled>
  </Container>
);

export default ThankYouTeaserList;
