interface IOptions {
  queryString?: boolean;
}

const transformLink = (
  asPath: string,
  siteName: string,
  options: IOptions = { queryString: true }
): string => {
  const querySplit = asPath.split('?');
  if (!options.queryString) {
    return `${querySplit[0]}/${siteName}`;
  }

  const queryParamsString = querySplit[1] ? `?${querySplit[1]}` : '';

  return `${querySplit[0]}/${siteName}${queryParamsString}`;
};

export default transformLink;
