import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

const Spacer = styled.div`
  padding: ${(props) => props.theme.paddings.spacer};
  position: relative;
  margin: ${(props) => props.theme.margins.spacer};
  width: ${(props) => props.theme.widths.spacer};
  max-width: ${(props) => props.theme.widths.spacerMax};

  ${moreThan('md')} {
    padding: ${(props) => props.theme.paddings.spacerWideScreen};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    max-height: ${(props) => props.theme.heights.spacerMax};
    z-index: -1;
    background: ${(props) => props.theme.backgrounds.spacer};
  }
`;

const SpacerWithGradient = () => {
  return <Spacer />;
};

export default SpacerWithGradient;
