import * as React from 'react';
import CloudinaryImage from '@adecco/base-app/src/components/atoms/CloudinaryImage/CloudinaryImage';
import { CloudinaryImageType } from '@adecco/base-app/src/gql/schema/generated';

export interface IDynamicImage {
  image?: string;
  cloudinaryImage?: CloudinaryImageType;
  alt?: string;
  title?: string;
}

const DynamicImage: React.FunctionComponent<IDynamicImage> = ({
  image,
  cloudinaryImage,
  alt,
  title,
  ...rest
}) => {
  if (cloudinaryImage) {
    return (
      <CloudinaryImage
        src={cloudinaryImage.image}
        alt={cloudinaryImage?.alt ?? alt}
        title={title}
        {...rest}
      />
    );
  }

  if (image) {
    return <img src={image} alt={alt} title={title} {...rest} />;
  }

  return null;
};

export default DynamicImage;
