import { ALLOWED_TYPES, MAX_SIZE } from '@adecco/base-app/src/constants/fileUpload';

export function validatedFiles(fileList: FileList | null | undefined) {
  if (!fileList) return true;

  const fileListArray = Array.from(fileList);
  let fileListSize = 0;

  return fileListArray.every((file) => {
    const isValidSize = file.size < MAX_SIZE;
    const isValidType = ALLOWED_TYPES.test(file.name);
    fileListSize += file.size || 0;

    return isValidSize && isValidType && fileListSize < MAX_SIZE;
  });
}
