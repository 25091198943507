import { isHtmlFormat } from './isHtmlFormat';

const prepareLists = (lines: string[]) => {
  return `
    <ul>
      ${lines.map((line) => `<li>${line}</li>`).join('')}
    </ul>
  `;
};

export const textFormatter = (text: string): string => {
  if (isHtmlFormat(text)) {
    return text.replace(/<b>(.*?)<\/b>/g, '<strong>$1</strong>');
  }

  const formattedText = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
  const lines = formattedText.split(/^-\s|[\r\n]\s?-\s/g).filter((line) => line.length > 0);
  return lines.length > 1 ? prepareLists(lines) : lines[0];
};
