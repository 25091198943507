import styled from 'styled-components';
import FormField from '@adecco/base-app/src/components/atoms/FormField/FormField';
import { inputtext } from '@adecco/base-app/src/styles/typography';
import 'react-phone-number-input/style.css';
import getConfig from 'next/config';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import flags from 'react-phone-number-input/flags';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { phonePattern } from '@adecco/base-app/src/utilities/forms/regExPatterns';

interface Props {
  control: object;
  error: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  required: boolean;
  defaultValue?: string;
}

const PhoneWithCountry = styled(PhoneInputWithCountry)<{ errorSkinning: boolean }>`
  height: 46px;
  --PhoneInputCountryFlag-borderWidth: 0;
  --PhoneInputCountrySelectArrow-width: 8px;
  --PhoneInputCountrySelectArrow-marginLeft: 10px;
  background: ${(props) => props.theme.backgrounds.phoneWithCountry};

  &:focus-within {
    /* Not supported by IE. Polyfill: https://www.npmjs.com/package/focus-within-polyfill */
    background-color: ${(props) => props.theme.backgrounds.inputFocus};
    outline: none;
    box-shadow: ${(props) => props.theme.boxShadow.inputFocus};

    .PhoneInputInput {
      background: ${(props) => props.theme.backgrounds.inputFocus};
    }
  }

  .PhoneInputInput {
    background: ${(props) => props.theme.backgrounds.phoneWithCountry};
    border: none;
    ${inputtext};

    &:focus {
      background-color: ${(props) => props.theme.backgrounds.inputFocus};
      outline: none;
    }
  }

  .PhoneInputCountryIcon {
    width: 24px;
    height: 16px;
  }

  border-radius: ${(props) => props.theme.radius.input};
  border: ${(props) => props.theme.borders.inputField};
  box-shadow: ${(props) => props.theme.boxShadow.materialMid};
  padding: 12px 14px;
  height: ${(props) => props.theme.heights.input};

  .PhoneInputCountrySelectArrow {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }
`;

const CustomFormField = styled(FormField)<{ redStar: boolean }>`
  .PhoneInput {
    ${(props) => props.redStar && `border:${props.theme.borders.errorField}`};
  }
`;

const PhoneInput = ({ control, error, register, required, defaultValue }: Props) => {
  const { t } = useAppContext();
  const { setValue } = useFormContext();
  const { publicRuntimeConfig } = getConfig();
  const defaultCountry = publicRuntimeConfig.LANG.toUpperCase();
  const name = 'phone';

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue]);

  return (
    <CustomFormField label={t('form-field-phone')} name={name} required={required} redStar={error}>
      <PhoneWithCountry
        name={name}
        defaultCountry={defaultCountry}
        // @ts-ignore
        control={control}
        register={register('phone', {
          required,
          pattern: phonePattern,
        })}
        id={name}
        flags={flags}
        defaultValue={defaultValue}
        data-cy={`input-${name}`}
      />
    </CustomFormField>
  );
};

export default PhoneInput;
