import * as React from 'react';
import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import CardInfo from '@adecco/base-app/src/components/atoms/CardInfo/CardInfo';
import { IconType } from '@adecco/base-app/src/components/atoms/Icon/iconMap';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

export interface IPlannedStepItem {
  iconName: IconType;
  headline: string;
  text: string;
}

export interface IPlannedSteps {
  items: Array<IPlannedStepItem>;
  centeredHeadline?: boolean;
}

const PlannedStepsList = styled.div`
  display: flex;
  flex-direction: column;

  ${moreThan('md')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: ${(props) => props.theme.margins.headLineBottom};
  font-size: ${(props) => props.theme.fontSizes.headline2};
  line-height: ${(props) => props.theme.lineHeights.headline2};
  font-family: ${(props) => props.theme.fonts.HeadlineBold};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.headline2WideScreen};
    line-height: ${(props) => props.theme.lineHeights.headline2WideScreen};
  }
`;

const PlannedSteps: React.FunctionComponent<IPlannedSteps> = ({ items, centeredHeadline }) => {
  const { t } = useAppContext();
  return (
    <Container>
      <StyledHeadline centered={centeredHeadline} skin={2}>
        {t('thank-you-panel-next-step')}
      </StyledHeadline>
      <PlannedStepsList>
        {items.map(({ iconName, headline, text }, index) => {
          return (
            <CardInfo
              key={`step-${index}`}
              iconName={iconName}
              title={headline}
              description={text}
            />
          );
        })}
      </PlannedStepsList>
    </Container>
  );
};
export default PlannedSteps;
