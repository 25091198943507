import { FormElementTypes } from './FormElementRenderer.types';

export const configInputFields: {
  [key in FormElementTypes]: { label: string; name: string; error: string; help?: string };
} = {
  'first-name': {
    label: 'form-field-first-name',
    name: 'firstName',
    error: 'error-first-name',
  },
  'last-name': {
    label: 'form-field-last-name',
    name: 'lastName',
    error: 'error-last-name',
  },
  'zip-code': {
    label: 'form-field-zipcode',
    name: 'zipcode',
    error: 'error-zip-code',
  },
  city: {
    label: 'form-field-city',
    name: 'city',
    error: 'error-city',
  },
  email: {
    label: 'form-field-email',
    name: 'email',
    error: 'error-email',
  },
  'email-validation': {
    label: 'form-field-email-validation',
    name: 'email_validation',
    error: 'error-email-validation',
  },
  'desired-position': {
    label: 'form-field-desired-job',
    name: 'desiredPosition',
    error: 'error-desired-job',
    help: 'form-field-desired-job-help',
  },
  location: {
    label: 'form-field-location',
    name: 'location',
    error: 'error-location',
  },
  distance: {
    label: 'form-field-distance',
    name: 'distance',
    error: 'error-distance',
    help: 'form-field-distance-help',
  },
  phone: {
    label: 'form-field-phone',
    name: 'phone',
    error: 'error-phone-number',
  },
  street: {
    label: 'form-field-street',
    name: 'street',
    error: 'error-street-name',
  },
  number: {
    label: 'form-field-number',
    name: 'number',
    error: 'error-house-number',
  },
  'street-block': {
    label: '',
    name: '',
    error: '',
  },
  'zip-city-block': {
    label: '',
    name: '',
    error: '',
  },
  empty: {
    label: '',
    name: '',
    error: '',
  },
  'desired-location': {
    label: 'form-field-desired-location',
    name: 'desiredJobLocation',
    error: 'error-street-desired-location',
  },
  'contact-source': {
    label: 'form-field-contact-source',
    name: 'contactSource',
    error: 'error-contact-source',
  },
  greetings: {
    label: 'form-field-greetings',
    name: 'greeting',
    error: 'error-greetings',
  },
  branch: {
    label: 'form-field-branch',
    name: 'branchId',
    error: 'error-branch',
    help: 'form-field-branch-help',
  },
};
