import styled from 'styled-components';
import { CloudinaryImageType } from '@adecco/base-app/src/gql/schema/generated';
import { copytext } from '@adecco/base-app/src/styles/typography';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { IconType } from '@adecco/base-app/src/components/atoms/Icon/iconMap';
import DynamicImage from '@adecco/base-app/src/components/atoms/DynamicImage/DynamicImage';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import Paragraph from '@adecco/base-app/src/components/atoms/Paragraph/Paragraph';

export interface ICardInfo {
  title: string;
  description: string;
  iconName?: IconType;
  image?: string;
  number?: number;
  cloudinaryImage?: CloudinaryImageType;
}

const CardIconContainer = styled.div`
  position: relative;
  display: flow-root;
  flex: 1;
  background: ${(props) => props.theme.backgrounds.cardIconContainer};
  border-radius: ${(props) => props.theme.radius.cardInfo};
  box-shadow: ${(props) => props.theme.boxShadow.cardInfo};
  padding: 25px;
  margin-bottom: ${(props) => props.theme.margins.cardIconContainer};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${moreThan('md')} {
    margin-right: ${(props) => props.theme.margins.cardIconContainer};

    &:last-of-type {
      margin-bottom: ${(props) => props.theme.margins.cardIconContainer};
      margin-right: 0;
    }
  }
`;

const CardIconHeadline = styled(Headline)`
  color: ${(props) => props.theme.colors.cardInfoHeadline};
  font-family: ${(props) => props.theme.fonts.cardInfoHeadline};
  font-size: ${(props) => props.theme.fontSizes.cardIconHeadline};
  line-height: ${(props) => props.theme.lineHeights.headline4};
`;

const CardIcon = styled(Icon)`
  float: right;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: ${(props) => props.theme.margins.cardIcon};
  margin-left: ${(props) => props.theme.margins.cardIcon};
`;

const CardIconText = styled.p`
  ${copytext};
  line-height: ${(props) => props.theme.lineHeights.cardIconText};
`;

const IMAGE_RESPECT_AREA = 70 + 10 * 2 - 25;
const IMAGE_RESPECT_AREA_DSK = 80 + 12 * 2 - 25;

const CardContainer = styled.div`
  background: ${(props) => props.theme.backgrounds.cardIconContainer};
  box-shadow: ${(props) => props.theme.boxShadow.cardInfo};
  border-radius: ${(props) => props.theme.radius.panel};
  padding: ${calcrem(25)};
  position: relative;
  height: 100%;
`;

const CardImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  height: ${calcrem(50)};
  width: ${calcrem(70)};
  position: absolute;
  right: ${calcrem(10)};
  top: ${calcrem(10)};

  ${moreThan('md')} {
    height: ${calcrem(65)};
    right: ${calcrem(12)};
    width: ${calcrem(80)};
  }
`;

const CardImageHeadline = styled(Headline)`
  word-break: break-all;
  font-family: ${(props) => props.theme.fonts.cardInfoHeadline};
  font-size: ${(props) => props.theme.fontSizes.cardImageHeadline};
  margin-right: ${calcrem(IMAGE_RESPECT_AREA)};
  color: ${(props) => props.theme.colors.cardInfoHeadline};
  font-weight: ${(props) => props.theme.fontWeights.cardImageHeadline};

  ${moreThan('md')} {
    margin-bottom: ${calcrem(20)};
    margin-right: ${calcrem(IMAGE_RESPECT_AREA_DSK)};
  }
`;

const CardImage = styled(DynamicImage)`
  max-width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: contain;
`;

const CardInfo: React.FunctionComponent<ICardInfo> = ({
  description,
  title,
  cloudinaryImage,
  iconName,
  image,
}) => {
  if (iconName) {
    return (
      <CardIconContainer>
        <CardIconHeadline skin={3} tag="h4">
          {title}
        </CardIconHeadline>
        <CardIcon height={75} width={100} name={iconName} />
        <CardIconText>{description}</CardIconText>
      </CardIconContainer>
    );
  }

  if (image || cloudinaryImage) {
    return (
      <CardContainer>
        <CardImageContainer>
          <CardImage image={image} cloudinaryImage={cloudinaryImage} alt={title} />
        </CardImageContainer>
        <CardImageHeadline skin={3} tag="h4">
          {title}
        </CardImageHeadline>
        <Paragraph>{description}</Paragraph>
      </CardContainer>
    );
  }

  return null;
};

export default CardInfo;
