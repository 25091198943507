import * as React from 'react';
import styled from 'styled-components';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import { helptext } from '@adecco/base-app/src/styles/typography';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface IHelpText {
  text: string;
  show?: boolean;
  className?: string;
}

export const HelpTextStyled = styled.span`
  position: relative;
  display: inline-block;
  padding: 0 10px 5px;
`;

interface IHelpTextWindow {
  show?: boolean;
}

export const HelpTextWindow = styled.span<IHelpTextWindow>`
  background: white;
  border: 0.5px solid rgb(175, 175, 175);
  box-shadow: ${(props) => props.theme.boxShadow.helpText};
  padding: 16px 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: ${(props) => props.theme.zIndex.searchBox};
  ${helptext};
  display: ${(props) => (props.show ? 'block' : 'none')};
  width: 90vw;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;

  ${moreThan('md')} {
    position: absolute;
    transform: translateX(-50%) translateY(0);
    top: 100%;
    left: 0;
    width: 360px;
  }

  ${moreThan('lg')} {
    transform: translateX(0) translateY(-50%);
    top: 50%;
    left: calc(100% + 20px);
  }

  ${HelpTextStyled}:hover & {
    display: block;
  }
`;

const HelpText: React.FunctionComponent<IHelpText> = ({ text, show, className }) => {
  return (
    <HelpTextStyled className={className}>
      <Icon name={'help-text-info'} height={18} width={18} />
      <HelpTextWindow show={show} dangerouslySetInnerHTML={{ __html: text }} />
    </HelpTextStyled>
  );
};

export default HelpText;
