import * as React from 'react';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import TextField from '@adecco/base-app/src/components/atoms/TextField/TextField';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { TextInputType } from './FormElementRenderer.types';
import { configInputFields } from './configInputFields';
import { usePatterns } from '@adecco/base-app/src/hooks/usePatterns';

const TextInput: React.FunctionComponent<TextInputType> = ({
  type,
  register,
  errors,
  size,
  required,
}) => {
  const { t } = useAppContext();
  const patterns = usePatterns();

  return (
    <FieldGroup size={1 / size}>
      <TextField
        label={t(configInputFields[type].label)}
        helpText={configInputFields[type].help ? t(configInputFields[type].help!) : undefined}
        name={configInputFields[type].name}
        register={register(configInputFields[type].name, {
          required,
          pattern: patterns[type] || undefined,
        })}
        error={!!errors[configInputFields[type].name]}
        required={required}
      />
      {errors[configInputFields[type].name] && (
        <ErrorMessage
          text={t(configInputFields[type].error)}
          elementName={configInputFields[type].name}
        />
      )}
    </FieldGroup>
  );
};

export default TextInput;
