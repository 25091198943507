import * as React from 'react';
import styled from 'styled-components';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';

type StatusBar = {
  headline: string;
  status: number;
  withoutMarginBottom?: boolean;
};

const Wrapper = styled.div<{ withoutMarginBottom: boolean }>`
  margin: 15px 0 ${(props) => (props.withoutMarginBottom === true ? 0 : '30px')} 0;
`;

const StyledStatusBar = styled.div<{ status: number }>`
  margin-top: ${(props) => props.theme.margins.statusBar};
  width: 100%;
  height: 36px;
  border: ${(props) => props.theme.borders.inputField};
  border-radius: ${(props) => props.theme.radius.statusBar};
  background-color: ${(props) => props.theme.colors.lighterGrey};
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: ${(props) => `${props.status}%`};
    background-color: ${(props) =>
      props.status === 100 ? props.theme.colors.green : props.theme.colors.statusBarYellow};
  }
`;

const StatusBar: React.FunctionComponent<StatusBar> = ({
  headline,
  status,
  withoutMarginBottom = false,
}) => (
  <Wrapper withoutMarginBottom={withoutMarginBottom}>
    <Headline skin={3}>{headline}</Headline>
    <StyledStatusBar status={status} />
  </Wrapper>
);

export default StatusBar;
