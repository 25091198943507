import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { concatPagination } from '@apollo/client/utilities';
import getConfig from 'next/config';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const API_URL = process.env.ENDPOINT_URL;

const { publicRuntimeConfig } = getConfig();
const basePath = publicRuntimeConfig.BASEPATH;

const CLIENT_URL = `${basePath || ''}/cdx`;

export const createApolloClient = () =>
  new ApolloClient({
    ssrMode: typeof window === 'undefined',
    // https://www.apollographql.com/blog/graphql/file-uploads/with-react-hooks-typescript-amazon-s3-tutorial/#apollo-client
    // @ts-ignore
    link: createUploadLink({
      uri: typeof window === 'undefined' ? API_URL : CLIENT_URL,
      credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });
