import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { useState } from 'react';
import Head from 'next/head';
import { useDataLayer } from '@adecco/base-app/src/hooks/useDatalayer';
import {
  LandingPageKldbDocument,
  LandingPageKldbQuery,
} from '@adecco/base-app/src/gql/schema/generated';
import { withApolloProps } from '@adecco/base-app/src/apollo/withApolloProps';
import LandingPageKLDBApp from '@adecco/base-app/src/components/templates/LandingPageKLDBApp/LandingPageKLDBApp';
import getConfig from 'next/config';
import getConfiguration from '@adecco/base-app/src/utilities/getConfiguration/getConfiguration';
import getTextFromHtml from '@adecco/base-app/src/utilities/transformText/getTextfromHtml';
import { EventData, useDataLayerPushEvent } from '@adecco/base-app/src/hooks/useDataLayerPushEvent';
import { ITEM_BRANDS } from '@adecco/base-app/src/constants/itemBrands';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

interface IIndex {
  data: LandingPageKldbQuery;
  confirm: boolean;
}
export const PageType = 'Landingpage';

const GroupListPage: NextPage<IIndex> = ({ data }) => {
  const { brand } = useAppContext();
  const [formIsDirty, setFormIsDirty] = useState(false);

  useDataLayer(data.landingpageKldb, PageType, false, true, {
    job_category_id: data!.landingpageKldb!.kldb,
    form_type: data!.landingpageKldb!.formType === 1 ? 'lead-qualified' : 'lead-basic',
  });
  useDataLayerPushEvent({ event: 'page_view' });
  useDataLayerPushEvent({ event: 'candidature_start_topjob' });

  const eventData: EventData = {
    ecommerce: {
      affiliation: 'topjob',
      items: [
        {
          item_name: data.landingpageKldb?.title || 'na',
          item_id: 'na',
          item_category: 'na',
          item_category2: 'na',
          item_category3: 'na',
          item_category4: 'na',
          item_category5: data.landingpageKldb?.kldb || 'na',
          item_variant: 'na',
          item_brand: ITEM_BRANDS[brand],
          client: 'na',
        },
      ],
    },
  };

  useDataLayerPushEvent({
    event: 'view_cart',
    data: eventData,
  });

  useDataLayerPushEvent({
    event: 'begin_checkout',
    data: eventData,
    deps: [formIsDirty],
    checker: formIsDirty,
  });

  const title = data.landingpageKldb?.title;
  const description = getTextFromHtml(data.landingpageKldb?.description)?.slice(0, 160);

  return (
    <>
      <Head>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
      </Head>
      {/* @ts-ignore */}
      <LandingPageKLDBApp {...data.landingpageKldb} setFormIsDirty={setFormIsDirty} />
    </>
  );
};

export const getStaticProps: GetStaticProps = (context) =>
  withApolloProps(async (client) => {
    if (!context.params)
      return {
        notFound: true,
        revalidate: 1,
      };
    const { publicRuntimeConfig } = getConfig();
    const lpIdParam = context?.params?.lpIdParam;

    const lpId = Array.isArray(lpIdParam) ? lpIdParam[0] : lpIdParam;
    const { data } = await client.query<LandingPageKldbQuery>({
      query: LandingPageKldbDocument,
      variables: {
        slug: String(lpId),
        languages: publicRuntimeConfig.LANGUAGES,
        // @ts-ignore
        secret: context.preview ? context.previewData?.key : undefined,
        countryCode: context.locale ?? '',
      },
      errorPolicy: 'ignore',
    });

    if (
      data.landingpageKldb === null ||
      (!data.landingpageKldb?.online && typeof context.preview === 'undefined') ||
      getConfiguration(data.frontendConfigurations, 'withLandingpage') === 'false'
    ) {
      return {
        notFound: true,
        revalidate: 1,
      };
    }
    return {
      props: {
        data,
        pageType: PageType,
      },
      revalidate: 1,
    };
  });

export const getStaticPaths: GetStaticPaths = () =>
  Promise.resolve({
    fallback: 'blocking',
    paths: [],
  });

export default GroupListPage;
