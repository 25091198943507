import * as React from 'react';
import styled from 'styled-components';
import JobFactItem, {
  IJobFactItem,
} from '@adecco/base-app/src/components/atoms/JobFactItem/JobFactItem';
import { moreThan, lessThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';

export interface IJobFactList {
  facts: Array<IJobFactItem>;
}

export const JobFactListStyled = styled.ul`
  display: ${(props) => props.theme.displays.jobFactList};
  ${(props) =>
    props.theme.displays.jobFactList === 'flex' &&
    `
    flex-wrap: wrap;
  `};
  gap: ${calcrem(20)} ${calcrem(20)};
  margin-bottom: ${(props) => props.theme.margins.jobFactList};

  ${moreThan('md')} {
    gap: ${(props) => props.theme.gaps.jobFactList};
    margin-bottom: ${(props) => props.theme.margins.jobFactListWideScreen};
    grid-template-columns: repeat(2, 1fr);
  }

  ${moreThan('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const JobFactItemContainer = styled.li`
  ${lessThan('md')} {
    &:first-child {
      div[class*='JobFactItemStyled'] {
        padding-top: ${(props) => props.theme.paddings.jobFactListFirstItem};
      }
    }
  }
`;

const JobFactList: React.FunctionComponent<IJobFactList> = ({ facts }) => (
  <JobFactListStyled>
    {facts.map((fact, index) => (
      <JobFactItemContainer key={`jobFact-${index}`}>
        <JobFactItem {...fact} />
      </JobFactItemContainer>
    ))}
  </JobFactListStyled>
);

export default JobFactList;
