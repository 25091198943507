import * as React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

export interface ILocationList {
  headline: string;
  items: readonly {
    title: string;
    slug: string;
  }[];
}

const Ol = styled.ol`
  margin: ${(props) => props.theme.margins.locationList};
  list-style: disc;
`;

const Li = styled.li`
  margin-bottom: ${(props) => props.theme.margins.locationListLi};
  &:last-of-type {
    margin-bottom: 0;
  }
  a {
    font-family: ${(props) => props.theme.fonts.Copy};
    font-weight: ${(props) => props.theme.fontWeights.locationList};
    color: ${(props) => props.theme.colors.location};
    font-size: ${(props) => props.theme.fontSizes.locationList};
    line-height: ${(props) => props.theme.lineHeights.locationList};
    text-decoration: ${(props) => props.theme.textDecorations.location};
  }
  &::marker {
    color: ${(props) => props.theme.colors.location};
  }
`;

const Headline = styled.h4`
  font-family: ${(props) => props.theme.fonts.Copy};
  font-weight: ${(props) => props.theme.fontWeights.locationListHeadline};
  color: ${(props) => props.theme.colors.location};
  font-size: ${(props) => props.theme.fontSizes.locationList};
  line-height: ${(props) => props.theme.lineHeights.locationList};
  margin-top: ${(props) => props.theme.margins.locationListLi};
`;

const LocationList: React.FunctionComponent<ILocationList> = ({ headline, items }) => (
  <>
    <Headline>{headline}</Headline>
    <Ol>
      {items.map(({ title, slug }, index) => {
        return (
          <Li key={`item-${title}-${index}`}>
            <Link href={`#${slug}`} scroll={false}>{title}</Link>
          </Li>
        );
      })}
    </Ol>
  </>
);

export default LocationList;
