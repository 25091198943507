import * as React from 'react';
import styled from 'styled-components';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import TextField from '@adecco/base-app/src/components/atoms/TextField/TextField';
import Button from '@adecco/base-app/src/components/atoms/Button/Button';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { mdPx, moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import Link from 'next/link';
import { useDataLayerPushEvent } from '@adecco/base-app/src/hooks/useDataLayerPushEvent';

interface IPageHead {
  compact?: boolean;
  className?: string;
}

const PageHeadPlaceholder = styled.div<{
  compact?: boolean;
}>`
  height: 80px;
  margin-bottom: ${(props) => props.theme.margins.pageHead};

  ${moreThan('md')} {
    height: ${(props) =>
      props.compact === true
        ? props.theme.headerSpacing.searchBoxFakeHeight
        : props.theme.headerSpacing.searchBoxFakeHeightWithSearch};
    margin-bottom: ${(props) => props.theme.margins.pageHeadWideScreen};
  }
  @media print {
    display: none;
  }
`;

const PageHeadStyledContainer = styled.nav<IPageHead>`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.boxShadow.pageHeadContainer};
  padding: ${(props) => props.theme.headerSpacing.logoHeight} 30px;
  position: fixed;
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.header};
  top: 0;
  border-bottom: ${(props) => props.theme.borders.header};

  ${moreThan('md')} {
    border-radius: ${(props) => props.theme.radius.pageHead};
    box-shadow: ${(props) => props.theme.boxShadow.pageHead};
  }
`;

const PageHeadStyledContent = styled.ul<IPageHead>`
  display: flex;
  justify-content: space-between;

  ${moreThan('md')} {
    max-width: ${calcrem(1120)};
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
  }
`;

const PageHeadLogo = styled.h2<IPageHead>`
  height: ${(props) => props.theme.headerSpacing.logoHeight};

  svg {
    transition: height 0.25s ease-in-out;
    height: 100%;
  }
`;

const SearchBox = styled.form<{
  showOnXs: boolean;
  compact: boolean;
}>`
  display: ${(props) => props.theme.displays.searchBox};
  width: 100%;
  position: fixed;
  top: 60px;
  transition: transform 0.5s ease-in-out;
  transform: ${(props) => (props.showOnXs ? 'translateY(0)' : 'translateY(-240px)')};
  z-index: ${(props) => props.theme.zIndex.searchBox};
  background: ${(props) => props.theme.backgrounds.searchBox};

  ${moreThan('md')} {
    transform: ${(props) => (props.compact ? 'translateY(-122px)' : 'translateY(0)')};
  }
`;

const SearchBoxContent = styled.fieldset<IPageHead>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  max-width: ${calcrem(1120)};
  padding: ${(props) => props.theme.headerSpacing.searchBoxPaddingTop} 15px
    ${(props) => props.theme.headerSpacing.searchBoxPaddingBottom};

  label {
    color: ${(props) => props.theme.colors.white};
  }

  input {
    margin-bottom: ${(props) => props.theme.margins.searchBoxInput};
  }

  ${moreThan('md')} {
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-start;

    input {
      margin-bottom: ${(props) => props.theme.margins.searchBoxInputWideScreen};
    }
  }

  > * {
    width: auto;
    margin-right: ${(props) => props.theme.margins.searchBoxChildren};
    padding: 0;
  }
`;

const SearchTextField = styled(TextField)<IPageHead>`
  height: ${(props) => props.theme.headerSpacing.inputCustomHeight};
  padding: 12px 24px 10px 24px;
  font-family: ${(props) => props.theme.fonts.searchTextField};

  ${moreThan('md')} {
    transition: width 0.5s ease-in-out;
    width: 280px;
  }

  ${moreThan('maxWidth')} {
    width: 360px;
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: ${(props) => props.theme.fonts.searchTextField};
    color: ${(props) => props.theme.colors.black};
  }
  :-ms-input-placeholder {
    font-family: ${(props) => props.theme.fonts.searchTextField};
    color: ${(props) => props.theme.colors.black};
  }
`;

const SearchSubmitButton = styled(Button)<IPageHead>`
  border: ${(props) => props.theme.borders.searchButton};
  border-radius: ${(props) => props.theme.radius.panel};
  color: ${(props) => props.theme.colors.white};
  height: ${(props) => props.theme.headerSpacing.inputCustomHeight};
  overflow: visible;
  white-space: nowrap;
  padding: 0 10px;
  width: 100px;
  font-size: ${(props) => props.theme.fontSizes.searchSubmitButton};

  ${moreThan('md')} {
    padding: 0 40px;
    width: auto;
  }
`;

const BackToSearchMobileLink = styled.button`
  display: ${(props) => props.theme.displays.backToSearchMobileLink};
  height: calc(
    ${(props) => props.theme.headerSpacing.logoHeight} +
      ${(props) => props.theme.headerSpacing.searchIconExtraPadding}
  );
  padding: ${(props) => props.theme.headerSpacing.searchIconExtraPadding};
  width: calc(
    ${(props) => props.theme.headerSpacing.logoHeight} +
      ${(props) => props.theme.headerSpacing.searchIconExtraPadding}
  );
  border: none;
  background: transparent;

  ${moreThan('md')} {
    display: none;
  }
`;

const PageHead: React.FunctionComponent<IPageHead> = ({ compact = false, className }) => {
  const [collapsed, setCollapsed] = useState(compact || false);
  const [showOnXs, setShowOnXs] = useState(false);
  const [queryValue, setQueryValue] = useState('');
  const { handleSubmit, register } = useForm();
  const { t, logoLink } = useAppContext();

  useDataLayerPushEvent({
    event: 'ga_event',
    data: {
      event_category: 'search jobs',
      event_action: queryValue ? 'search_banner' : 'no selection made',
      search_keyword: queryValue || 'no selection made',
    },
    deps: [queryValue],
    checker: queryValue !== '',
  });

  const searchUrl = t('header-job-search-url') ?? '';

  if (!compact) {
    useScrollPosition(
      ({ prevPos, currPos }) => {
        if (compact || window.innerWidth < mdPx) return;

        if (currPos.y < -100 && currPos.y < prevPos.y) {
          setCollapsed(true);
        } else {
          setCollapsed(false);
        }
      },
      [],
      undefined,
      false,
      200
    );
  }

  const onSubmit = (values: FieldValues) => {
    setQueryValue(values.query);

    let url = searchUrl;

    if (values.searchLocation && !values.query) {
      url += `?k=&l=${values.searchLocation}&display=15`;
    } else if (!values.searchLocation && values.query) {
      url += `?k=${values.query}&l=&display=15`;
    } else {
      url += `?k=${values.query}&l=${values.searchLocation}&display=15`;
    }

    if (typeof window !== 'undefined') {
      window.location.href = url;
    }
  };

  return (
    <header>
      <PageHeadStyledContainer>
        <PageHeadStyledContent>
          <li>
            <PageHeadLogo>
              {logoLink ? (
                <Link href={logoLink}>
                  <Icon name="logo" width={105} />
                </Link>
              ) : (
                <Icon name="logo" width={105} />
              )}
            </PageHeadLogo>
          </li>
          <li>
            <BackToSearchMobileLink
              onClick={() => setShowOnXs(!showOnXs)}
              aria-label={t('form-button-search')}
            >
              {showOnXs ? <Icon name="close-x" /> : <Icon name="search" />}
            </BackToSearchMobileLink>
          </li>
        </PageHeadStyledContent>
      </PageHeadStyledContainer>
      <SearchBox
        compact={collapsed}
        method="get"
        onSubmit={handleSubmit(onSubmit)}
        showOnXs={showOnXs}
      >
        <SearchBoxContent>
          <SearchTextField
            name="query"
            register={register('query', { required: false })}
            placeholder={t('page-header-input-placeholder-jobtitle')}
            noPaddingBottom
          />
          <SearchTextField
            name="searchLocation"
            register={register('searchLocation', { required: false })}
            placeholder={t('page-header-input-placeholder-zip')}
            noPaddingBottom
          />
          <SearchSubmitButton type="button" variant="small-ghost">
            {t('form-button-search')}
          </SearchSubmitButton>
        </SearchBoxContent>
      </SearchBox>
      <PageHeadPlaceholder className={className} compact={compact} />
    </header>
  );
};

export default PageHead;
