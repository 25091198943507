import { Cloudinary } from '@cloudinary/url-gen';

const cld = new Cloudinary({
  cloud: {
    cloudName: 'nexum-ag-adecco',
  },
  url: { secure: true },
});

export default cld;
