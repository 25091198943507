import { FormStructure } from '@adecco/base-app/src/components/organisms/FormRenderer/FormRenderer.types';

export const TopJobsQualified: FormStructure = [
  [
    {
      type: 'first-name',
      required: true,
      inputType: 'text',
    },
    {
      type: 'last-name',
      required: true,
      inputType: 'text',
    },
  ],
  [
    {
      type: 'zip-code',
      required: true,
      inputType: 'text',
    },
    {
      type: 'city',
      required: true,
      inputType: 'text',
    },
  ],
  [
    {
      type: 'email',
      required: true,
      inputType: 'email',
    },
  ],
  [
    {
      type: 'email-validation',
      required: true,
      inputType: 'email',
    },
  ],
  [
    {
      type: 'phone',
      required: true,
      inputType: 'phone',
    },
  ],
];

export const TopJobs: FormStructure = [
  [
    {
      type: 'first-name',
      required: true,
      inputType: 'text',
    },
    {
      type: 'last-name',
      required: true,
      inputType: 'text',
    },
  ],
  [
    {
      type: 'email',
      required: true,
      inputType: 'email',
    },
  ],
  [
    {
      type: 'email-validation',
      required: true,
      inputType: 'email',
    },
  ],
  [
    {
      type: 'phone',
      required: true,
      inputType: 'phone',
    },
  ],
];

export const ClientLandingpage: FormStructure = [
  [
    {
      type: 'first-name',
      required: true,
      inputType: 'text',
    },
    {
      type: 'last-name',
      required: true,
      inputType: 'text',
    },
  ],
  [
    {
      type: 'street-block',
      required: true,
      inputType: 'block',
    },
    {
      type: 'zip-city-block',
      required: true,
      inputType: 'block',
    },
  ],
  [
    {
      type: 'phone',
      required: true,
      inputType: 'phone',
    },
    {
      type: 'location',
      required: true,
      inputType: 'select',
    },
  ],
  [
    {
      type: 'email',
      required: true,
      inputType: 'email',
    },
    {
      type: 'email-validation',
      required: true,
      inputType: 'email',
    },
  ],
];

export const ProActiveForm: FormStructure = [
  [
    {
      type: 'desired-position',
      required: true,
      inputType: 'text',
    },
    {
      type: 'empty',
      required: false,
      inputType: 'empty',
    },
  ],
  [
    {
      type: 'first-name',
      required: true,
      inputType: 'text',
    },
    {
      type: 'last-name',
      required: true,
      inputType: 'text',
    },
  ],
  [
    {
      type: 'street-block',
      required: true,
      inputType: 'block',
    },
    {
      type: 'zip-city-block',
      required: true,
      inputType: 'block',
    },
  ],
  [
    {
      type: 'phone',
      required: true,
      inputType: 'phone',
    },
    {
      type: 'empty',
      required: false,
      inputType: 'empty',
    },
  ],
  [
    {
      type: 'branch',
      required: true,
      inputType: 'datalist',
    },
  ],
  [
    {
      type: 'desired-location',
      required: false,
      inputType: 'text',
    },
    {
      type: 'distance',
      required: false,
      inputType: 'select',
    },
  ],
  [
    {
      type: 'email',
      required: true,
      inputType: 'email',
    },
    {
      type: 'email-validation',
      required: true,
      inputType: 'email',
    },
  ],
];

export const LinkForm: FormStructure = [
  [
    {
      type: 'first-name',
      required: true,
      inputType: 'text',
    },
    {
      type: 'last-name',
      required: true,
      inputType: 'text',
    },
  ],
  [
    {
      type: 'street-block',
      required: true,
      inputType: 'block',
    },
    {
      type: 'zip-city-block',
      required: true,
      inputType: 'block',
    },
  ],
  [
    {
      type: 'phone',
      required: true,
      inputType: 'phone',
    },
    {
      type: 'empty',
      required: false,
      inputType: 'empty',
    },
  ],
  [
    {
      type: 'email',
      required: true,
      inputType: 'email',
    },
    {
      type: 'email-validation',
      required: true,
      inputType: 'email',
    },
  ],
];
