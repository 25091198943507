import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import DynamicImage from '@adecco/base-app/src/components/atoms/DynamicImage/DynamicImage';
import { CloudinaryImageType } from '@adecco/base-app/src/gql/schema/generated';

export type IBrandedBrandBar = {
  brandedLogos: Array<{
    image?: string;
    cloudinaryImage?: CloudinaryImageType;
  }>;
  withSpeechBubble?: boolean;
};

const Wrapper = styled.div<{ withSpeechBubble?: boolean }>`
  background: ${(props) => props.theme.backgrounds.wrapper};
  position: relative;
  margin-inline: auto;
  padding-top: 15px;

  ${moreThan('md')} {
    padding-top: 35px;
  }
`;

const Logos = styled.div`
  //display: grid;
  place-items: end center;
  grid-template-columns: repeat(2, auto);
  gap: 20px;

  ${moreThan('md')} {
    grid-template-columns: repeat(4, auto);
  }
`;

const Img = styled(DynamicImage)`
  position: relative;
  width: 100%;
  object-fit: contain;
  padding: 0 20px;
  max-height: 48px;

  ${moreThan('md')} {
    max-height: 72px;
  }
`;

const BrandedBrandBar: React.FunctionComponent<IBrandedBrandBar> = ({
  brandedLogos,
  withSpeechBubble,
}) => (
  <Wrapper withSpeechBubble={withSpeechBubble}>
    <Logos>
      {brandedLogos.map((brandedLogo, index) => (
        <Img
          image={brandedLogo.image}
          cloudinaryImage={brandedLogo.cloudinaryImage}
          key={`${brandedLogo}-${index}`}
        />
      ))}
    </Logos>
  </Wrapper>
);

export default BrandedBrandBar;
