import * as React from 'react';
import styled from 'styled-components';
import { topline } from '@adecco/base-app/src/styles/typography';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { dateToDMY } from '@adecco/base-app/src/utilities/dateformat/dateformat';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import DynamicImage from '@adecco/base-app/src/components/atoms/DynamicImage/DynamicImage';

export interface IJobHeader {
  onlineDate?: string;
  title?: string;
  topline?: boolean;
  clientLogoImage?: string;
}

const JobHeaderStyled = styled.div`
  width: 100%;
`;

const DateParagraphContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${(props) => props.theme.margins.dateParagraph};
`;

const DateBadge = styled.span`
  background-color: ${(props) => props.theme.colors.success};
  border-radius: ${(props) => props.theme.radius.dateBadge};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.Headline};
  font-size: ${(props) => props.theme.fontSizes.dataBadge};
  margin-right: ${(props) => props.theme.margins.dateBadge};
  padding: 8px 10px;
`;

const DateParagraph = styled.p`
  font-family: ${(props) => props.theme.fonts.Copy};
  color: ${(props) => props.theme.colors.textGrey};
  font-size: ${(props) => props.theme.fontSizes.dateParagraph};
  line-height: ${(props) => props.theme.lineHeights.dateParagraph};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.dateParagraphWideScreen};
    color: ${(props) => props.theme.colors.paragraph};
    line-height: ${(props) => props.theme.lineHeights.dateParagraphWideScreen};
  }
`;

const Topline = styled.div`
  ${topline};
`;

const JobHeaderHeadline = styled(Headline)`
  margin-bottom: ${(props) => props.theme.margins.headline};
  font-size: ${(props) => props.theme.fontSizes.jobHeaderHeadline};
  line-height: ${(props) => props.theme.lineHeights.jobHeaderHeadline};
  hyphens: ${(props) => props.theme.hyphens?.jobHeaderHeadline};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.headline1WideScreen};
    line-height: ${(props) => props.theme.lineHeights.jobHeaderHeadlineWideScreen};
  }
`;

const StyledLogoImage = styled(DynamicImage)`
  max-height: 35px;
  margin-bottom: 30px;

  ${moreThan('md')} {
    max-height: 55px;
  }
`;

const JobHeader: React.FunctionComponent<IJobHeader> = ({
  onlineDate,
  title,
  topline,
  clientLogoImage,
}) => {
  const { t } = useAppContext();
  const [isNew, setIsNew] = React.useState(false);
  const [dateParagraph, setDateParagraph] = React.useState('');

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return `${t('job-header-published')} ${dateToDMY(date)}`;
  };

  React.useEffect(() => {
    const timeStamp = new Date();

    if (onlineDate) {
      setDateParagraph(formatDate(onlineDate));
      setIsNew(formatDate(timeStamp.toString()) === formatDate(onlineDate.toString()));
    }
  }, []);

  return (
    <JobHeaderStyled>
      {onlineDate && (
        <DateParagraphContainer>
          {isNew ? (
            <>
              <DateBadge>{`${t('job-header-new')}`}</DateBadge>
              <DateParagraph>{`${t('job-header-published')} ${t(
                'job-header-today'
              )}`}</DateParagraph>
            </>
          ) : (
            <DateParagraph>{dateParagraph}</DateParagraph>
          )}
        </DateParagraphContainer>
      )}
      {clientLogoImage && <StyledLogoImage image={clientLogoImage} alt="logo image" />}
      {title ? (
        <JobHeaderHeadline>
          {topline && <Topline>{`${t('job-header-applying-as')}: `}</Topline>}
          {title}
        </JobHeaderHeadline>
      ) : null}
    </JobHeaderStyled>
  );
};

export default JobHeader;
