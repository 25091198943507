export const BrowserItems = {
  transaction_id: 'transaction_id',
  lp_data: 'lp_data',
} as const;

export function useBrowserStorage() {
  const getItem = (item: (typeof BrowserItems)[keyof typeof BrowserItems]) => {
    const value = sessionStorage.getItem(item);
    return value ? JSON.parse(value) : '';
  };

  const setItem = (
    item: (typeof BrowserItems)[keyof typeof BrowserItems],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newValue: string | Record<string, any>
  ) => {
    const value = JSON.stringify(newValue);
    sessionStorage.setItem(item, value);
  };

  const removeItem = (item: (typeof BrowserItems)[keyof typeof BrowserItems]) => {
    sessionStorage.removeItem(item);
  };

  return { getItem, setItem, removeItem };
}
