import { QueryKeys } from '@adecco/base-app/src/hooks/useRouterQueries';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Values = Record<string, any>;
interface MonaAiValues extends Values {
  externalID: string;
  jobPostingID?: string;
}

export async function monaAi(values: MonaAiValues) {
  const payload = {
    ...values,
  };

  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);

    const varCampaign =
      searchParams.get(QueryKeys.cdx_campaign) || searchParams.get(QueryKeys.utm_campaign) || '';
    const varContent =
      searchParams.get(QueryKeys.cdx_content) || searchParams.get(QueryKeys.utm_content) || '';
    const varMedium =
      searchParams.get(QueryKeys.cdx_medium) || searchParams.get(QueryKeys.utm_medium) || '';
    const varSource =
      searchParams.get(QueryKeys.cdx_source) || searchParams.get(QueryKeys.utm_source) || '';

    if (varCampaign) payload.varCampaign = varCampaign;
    if (varContent) payload.varContent = varContent;
    if (varMedium) payload.varMedium = varMedium;
    if (varSource) payload.varSource = varSource;
  }

  const res = await fetch('/api/monaAI/pushNewApplicant', {
    method: 'post',
    body: JSON.stringify(payload),
  });

  const { applicantId, error } = await res.json();

  return { applicantId, error };
}
