import * as React from 'react';
import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface IContainer {
  children: React.ReactNode;
  sidebar?: React.ReactNode;
  className?: string;
}

const ContainerStyled = styled.article<{ sidebar: boolean }>`
  width: 100%;
  max-width: ${(props) => props.theme.widths.container};
  margin: ${(props) => props.theme.margins.container};
  padding: ${(props) => props.theme.paddings.container};
  display: flex;
  flex-direction: column;

  ${moreThan('md')} {
    margin-bottom: ${(props) => props.theme.margins.containerWideScreenBottom};
  }

  ${moreThan('lg')} {
    flex-direction: ${(props) => (props.sidebar ? 'row' : 'column')};
  }
`;

const ContainerMain = styled.div`
  ${moreThan('lg')} {
    flex: 1 1 auto;
    // width: calc(100% / 12 * 9);
    padding: 0 15px 0 15px;
  }
`;

const ContainerSidebar = styled.aside`
  padding-top: 30px;
  ${moreThan('lg')} {
    width: 300px; //calc(100% / 12 * 3);
    min-width: 265px;
    padding: 0 0 0 15px;
    display: flex;
    flex-direction: column;
  }
`;

const Container: React.FunctionComponent<IContainer> = ({ children, sidebar, className }) => (
  <ContainerStyled className={className} sidebar={!!sidebar}>
    {sidebar ? (
      <>
        <ContainerMain>{children}</ContainerMain>
        <ContainerSidebar>{sidebar}</ContainerSidebar>
      </>
    ) : (
      children
    )}
  </ContainerStyled>
);

export default Container;
