import * as React from 'react';
import styled from 'styled-components';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import Paragraph from '@adecco/base-app/src/components/atoms/Paragraph/Paragraph';
import Button from '@adecco/base-app/src/components/atoms/Button/Button';
import { CloudinaryImageType } from '@adecco/base-app/src/gql/schema/generated';
import DynamicImage from '@adecco/base-app/src/components/atoms/DynamicImage/DynamicImage';

export interface IThankYouTeaser {
  title: string;
  description: string;
  image?: string;
  link?: string;
  linkText?: string;
  cloudinaryImage?: CloudinaryImageType;
}

const ThankYouTeaserStyled = styled.div`
  position: relative;
  border: ${(props) => props.theme.borders.regular};
  border-radius: ${(props) => props.theme.radius.panel};
  padding: ${(props) => props.theme.paddings.thankYouTeaser};
  overflow: ${(props) => props.theme.overflows.thankYouTeaser};
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: ${(props) => props.theme.widths.thankYouTeaser};

  ${(props) =>
    props.theme.brandColor &&
    `
    border: none;
    overflow: visible;
    
    &::before {
      border-radius: ${props.theme.radius.panel};
      content: '';
      box-shadow: 5px 5px 25px ${props.theme.brandColor};
      opacity: .15;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: 1px solid #D5D5D5;
    }
  `}
`;

const ThankYouTeaserImage = styled.div`
  padding-bottom: 30px;
  margin: ${(props) => props.theme.margins.thankYouTeaserImage};
`;

const Image = styled(DynamicImage)`
  display: block;
  width: 100%;
  border-radius: ${(props) => props.theme.radius.thankYouTeaserImage};
  box-shadow: ${(props) => props.theme.boxShadow.thankYouTeaserImage};
  border-radius: ${(props) => props.theme.brandColor && '10px 10px 0 0'};
`;

const HeadlineStyled = styled(Headline)`
  font-size: ${(props) => props.theme.fontSizes.thankYouTeaser};
  font-family: ${(props) => props.theme.fonts.HeadlineBold};
  color: ${(props) => props.theme.colors.thankYouTeaserHeadline};
`;

const ButtonStyled = styled(Button)`
  margin-top: auto;
  width: fit-content;
`;

const ThankYouTeaser: React.FunctionComponent<IThankYouTeaser> = ({
  title,
  description,
  image,
  link,
  linkText,
  cloudinaryImage,
}) => (
  <ThankYouTeaserStyled>
    <ThankYouTeaserImage>
      {image || cloudinaryImage ? (
        <Image image={image || undefined} cloudinaryImage={cloudinaryImage} alt={title} />
      ) : null}
    </ThankYouTeaserImage>
    <HeadlineStyled skin={3} tag="h4">
      {title}
    </HeadlineStyled>
    <Paragraph>{description}</Paragraph>
    <br />
    <ButtonStyled variant="small" href={link}>
      {linkText}
    </ButtonStyled>
  </ThankYouTeaserStyled>
);

export default ThankYouTeaser;
