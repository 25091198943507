import { css } from 'styled-components';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

const headline1 = css`
  color: ${(props) => props.theme.colors.headline};
  font-family: ${(props) => props.theme.fonts.HeadlineBold};
  font-size: ${(props) => props.theme.fontSizes.headline1};
  line-height: ${(props) => props.theme.lineHeights.headline1};
  margin-bottom: 20px;
  hyphens: auto;

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.headline1WideScreen};
    line-height: ${(props) => props.theme.lineHeights.headline1WideScreen};
    margin-bottom: 48px;
    letter-spacing: -0.78px;
  }
`;

const headline2 = css`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.Headline};
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSizes.typographyHeadline2};
  line-height: ${(props) => props.theme.lineHeights.typographyHeadline2};
  hyphens: auto;
  margin-bottom: 15px;

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.typographyHeadline2WideScreen};
    line-height: ${(props) => props.theme.lineHeights.typographyHeadline2WideScreen};
    margin-bottom: 10px;
  }
`;

const headline3 = css`
  font-family: ${(props) => props.theme.fonts.Headline};
  font-size: ${(props) => props.theme.fontSizes.headline3};
  line-height: ${(props) => props.theme.lineHeights.headline3};
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10px;
  hyphens: auto;

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.headline3};
    line-height: ${(props) => props.theme.lineHeights.headline3WideScreen};
  }
`;

const headline4 = css`
  font-family: ${(props) => props.theme.fonts.Headline};
  font-size: ${calcrem(18)};
  line-height: ${calcrem(20)};
  color: ${(props) => props.theme.colors.text};
  hyphens: auto;
  margin-bottom: 8px;
`;

const copytextbase = css`
  color: ${(props) => props.theme.colors.paragraph};
  font-size: ${(props) => props.theme.fontSizes.text};
  line-height: ${(props) => props.theme.lineHeights.text};
  hyphens: auto;

  ${moreThan('md')} {
    line-height: ${(props) => props.theme.lineHeights.textWideScreen};
    font-size: ${(props) => props.theme.fontSizes.textWideScreen};
  }

  strong {
    font-family: ${(props) => props.theme.fonts.Copy};
    font-weight: 600;
  }

  a {
    color: ${(props) => props.theme.colors.textlink};
    font-family: ${(props) => props.theme.fonts.textLink};

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 0.8rem;
    margin-left: 0.8rem;
    margin-top: 0;
  }
  ol {
    list-style-type: decimal;
    padding-left: 0.8rem;
    margin-left: 0.8rem;
    margin-top: 0;
    margin-bottom: 1em;
  }
`;

const copytext = css`
  ${copytextbase};
  font-family: ${(props) => props.theme.fonts.Copy};
`;

const stepstext = css`
  ${copytext};
  font-size: ${calcrem(14)};
  line-height: ${calcrem(26)};
`;

const topline = css`
  color: ${(props) => props.theme.colors.paragraph};
  font-family: ${(props) => props.theme.fonts.Headline};
  font-size: ${calcrem(28)};
  line-height: ${calcrem(30)};
  margin-bottom: ${calcrem(8)};
  hyphens: auto;

  ${moreThan('md')} {
    font-size: ${calcrem(32)};
    line-height: ${calcrem(36)};
    margin-bottom: ${calcrem(6)};
  }
`;

const buttontext = css`
  color: ${(props) => props.theme.colors.baseButtonText};
  font-family: ${(props) => props.theme.fonts.Copy};
  font-size: ${(props) => props.theme.fontSizes.buttontext};
  line-height: ${({ theme }) => theme.lineHeights.buttontext};
`;

const labeltext = css`
  font-family: ${(props) => props.theme.fonts.input};
  color: ${(props) => props.theme.colors.label};
  font-size: ${(props) => props.theme.fontSizes.label};
  line-height: ${(props) => props.theme.lineHeights.label};
`;

const inputtext = css`
  font-family: ${(props) => props.theme.fonts.input};
  color: ${(props) => props.theme.colors.inputText};
  font-size: ${calcrem(18)};
  line-height: ${calcrem(20)};
`;

const placeholdertext = css`
  font-family: ${(props) => props.theme.fonts.input};
  color: ${(props) => props.theme.colors.textGrey};
  font-size: ${(props) => props.theme.fontSizes.placeholder};
  line-height: ${(props) => props.theme.lineHeights.placeholder};
`;

const progresstext = css`
  font-family: ${(props) => props.theme.fonts.Copy};
  color: ${(props) => props.theme.colors.paragraph};
  font-size: ${calcrem(14)};
  line-height: ${calcrem(17)};
`;

const formhead = css`
  font-family: ${(props) => props.theme.fonts.formHead};
  -webkit-font-smoothing: antialiased;
  font-size: ${(props) => props.theme.fontSizes.formHead};
  line-height: ${(props) => props.theme.lineHeights.formHead};
  color: ${(props) => props.theme.colors.text};

  ${moreThan('md')} {
    font-family: ${(props) => props.theme.fonts.formHeadWideScreen};
    font-size: ${(props) => props.theme.fontSizes.formHeadWideScreen};
    line-height: ${(props) => props.theme.lineHeights.formHeadWideScreen};
  }
`;

const helptext = css`
  font-family: ${(props) => props.theme.fonts.HeadlineBold};
  color: ${(props) => props.theme.colors.paragraph};
  font-weight: bold;
  font-size: ${calcrem(14)};
  line-height: ${calcrem(17)};
`;

const errortext = css`
  font-family: ${(props) => props.theme.fonts.input};
  color: ${(props) => props.theme.colors.error};
  font-size: ${calcrem(18)};
  line-height: ${(props) => props.theme.lineHeights.errorText};
`;

const contacttext = css`
  font-size: ${calcrem(16)};
  line-height: ${calcrem(20)};
  margin-bottom: ${calcrem(10)};
`;

const contactheadline = css`
  font-size: ${calcrem(24)};
  font-weight: normal;
  line-height: ${calcrem(24)};
`;

export {
  headline1,
  headline2,
  headline3,
  headline4,
  copytextbase,
  copytext,
  stepstext,
  topline,
  buttontext,
  labeltext,
  inputtext,
  placeholdertext,
  formhead,
  progresstext,
  helptext,
  errortext,
  contacttext,
  contactheadline,
};
