import { useEffect } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

export type IErrors = ReadonlyArray<
  | {
      field: string;
    }
  | undefined
>;

interface IUseApplicationFieldErrors {
  errors: IErrors;
  setErrorMessage: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<FieldValues, any>;
}

export const useApplicationFieldErrors = ({
  errors,
  setErrorMessage,
  formMethods,
}: IUseApplicationFieldErrors) => {
  const { getValues, clearErrors, setError } = formMethods;
  const values = getValues();
  const fields = Object.keys(values);
  const isField = (field: string) => fields.some((f) => f === field);

  useEffect(() => {
    if (!errors) return;
    errors.forEach((error, index) => {
      let field = error?.field;

      if (!field || !isField(field)) {
        setErrorMessage(true);
        clearErrors();
        return;
      }

      if (field === 'phoneCountryCode') field = 'phone';
      setError(field, {}, { shouldFocus: index === 0 });
    });
  }, [errors]);
};
