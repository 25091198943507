import { FormFieldsType } from '@adecco/base-app/src/gql/schema/generated';

const transformFormFieldsSet = (formSets: FormFieldsType[]) => {
  const formSetsCopy = [...formSets]
    .sort((a, b) => a.column - b.column)
    .sort((a, b) => a.row - b.row);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let formStructure: any[] = [];
  formSetsCopy.forEach((formSet) => {
    // eslint-disable-next-line no-unused-expressions
    if (!formStructure[formSet.row - 1]) {
      formStructure = [...formStructure, []];
    }

    formStructure[formSet.row - 1]!.push({
      type: formSet.field.name,
      required: formSet.required,
      inputType: formSet.field.fieldType?.name,
      ...(formSet.field.defaultValue && { defaultValue: formSet.field.defaultValue }),
    });
  });

  return formStructure;
};

export default transformFormFieldsSet;
