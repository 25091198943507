import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import {
  BrandedLandingPageDocument,
  BrandedLandingPageQuery,
} from '@adecco/base-app/src/gql/schema/generated';
import { withApolloProps } from '@adecco/base-app/src/apollo/withApolloProps';
import { useDataLayer } from '@adecco/base-app/src/hooks/useDatalayer';
import { useState } from 'react';
import getConfig from 'next/config';
import getConfiguration from '@adecco/base-app/src/utilities/getConfiguration/getConfiguration';
import BrandedLandingPageApp from '@adecco/base-app/src/components/templates/BrandedLandingPageApp/BrandedLandingPageApp';
import { EventData, useDataLayerPushEvent } from '@adecco/base-app/src/hooks/useDataLayerPushEvent';
import { ITEM_BRANDS } from '@adecco/base-app/src/constants/itemBrands';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

interface IIndex {
  data: BrandedLandingPageQuery;
  lpId: number;
}

export const PageType = 'Pure-Client-Landingpage';

const GroupListPage: NextPage<IIndex> = ({ data }) => {
  const { brand } = useAppContext();
  const [formIsDirty, setFormIsDirty] = useState(false);

  useDataLayer(data.landingpageBranded, PageType, false, true, {
    job_category_id: data!.landingpageBranded!.kldb,
  });
  useDataLayerPushEvent({ event: 'page_view' });
  useDataLayerPushEvent({ event: 'candidature_start_pureclientjob' });

  const eventData: EventData = {
    ecommerce: {
      affiliation: 'pureclientjob',
      items: [
        {
          item_name: data.landingpageBranded?.title || 'na',
          item_id: 'na',
          item_category: 'na',
          item_category2: 'na',
          item_category3: 'na',
          item_category4: 'na',
          item_category5: data.landingpageBranded?.kldb || 'na',
          item_variant: 'na',
          item_brand: ITEM_BRANDS[brand],
          client: data.landingpageBranded?.brand || 'na',
        },
      ],
    },
  };

  useDataLayerPushEvent({
    event: 'view_cart',
    data: eventData,
  });

  useDataLayerPushEvent({
    event: 'begin_checkout',
    data: eventData,
    deps: [formIsDirty],
    checker: formIsDirty,
  });

  return (
    <>
      <Head>
        <title>{data ? data?.landingpageBranded?.title : 'Adecco Jobs'}</title>
      </Head>
      {/*  @ts-ignore */}
      <BrandedLandingPageApp {...data?.landingpageBranded} setFormIsDirty={setFormIsDirty} />
    </>
  );
};

export const getStaticProps: GetStaticProps = (context) =>
  withApolloProps(async (client) => {
    if (!context.params)
      return {
        notFound: true,
        revalidate: 1,
      };

    const { publicRuntimeConfig } = getConfig();
    const lpIdParam = context?.params?.lpIdParam;
    const lpId = Array.isArray(lpIdParam) ? lpIdParam[0] : lpIdParam;
    const { data } = await client.query<BrandedLandingPageQuery>({
      query: BrandedLandingPageDocument,
      variables: {
        slug: String(lpId),
        languages: publicRuntimeConfig.LANGUAGES,
        // @ts-ignore
        secret: context.preview ? context.previewData?.key : undefined,
        countryCode: context.locale ?? '',
      },
      errorPolicy: 'ignore',
    });

    if (
      data.landingpageBranded === null ||
      (!data.landingpageBranded?.online && typeof context.preview === 'undefined') ||
      getConfiguration(data.frontendConfigurations, 'withClientLandingpage') === 'false'
    ) {
      return {
        notFound: true,
        revalidate: 1,
      };
    }

    return {
      props: {
        data,
        path: `lpl/${lpId}`,
        lpId,
        pageType: PageType,
      },
      revalidate: 1,
    };
  });

export const getStaticPaths: GetStaticPaths = () =>
  Promise.resolve({
    fallback: 'blocking',
    paths: [],
  });

export default GroupListPage;
