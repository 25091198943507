import * as React from 'react';
import styled from 'styled-components';
import FormField, { RequiredStar } from '@adecco/base-app/src/components/atoms/FormField/FormField';
import { copytext } from '@adecco/base-app/src/styles/typography';
import { IInputWithError } from '@adecco/base-app/src/components/atoms/TextField/TextField';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface IFormRow {
  label?: string;
  name: string;
  error?: boolean;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  children: string;
  required?: boolean;
  redStar?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  noPaddingBottom?: boolean;
}

interface IActualCheckBox {
  error?: boolean;
  required?: boolean;
}

const CheckBoxContainer = styled.div`
  position: relative;
  display: ${(props) => props.theme.displays.checkboxContainer};

  ${moreThan('md')} {
    display: flex;
  }
`;

const CheckMark = styled.span<IInputWithError>`
  position: relative;
  float: left;
  margin-right: ${calcrem(10)};
  margin-top: ${(props) => props.theme.margins.checkMark};
  display: block;
  border-radius: ${(props) => props.theme.radius.checkBox};
  width: ${(props) => props.theme.widths.checkMark};
  height: ${(props) => props.theme.heights.checkMark};
  flex: 0 0 auto;
  border: ${(props) =>
    props.error ? props.theme.borders.checkMarkPrimary : props.theme.borders.checkMarkDarkGrey};
  box-shadow: ${(props) => props.theme.boxShadow.checkBox};
  background-color: ${(props) => props.theme.backgrounds.checkBox};

  ${moreThan('md')} {
    float: none;
    margin-top: ${(props) => props.theme.margins.checkMarkWideScreen};
    width: ${calcrem(24)};
    height: ${calcrem(24)};
  }
`;

const ActualCheckBox = styled.input<IActualCheckBox>`
  cursor: pointer;
  width: ${calcrem(32)};
  height: ${calcrem(32)};
  color: ${(props) => (props.error ? props.theme.colors.primary : props.theme.colors.paragraph)};
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  z-index: 1;
  &:checked + ${CheckMark} {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: ${(props) => props.theme.radius.checkBox};
      background-color: ${(props) => props.theme.colors.success};
      width: calc(100% - 7px);
      height: calc(100% - 7px);
      transform: translate3d(-50%, -50%, 0);
    }
  }
  ${moreThan('md')} {
    width: ${calcrem(20)};
    height: ${calcrem(20)};
  }
`;

const CheckText = styled.label<{ error?: boolean }>`
  ${copytext};
  vertical-align: top;
  font-size: ${(props) => props.theme.fontSizes.checkText};
  line-height: ${(props) => props.theme.lineHeights.checkText};
  hyphens: auto;
  word-break: break-word;
  color: ${(props) =>
    props.error ? props.theme.colors.checkTextError : props.theme.colors.paragraph};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.checkTextWideScreen};
    line-height: ${(props) => props.theme.lineHeights.checkTextWideScreen};
  }

  & a {
    ${(props) =>
      props.error &&
      props.theme.colors.errorLink &&
      `  
      color: ${props.theme.colors.errorLink};
      `}
  }
`;

const CheckBox: React.FunctionComponent<IFormRow> = ({
  label,
  name,
  placeholder,
  children,
  register,
  error,
  required,
  onChange,
  className,
  noPaddingBottom,
}) => (
  <FormField
    label={label}
    name={name}
    required={required}
    className={className}
    noPaddingBottom={noPaddingBottom}
  >
    <CheckBoxContainer>
      {typeof onChange !== 'undefined' ? (
        <ActualCheckBox
          type="checkbox"
          name={name}
          id={name}
          placeholder={placeholder}
          {...register}
          onChange={onChange}
          error={error}
          data-cy={`checkbox-${name}`}
        />
      ) : (
        <ActualCheckBox
          type="checkbox"
          name={name}
          id={name}
          placeholder={placeholder}
          error={error}
          {...register}
          data-cy={`checkbox-${name}`}
        />
      )}
      <CheckMark error={error} />
      <p>
        <CheckText
          htmlFor={name}
          dangerouslySetInnerHTML={{
            __html: children,
          }}
          error={error}
        />
        {required && <RequiredStar red={error}> *</RequiredStar>}
      </p>
    </CheckBoxContainer>
  </FormField>
);

export default CheckBox;
