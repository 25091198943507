import { ILogoButtonItem } from '@adecco/base-app/src/components/molecules/LogoButtonRow/LogoButtonRow';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

const useLogoButtonRowItems = (): ILogoButtonItem[] => {
  const { socialLinks } = useAppContext();
  const logoButtonRowItems: ILogoButtonItem[] = [];

  if (socialLinks?.Kununu) {
    logoButtonRowItems.push({
      logo: 'logo_kununu',
      alt: 'Kununu',
      href: socialLinks.Kununu,
    });
  }

  if (socialLinks?.Xing) {
    logoButtonRowItems.push({
      logo: 'logo_xing',
      alt: 'Xing',
      href: socialLinks.Xing,
    });
  }

  if (socialLinks?.GoogleMaps) {
    logoButtonRowItems.push({
      logo: 'logo_maps',
      alt: 'Google Maps',
      href: socialLinks.GoogleMaps,
    });
  }

  if (socialLinks?.Facebook) {
    logoButtonRowItems.push({
      logo: 'logo_facebook',
      alt: 'Facebook',
      href: socialLinks.Facebook,
    });
  }

  if (socialLinks?.LinkedIn) {
    logoButtonRowItems.push({
      logo: 'logo_linkedin',
      alt: 'LinkedIn',
      href: socialLinks.LinkedIn,
    });
  }

  return logoButtonRowItems;
};

export { useLogoButtonRowItems };
