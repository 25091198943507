import * as React from 'react';
import styled from 'styled-components';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

const SpinnerStyled = styled.div`
  text-align: center;

  svg {
    margin-right: 1ex;
    height: 2ex;
    animation: rotate 2s linear infinite;
    aspect-ratio: 1 / 1;
    stroke-width: 0.3ex;

    & circle {
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Spinner: React.FunctionComponent = () => {
  const { t } = useAppContext();

  return (
    <SpinnerStyled>
      <svg viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20" fill="none" />
      </svg>
      {t('spinner-loading')}
    </SpinnerStyled>
  );
};

export default Spinner;
