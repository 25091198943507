import * as React from 'react';
import dynamic from 'next/dynamic';
import {
  DynamicLandingpageElementsEdge,
  ProcessElementType,
  BenefitsElementType,
  RequirementsElementType,
  FaqElementType,
  LocationElementType,
  MediaElementType,
  MoreInfoElementType,
  TextElementType,
  SalaryElementType,
  JobSearchElementType,
  FactsElementType,
  ListElementType,
  LinkListElementType,
  TabsElementType,
  CtaElementType,
} from '@adecco/base-app/src/gql/schema/generated';

const Process = dynamic(() => import('@adecco/base-app/src/components/molecules/Process/Process'));
const BenefitList = dynamic(
  () => import('@adecco/base-app/src/components/molecules/BenefitList/BenefitList')
);
const ApplyStepsList = dynamic(
  () => import('@adecco/base-app/src/components/molecules/ApplyStepsList/ApplyStepsList')
);
const SpacerWithGradient = dynamic(
  () => import('@adecco/base-app/src/components/molecules/SpacerWithGradient/SpacerWithGradient')
);
const FaqList = dynamic(() => import('@adecco/base-app/src/components/molecules/FaqList/FaqList'));
const MultiMediaElement = dynamic(
  () => import('@adecco/base-app/src/components/molecules/MultiMediaElement/MultiMediaElement')
);
const LocationAccordion = dynamic(
  () => import('@adecco/base-app/src/components/molecules/LocationAccordion/LocationAccordion')
);
const ImageTeasers = dynamic(
  () => import('@adecco/base-app/src/components/molecules/ImageTeasers/ImageTeasers')
);
const TwoColText = dynamic(
  () => import('@adecco/base-app/src/components/molecules/TwoColText/TwoColText')
);
const Salary = dynamic(() => import('@adecco/base-app/src/components/molecules/Salary/Salary'));
const JobSearchList = dynamic(
  () => import('@adecco/base-app/src/components/molecules/JobSearchList/JobSearchList')
);
const FactList = dynamic(
  () => import('@adecco/base-app/src/components/molecules/FactList/FactList')
);
const ListComponent = dynamic(
  () => import('@adecco/base-app/src/components/molecules/ListComponent/ListComponent')
);
const LinkList = dynamic(
  () => import('@adecco/base-app/src/components/molecules/LinkList/LinkList')
);
const TabsModule = dynamic(
  () => import('@adecco/base-app/src/components/molecules/TabsModule/TabsModule')
);
const CallToAction = dynamic(
  () => import('@adecco/base-app/src/components/molecules/CallToAction/CallToAction')
);

// add here all Element Types
export type ElementNames =
  | 'BenefitsElementType'
  | 'ProcessElementType'
  | 'RequirementsElementType'
  | 'SeparatorElementType'
  | 'FAQElementType'
  | 'LocationElementType'
  | 'MediaElementType'
  | 'MoreInfoElementType'
  | 'TextElementType'
  | 'SalaryElementType'
  | 'JobSearchElementType'
  | 'FactsElementType'
  | 'ListElementType'
  | 'LinkListElementType'
  | 'TabsElementType'
  | 'CtaElementType';

type IElementMap = DynamicLandingpageElementsEdge & {
  centeredHeadline?: boolean;
};

const ElementMap: React.FunctionComponent<IElementMap> = ({ node, centeredHeadline }) => {
  const elements: { [key in ElementNames]: JSX.Element } = {
    BenefitsElementType: (
      <BenefitList
        title={(node as BenefitsElementType).title}
        entries={(node as BenefitsElementType).benefitselementitemSet?.map((benefitElement) => ({
          name: benefitElement.title,
          icon: benefitElement.image,
          cloudinaryImage: benefitElement.cloudinaryImage,
        }))}
        ctaLink={(node as BenefitsElementType).buttonLink}
        ctaTitle={(node as BenefitsElementType).buttonText}
        centeredHeadline={centeredHeadline}
      />
    ),
    ProcessElementType: (
      <Process
        {...(node as ProcessElementType)}
        items={(node as ProcessElementType).processelementitemSet}
        centeredHeadline={centeredHeadline}
      />
    ),
    RequirementsElementType: (
      <ApplyStepsList
        {...(node as RequirementsElementType)}
        items={(node as RequirementsElementType)?.requirementselementitemSet}
        centeredHeadline={centeredHeadline}
      />
    ),
    SeparatorElementType: <SpacerWithGradient />,
    FAQElementType: (
      <FaqList
        {...(node as FaqElementType)}
        entries={(node as FaqElementType).faqelementitemSet}
        centeredHeadline={centeredHeadline}
      />
    ),
    LocationElementType: (
      <LocationAccordion
        {...(node as LocationElementType)}
        entries={(node as LocationElementType).locationelementitemSet}
        centeredHeadline={centeredHeadline}
      />
    ),
    MediaElementType: (
      <MultiMediaElement
        aboveLine={(node as MediaElementType).aboveLine ?? ''}
        headline={(node as MediaElementType).title}
        text={(node as MediaElementType).mediaText}
        media={{
          // @ts-ignore
          type: (node as MediaElementType).mediaType,
          // @ts-ignore
          position: (node as MediaElementType).mediaPosition,
          // @ts-ignore
          src: (node as MediaElementType).mediaFile || (node as MediaElementType).mediaSrc,
          title: (node as MediaElementType).title,
          cloudinaryImage: (node as MediaElementType).cloudinaryImage,
        }}
        button={{
          text: (node as MediaElementType).buttonText,
          link: (node as MediaElementType).buttonLink,
        }}
        centeredHeadline={centeredHeadline}
      />
    ),
    MoreInfoElementType: (
      <ImageTeasers
        {...(node as MoreInfoElementType)}
        teasers={(node as MoreInfoElementType).moreinfoelementitemSet}
        centeredHeadline={centeredHeadline}
      />
    ),
    TextElementType: (
      <TwoColText
        headline={(node as TextElementType).title}
        subline={(node as TextElementType).subline}
        text={(node as TextElementType).text}
        withoutSpaceAtBottom
        centeredHeadline={centeredHeadline}
      />
    ),
    SalaryElementType: (
      <Salary
        title={(node as SalaryElementType).title}
        subline={(node as SalaryElementType).subline}
        salary25={(node as SalaryElementType).min}
        salary75={(node as SalaryElementType).max}
        description={(node as SalaryElementType).description}
      />
    ),
    JobSearchElementType: (
      <JobSearchList
        title={(node as JobSearchElementType).title}
        kldbNumbers={(node as JobSearchElementType)?.jobsearchelementitemSet?.map(
          (jobsearchelementitem) => jobsearchelementitem.jobadsGroupId
        )}
      />
    ),
    FactsElementType: (
      <FactList
        title={(node as FactsElementType).title}
        entries={(node as FactsElementType).factselementitemSet}
        centeredHeadline={centeredHeadline}
      />
    ),
    ListElementType: (
      <ListComponent
        items={(node as ListElementType)?.listelementitemSet?.map((item) => item.itemText)}
      />
    ),
    LinkListElementType: (
      <LinkList
        heading={(node as LinkListElementType).optionalTitle}
        linkList={(node as LinkListElementType)?.linklistelementitemSet?.map((item) => ({
          linkTitle: item.linkTitle,
          linkUrl: item.linkUrl,
          target: item.target,
        }))}
        centeredHeadline={centeredHeadline}
      />
    ),
    TabsElementType: (
      <TabsModule
        headline={(node as TabsElementType).title}
        subHeadline={(node as TabsElementType).tabsSubline}
        tabContents={(node as TabsElementType).tabselementitemSet}
        centeredHeadline={centeredHeadline}
      />
    ),
    CtaElementType: (
      <CallToAction
        headline={(node as CtaElementType).title}
        subline={(node as CtaElementType).subline}
        link={(node as CtaElementType).linkUrl}
        buttonText={(node as CtaElementType).buttonText}
      />
    ),
  };

  return (
    // eslint-disable-next-line no-underscore-dangle
    elements[node!.__typename as ElementNames] ?? (
      <mark>
        {/* eslint-disable-next-line no-underscore-dangle */}
        The element {node!.__typename} is missing
        <br />
      </mark>
    )
  );
};

export default ElementMap;
