import * as React from 'react';
import { useRouter } from 'next/router';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import TextField from '@adecco/base-app/src/components/atoms/TextField/TextField';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { usePatterns } from '@adecco/base-app/src/hooks/usePatterns';
import { getZipCodeHelpText } from '@adecco/base-app/src/utilities/getZipCodeHelpText';
import { BlockInputType } from './FormElementRenderer.types';
import { configInputFields } from './configInputFields';

const BlockInput: React.FunctionComponent<BlockInputType> = ({
  type,
  register,
  errors,
  size,
  required = false,
}) => {
  const { t } = useAppContext();
  const router = useRouter();
  const patterns = usePatterns();

  const currentCountry = router.locale?.split('-')[0];

  if (type === 'street-block') {
    return (
      <FieldGroup size={1 / size}>
        <FieldGroup size={4 / 5} tabletSize={8 / 10} mobileSize={3 / 4}>
          <TextField
            label={t(configInputFields.street.label)}
            helpText={configInputFields.street.help ? t(configInputFields.street.help!) : undefined}
            name={configInputFields.street.name}
            register={register(configInputFields.street.name, { required })}
            error={!!errors[configInputFields.street.name]}
            required={required}
          />
          {errors[configInputFields.street.name] && (
            <ErrorMessage
              text={t(configInputFields.street.error)}
              elementName={configInputFields.street.name}
            />
          )}
        </FieldGroup>
        <FieldGroup size={1 / 5} tabletSize={2 / 10} mobileSize={1 / 4}>
          <TextField
            label={t(configInputFields.number.label)}
            helpText={configInputFields.number.help ? t(configInputFields.number.help!) : undefined}
            name={configInputFields.number.name}
            register={register(configInputFields.number.name, { required })}
            error={!!errors[configInputFields.number.name]}
            required={required}
          />
          {errors[configInputFields.number.name] && (
            <ErrorMessage
              text={t(configInputFields.number.error)}
              elementName={configInputFields.number.name}
            />
          )}
        </FieldGroup>
      </FieldGroup>
    );
  }

  return (
    <FieldGroup size={1 / size}>
      <FieldGroup size={1 / 2} tabletSize={1 / 2} mobileSize={2 / 5}>
        <TextField
          label={t(configInputFields['zip-code'].label)}
          helpText={getZipCodeHelpText(
            currentCountry,
            !!errors[configInputFields['zip-code'].name]
          )}
          name={configInputFields['zip-code'].name}
          register={register(configInputFields['zip-code'].name, {
            required,
            pattern: patterns['zip-code'] || undefined,
          })}
          error={!!errors[configInputFields['zip-code'].name]}
          required={required}
        />
        {errors[configInputFields['zip-code'].name] && (
          <ErrorMessage text={t(configInputFields['zip-code'].error)} elementName={configInputFields['zip-code'].name} />
        )}
      </FieldGroup>
      <FieldGroup size={1 / 2} tabletSize={1 / 2} mobileSize={3 / 5}>
        <TextField
          label={t(configInputFields.city.label)}
          helpText={configInputFields.city.help ? t(configInputFields.city.help!) : undefined}
          name={configInputFields.city.name}
          register={register(configInputFields.city.name, { required })}
          error={!!errors[configInputFields.city.name]}
          required={required}
        />
        {errors[configInputFields.city.name] && (
          <ErrorMessage text={t(configInputFields.city.error)} elementName={configInputFields.city.name} />
        )}
      </FieldGroup>
    </FieldGroup>
  );
};

export default BlockInput;
