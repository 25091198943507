import * as React from 'react';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import SelectField, {
  ISelectValue,
} from '@adecco/base-app/src/components/atoms/SelectField/SelectField';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { SelectInputType } from './FormElementRenderer.types';
import { configInputFields } from './configInputFields';
import { getGreetValues } from '@adecco/base-app/src/utilities/transformText/getGreetValues';
import { getValuesFromTranslation } from '@adecco/base-app/src/utilities/transformText/getValuesFromTranslation';

const SelectInput: React.FunctionComponent<SelectInputType> = ({
  type,
  register,
  errors,
  size,
  required,
  locationFields,
  values,
  defaultValue = '',
}) => {
  const { t } = useAppContext();

  const getValues = () => {
    if (values) return values;

    if (type === 'location') {
      const getLocationFields = (locationFields: ISelectValue[]) =>
        locationFields.length > 1 ? [{ name: '', value: '' }, ...locationFields] : locationFields;
      return locationFields ? getLocationFields(locationFields) : [];
    }

    if (type === 'distance') {
      const distanceValues = getValuesFromTranslation(t('form-field-distance-value'));

      return distanceValues;
    }

    if (type === 'greetings') {
      return getGreetValues(defaultValue);
    }

    return [];
  };

  return (
    <FieldGroup size={1 / size}>
      <SelectField
        label={t(configInputFields[type].label)}
        helpText={configInputFields[type].help ? t(configInputFields[type].help!) : undefined}
        name={configInputFields[type].name}
        register={register(configInputFields[type].name, {
          required,
          validate: {
            isEmpty: (value) => {
              if (!required) {
                return true;
              }
              return value !== '';
            },
          },
        })}
        required={required}
        error={!!errors[configInputFields[type].name]}
        values={getValues()}
      />
      {errors[configInputFields[type].name] && (
        <ErrorMessage
          text={t(configInputFields[type].error)}
          elementName={configInputFields[type].name}
        />
      )}
    </FieldGroup>
  );
};

export default SelectInput;
