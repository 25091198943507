import * as React from 'react';
import styled from 'styled-components';
import Icon, { IconStyled, IIcon } from '@adecco/base-app/src/components/atoms/Icon/Icon';

interface ILogoButton extends IIcon {
  background?: string;
}

interface IIconStyled {
  border?: string;
  background?: string;
}

const LogoButtonStyled = styled.div<IIconStyled>`
  display: inline-block;
  height: 63px;
  padding: 10px 20px 15px;
  border: 1px solid ${(props) => props.border || props.theme.colors.lighterGrey};
  border-radius: 6.5px;
  background-color: ${(props) => (props.background ? props.background : 'rgb(252, 252, 252);')};

  ${IconStyled} {
    height: 100%;

    svg {
      width: auto;
    }
  }
`;

const LogoButtonContent = styled.div`
  display: flex;
  height: 100%;
`;

const LogoButton: React.FunctionComponent<ILogoButton> = ({ background, ...props }) => {
  let height = 30;
  let padding = 0;
  if (props.name === 'logo_kununu') {
    padding = 5;
  }
  if (props.name === 'logo_maps') {
    padding = 8;
  }
  if (props.name === 'logo_facebook') {
    padding = 5;
  }
  if (props.name === 'logo_xing') {
    height = 35;
  }
  if (props.name === 'logo_facebook') {
    height = 28;
  }
  if (props.name === 'logo_linkedin') {
    height = 35;
  }
  return (
    <LogoButtonStyled border={props.fill} background={background}>
      <LogoButtonContent
        style={{
          paddingTop: padding,
        }}
      >
        <Icon {...props} height={height} />
      </LogoButtonContent>
    </LogoButtonStyled>
  );
};

export default LogoButton;
