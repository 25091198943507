import * as React from 'react';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import { FormElementType } from './FormElementRenderer.types';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import PhoneFormInput from './PhoneFormInput';
import EmailInput from './EmailInput';
import BlockInput from './BlockInput';
import DatalistInput from './DatalistInput';
import { configInputFields } from './configInputFields';

export const FormElementRenderer: React.FunctionComponent<FormElementType> = ({
  type,
  inputType,
  register,
  errors,
  size,
  getValues,
  control,
  required = false,
  locationFields,
  values,
  defaultValue,
  branches,
}) => {
  const FallBackElement = <FieldGroup size={1 / size} />;

  if (!configInputFields[type]) return FallBackElement;

  let Element;

  switch (inputType) {
    case 'text':
      Element = (
        <TextInput
          type={type}
          register={register}
          errors={errors}
          size={size}
          required={required}
        />
      );
      break;
    case 'select':
      Element = (
        <SelectInput
          type={type}
          register={register}
          errors={errors}
          size={size}
          required={required}
          locationFields={locationFields}
          values={values}
          defaultValue={defaultValue}
        />
      );
      break;
    case 'phone':
      Element = (
        <PhoneFormInput
          type={type}
          register={register}
          errors={errors}
          size={size}
          required={required}
          control={control}
        />
      );
      break;
    case 'email':
      Element = (
        <EmailInput
          type={type}
          register={register}
          errors={errors}
          size={size}
          required={required}
          getValues={getValues}
        />
      );
      break;
    case 'block':
      Element = (
        <BlockInput
          type={type}
          register={register}
          errors={errors}
          size={size}
          required={required}
        />
      );
      break;
    case 'datalist':
      Element = (
        <DatalistInput
          type={type}
          register={register}
          errors={errors}
          size={size}
          required={required}
          branches={branches}
        />
      );
      break;
    default:
      Element = FallBackElement;
  }

  return Element;
};
