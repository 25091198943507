import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import BrandedThankYouDe from '@adecco/base-app/src/components/templates/BrandedThankYouDe/BrandedThankYouDe';
import { useDataLayer } from '@adecco/base-app/src/hooks/useDatalayer';
import {
  LandingpageBrandedSentDocument,
  LandingpageBrandedSentQuery,
} from '@adecco/base-app/src/gql/schema/generated';
import { withApolloProps } from '@adecco/base-app/src/apollo/withApolloProps';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import getConfiguration from '@adecco/base-app/src/utilities/getConfiguration/getConfiguration';
import { useDataLayerPushEvent } from '@adecco/base-app/src/hooks/useDataLayerPushEvent';
import { ITEM_BRANDS } from '@adecco/base-app/src/constants/itemBrands';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

const Qualtrics = dynamic(
  () => import('@adecco/base-app/src/components/atoms/Qualtrics/Qualtrics')
);

interface IAdeccoDeThankYouPage {
  data: LandingpageBrandedSentQuery;
}

export const PageType = 'Pure-Client-Landingpage Bewerbung gesendet';

const AdeccoDeThankYouPage: NextPage<IAdeccoDeThankYouPage> = ({ data }) => {
  const { brand } = useAppContext();
  useDataLayer(data!.landingpageBranded, PageType, true, false, {
    job_category_id: data!.landingpageBranded!.kldb,
  });
  useDataLayerPushEvent({ event: 'page_view' });
  useDataLayerPushEvent({ event: 'candidature_sent_pureclientjob' });
  useDataLayerPushEvent({
    event: 'purchase',
    data: {
      ecommerce: {
        affiliation: 'pureclientjob',
        currency: 'EUR',
        value: '1',
        items: [
          {
            item_name: data.landingpageBranded?.title || 'na',
            item_id: 'na',
            item_category: 'na',
            item_category2: 'na',
            item_category3: 'na',
            item_category4: 'na',
            item_category5: data.landingpageBranded?.kldb || 'na',
            item_variant: 'na',
            item_brand: ITEM_BRANDS[brand],
            client: data.landingpageBranded?.brand || 'na',
          },
        ],
      },
    },
  });

  return (
    <>
      <BrandedThankYouDe
        // @ts-ignore
        thankyouTeasers={data.thankyouTeasers}
        // @ts-ignore
        recoTeasers={data.recoTeasers}
        image={data.landingpageBranded?.image}
        cloudinaryImage={data.landingpageBranded?.cloudinaryImage}
        brandedlandingpagelogoSet={[
          ...(data.landingpageBranded?.brandedlandingpagelogoSet || []),
        ].slice(0, 4)}
      />
      <Qualtrics />
    </>
  );
};

export const getStaticProps: GetStaticProps = (context) =>
  withApolloProps(async (client) => {
    if (!context.params)
      return {
        notFound: true,
        revalidate: 1,
      };
    const { publicRuntimeConfig } = getConfig();
    const lpIdParam = context?.params?.lpIdParam;

    const lpId = Array.isArray(lpIdParam) ? lpIdParam[0] : lpIdParam;

    const { data } = await client.query<LandingpageBrandedSentQuery>({
      query: LandingpageBrandedSentDocument,
      variables: {
        slug: String(lpId),
        languages: publicRuntimeConfig.LANGUAGES,
        // @ts-ignore
        secret: context.preview ? context.previewData?.key : undefined,
        countryCode: context.locale ?? '',
      },
      errorPolicy: 'ignore',
    });

    if (
      data.landingpageBranded === null ||
      (!data.landingpageBranded?.online && typeof context.preview === 'undefined') ||
      getConfiguration(data.frontendConfigurations, 'withClientLandingpage') === 'false'
    ) {
      return {
        notFound: true,
        revalidate: 1,
      };
    }

    return {
      props: {
        data,
        pageType: PageType,
      },
      revalidate: 1,
    };
  });

export const getStaticPaths: GetStaticPaths = () =>
  Promise.resolve({
    fallback: 'blocking',
    paths: [],
  });

export default AdeccoDeThankYouPage;
