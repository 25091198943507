import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { buttontext, labeltext, placeholdertext } from '@adecco/base-app/src/styles/typography';
import { buttonBase } from '@adecco/base-app/src/components/atoms/Button/Button';

interface IFileLabel {
  small?: boolean;
}

interface IFakeField {
  error: boolean;
}

export const HiddenFileInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  top: -50px;
  left: -50px;
  visibility: hidden;
`;

export const FileLabel = styled.label<IFileLabel>`
  position: relative;
  width: 100%;
  display: ${(props) => (props.small ? 'block' : 'flex')};
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;

  ${moreThan('md')} {
    flex-direction: row;
    justify-content: stretch;
  }
`;

export const FieldRow = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.margins.fieldRow};

  ${moreThan('md')} {
    margin-bottom: ${(props) => props.theme.margins.fieldRowWideScreen};
  }
`;

export const FakeField = styled.span<IFakeField>`
  background: ${(props) => props.theme.backgrounds.input};
  min-height: ${(props) => props.theme.heights.input};
  display: flex;
  align-items: center;
  position: relative;
  flex: 1 1 auto;
  border-radius: ${(props) => props.theme.radius.fileField};
  border: ${(props) =>
    props.error ? props.theme.borders.errorField : props.theme.borders.inputField};
  box-shadow: ${(props) => props.theme.boxShadow.input};
  padding: 16px 14px;
  ${placeholdertext}
`;

export const FakeLabel = styled.div`
  ${labeltext};
  padding-bottom: 6px;
`;

export const ClearButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: ${(props) => props.theme.zIndex.inputOverlap};
  -webkit-appearance: none;
  border: none;
  background-color: transparent;

  &:before,
  &:after {
    content: '';
    display: block;
    border-top: 2px solid ${(props) => props.theme.colors.paragraph};
    width: 30px;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(45deg);
    transform-origin: top left;
  }

  &:after {
    left: auto;
    right: 0;
    transform: rotate(-45deg);
    transform-origin: top right;
  }

  &:hover:before,
  &:hover:after {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export const FakeButton = styled.div<IFileLabel>`
  ${buttontext}
  ${buttonBase}
  padding: 10px 16px;
  font-size: ${(props) => props.theme.fontSizes.smallButton};
  line-height: ${(props) => props.theme.lineHeights.smallButton};
  border-radius: ${(props) => props.theme.radius.smallButton};
  min-height: ${(props) => props.theme.heights.input};

  &:before {
    border-radius: ${(props) => props.theme.radius.smallButton};
  }

  ${(props) => (props.small ? 'width: 100%;' : null)};
  min-height: ${(props) => props.theme.heights.input};
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.black};
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  ${(props) =>
    props.theme.brandColor &&
    `
      background-color: white;
      color: black;
      border: 1px solid #333;
      transition: box-shadow 0.2s ease-out 0s;

      &:before, &:hover {
        background-color: white;
        box-shadow: 5px 5px 25px ${props.theme.brandColor}50;
      }
    `}

  ${moreThan('md')} {
    margin-top: ${(props) => (props.small ? '10px' : '0')};
    margin-left: ${(props) => (props.small ? '0' : props.theme.margins.fileFieldButton)};
  }
`;
