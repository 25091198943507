import merge from 'deepmerge';
import { createApolloClient } from './createApolloClient';

let apolloClient: ReturnType<typeof createApolloClient> | undefined;

export const initializeApollo = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState?: Record<string, any>
): ReturnType<typeof createApolloClient> => {
  // eslint-disable-next-line
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      arrayMerge: (_destinationArray, sourceArray) => sourceArray,
    });
    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  // It's important to create an entirely new instance of Apollo Client for each request.
  // Otherwise, your response to a request might include sensitive cached query results from a previous request.
  // See: https://www.apollographql.com/docs/react/performance/server-side-rendering/#initializing-apollo-client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};
