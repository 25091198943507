import * as React from 'react';
import styled from 'styled-components';
import FormField from '@adecco/base-app/src/components/atoms/FormField/FormField';
import { inputtext, placeholdertext } from '@adecco/base-app/src/styles/typography';

interface IFormRow {
  label?: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
  error?: boolean;
  className?: string;
  value?: string | undefined;
  noPaddingBottom?: boolean;
  helpText?: string;
  list?: string;
}

export interface IInputWithError {
  error?: boolean;
}

const Input = styled.input<IInputWithError>`
  ${inputtext};
  width: 100%;
  border-radius: ${(props) => props.theme.radius.input};
  border: ${(props) => props.theme.borders.inputField};
  box-shadow: ${(props) => props.theme.boxShadow.materialMid};
  padding: 12px 14px;
  height: ${(props) => props.theme.heights.input};
  background-color: ${(props) => props.theme.backgrounds.input};

  ${(props) =>
    props.error &&
    `
  border: 1px solid ${props.theme.colors.inputFieldError};
  `};

  &::placeholder {
    ${placeholdertext}
  }

  &:focus {
    background-color: ${(props) => props.theme.backgrounds.inputFocus};
    outline: none;
    box-shadow: ${(props) => props.theme.boxShadow.inputFocus};
  }
`;

const TextField: React.FunctionComponent<IFormRow> = ({
  label,
  name,
  required,
  placeholder,
  register,
  error,
  className,
  value = undefined,
  noPaddingBottom,
  helpText,
  list,
}) => (
  <FormField
    label={label}
    helpText={helpText}
    name={name}
    required={required}
    redStar={error}
    noPaddingBottom={noPaddingBottom}
  >
    <Input
      type="text"
      name={name}
      id={name}
      placeholder={placeholder}
      error={error}
      className={className}
      defaultValue={value}
      {...register}
      data-cy={`input-${name}`}
      list={list}
    />
  </FormField>
);

export default TextField;
