import * as React from 'react';
import styled from 'styled-components';
import { copytext } from '@adecco/base-app/src/styles/typography';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import { IconType } from '@adecco/base-app/src/components/atoms/Icon/iconMap';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

export interface IJobFactItem {
  field: string;
  value: string;
}

const JobFactItemStyled = styled.div`
  align-items: ${(props) => props.theme.aligns.jobFactItem};
  display: flex;
  width: fit-content;
  padding: ${(props) => props.theme.paddings.jobFactItem};
  background-color: ${(props) => props.theme.backgrounds.jobFactItem};
  border-radius: ${(props) => props.theme.radius.jobFactItem};
  height: ${(props) => props.theme.heights.jobFactItem};

  ${moreThan('md')} {
    padding: ${(props) => props.theme.paddings.jobFactItemWideScreen};
  }
`;

const JobFactItemIconContainer = styled.div`
  width: ${(props) => props.theme.widths.jobFactItemIconContainer};
  height: ${(props) => props.theme.heights.jobFactItemIconContainer};
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
`;

const JobFactItemContent = styled.h4`
  ${copytext};
  color: ${(props) => props.theme.colors.paragraph};
  padding-left: 16px;
  line-height: ${(props) => props.theme.lineHeights.jobFactItem};
  font-size: ${(props) => props.theme.fontSizes.jobFactItemContent};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.jobFactItemContent};
  }

  ${moreThan('lg')} {
    padding-left: 12px;
  }
`;

const JobFactItemIcon = styled(Icon)`
  height: 100%;
`;

const JobFactItemValue = styled.span`
  font-family: ${(props) => props.theme.fonts.Copy};
  font-weight: ${(props) => props.theme.fontWeights.jobFactItem};
  word-break: break-word;
  word-wrap: break-word;
  display: ${(props) => props.theme.displays.jobFactItemValue};
`;

const iconMap: { [index: string]: IconType } = {
  workHours: 'calender',
  jobLocation: 'job-location',
};

const JobFactItem: React.FunctionComponent<IJobFactItem> = ({ field, value }) => {
  const { t, brand } = useAppContext();

  const fieldLabels = new Map(
    Object.entries({
      workHours: t('job-fact-item-field-labels-work-hours'),
      jobLocation: t('job-fact-item-field-labels-job-location'),
    })
  );

  const valuesMap = new Map(
    Object.entries({
      Festanstellung: t('job-fact-item-values-contract-permanent-position'),
      Zeitarbeit: t('job-fact-item-values-contract-temporary-1'),
      Befristet: t('job-fact-item-values-contract-temporary-2'),
      Studentenjob: t('job-fact-item-values-contract-student-job'),
      AÜ: t('job-fact-item-values-placement-temporary-1'),
      AÜO: t('job-fact-item-values-placement-temporary-2'),
      PV: t('job-fact-item-values-placement-permanent'),
      'Interne Karriere': t('job-fact-item-values-placement-internal'),
      WV: t('job-fact-item-values-placement-contract'),
    })
  );

  const mapValueTranslation = (value: string) => {
    return valuesMap.get(value) ? valuesMap.get(value) : value;
  };

  return (
    <JobFactItemStyled>
      <JobFactItemIconContainer>
        <JobFactItemIcon
          name={iconMap[field] || ''}
          fill={brand === 'Adecco' ? 'rgba(0,0,0,0.5)' : ''}
        />
      </JobFactItemIconContainer>
      <JobFactItemContent>
        {fieldLabels.get(field)}:&nbsp;
        <JobFactItemValue>{mapValueTranslation(value)}</JobFactItemValue>
      </JobFactItemContent>
    </JobFactItemStyled>
  );
};

export default JobFactItem;
