import * as React from 'react';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import PhoneInput from '@adecco/base-app/src/components/atoms/PhoneInput/PhoneInput';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { PhoneInputType } from './FormElementRenderer.types';
import { configInputFields } from './configInputFields';

const PhoneFormInput: React.FunctionComponent<PhoneInputType> = ({
  type,
  register,
  errors,
  size,
  control,
  required = false,
}) => {
  const { t } = useAppContext();

  return (
    <FieldGroup size={1 / size}>
      <PhoneInput
        control={control}
        // @ts-ignore
        register={register}
        error={!!errors[configInputFields[type].name]}
        required={required}
      />
      {errors[configInputFields[type].name] && (
        <ErrorMessage
          text={t(configInputFields[type].error)}
          elementName={configInputFields[type].name}
        />
      )}
    </FieldGroup>
  );
};

export default PhoneFormInput;
