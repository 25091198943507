import * as React from 'react';
import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface IFieldGroup {
  size: number; // Fraction on desktop
  tabletSize?: number;
  mobileSize?: number;
  children?: React.ReactNode;
}

interface FieldGroupStyledProps {
  size: number;
  tabletSize: number | undefined;
  mobileSize: number;
}

const FieldGroupStyled = styled.div<FieldGroupStyledProps>`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: ${(props) => props.theme.paddings.fieldGroup};
  width: calc(100% * ${(props) => props.mobileSize});

  ${moreThan('md')} {
    padding: ${(props) => props.theme.paddings.fieldGroupWideScreen};
    width: ${(props) => props.tabletSize && `calc(100% * ${props.tabletSize})`};
  }

  ${moreThan('lg')} {
    width: calc(100% * ${(props) => props.size});
  }

  & &:first-child {
    padding-left: 0;
  }

  & &:last-child {
    padding-right: 0;
  }
`;

const FieldGroup: React.FunctionComponent<IFieldGroup> = ({
  size,
  tabletSize,
  mobileSize = 1,
  children,
}) => (
  <FieldGroupStyled size={size} tabletSize={tabletSize} mobileSize={mobileSize}>
    {children}
  </FieldGroupStyled>
);

export default FieldGroup;
