import { configurations } from './getConfiguration.types';

const getConfiguration = (
  configuration:
    | readonly (
        | {
            readonly key: string;
            readonly value: string;
          }
        | undefined
      )[]
    | undefined,
  key: configurations
): string =>
  configuration?.find((frontendConfiguration) => frontendConfiguration?.key === key)?.value ?? '';

export default getConfiguration;
