export function getValuesFromTranslation(translation: string) {
  return translation
    .split('/')
    .map((t) => {
      const valueAsArray = t.split('|');

      const name = valueAsArray[0]?.trim();
      const value = valueAsArray[1]?.trim();

      return {
        name,
        value,
      };
    })
    .filter((el) => el.name && el.value);
}
