import { useRouter } from 'next/router';

export const QueryKeys = {
  job_city: 'job_city',
  desiredPosition: 'desiredPosition',
  cdx_source: 'cdx_source',
  cdx_medium: 'cdx_medium',
  cdx_content: 'cdx_content',
  cdx_campaign: 'cdx_campaign',
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_content: 'utm_content',
  utm_campaign: 'utm_campaign',
} as const;

export function useRouterQueries(queries: (typeof QueryKeys)[keyof typeof QueryKeys][]) {
  const { query } = useRouter();

  const finalQueries = {
    job_city: '',
    desiredPosition: '',
    cdx_source: '',
    cdx_medium: '',
    cdx_content: '',
    cdx_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    utm_campaign: '',
  };

  queries.forEach((q) => {
    const routerQuery = query[q];
    if (!routerQuery) return;

    finalQueries[q] = Array.isArray(routerQuery) ? routerQuery[0] : routerQuery;
  });

  return finalQueries;
}

export function useGetQueryString() {
  const queries = useRouterQueries([
    'cdx_source',
    'cdx_medium',
    'cdx_content',
    'cdx_campaign',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
  ]);

  let qs = '';

  Object.entries(queries).forEach(([key, value]) => {
    if (!value) return;
    qs = qs ? `${qs}&${key}=${value}` : `?${key}=${value}`;
  });

  return qs;
}
