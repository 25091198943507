import { ApolloProvider } from '@apollo/client';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';
import { ErrorBoundary } from 'react-error-boundary';
import GlobalStyle from '@adecco/base-app/src/styles/globals';
import { useApollo } from '@adecco/base-app/src/apollo/useApollo';
import defaultTheme from '@adecco/base-app/src/constants/themes/defaultTheme/defaultTheme';
import PageHead from '@adecco/base-app/src/components/organisms/PageHead/PageHead';
import Footer from '@adecco/base-app/src/components/molecules/Footer/Footer';
import { TranslationType } from '@adecco/base-app/src/gql/schema/generated';
import getConfigurations from '@adecco/base-app/src/utilities/getConfigurations/getConfigurations';
import { AppProvider } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { transformLanguage } from '@adecco/base-app/src/utilities/languages/languages';
import Script from 'next/script';
import { getSocialLinks } from '@adecco/base-app/src/utilities/getSocialLinks/getSocialLinks';
import Custom500 from '@adecco/base-app/src/components/templates/500/500';
import { PageType as JobApplyPageType } from './job/apply/apply';
import { PageType as BrandedPageType } from './lpc';
import { PageType as ThankyouBrandedPageType } from './lpc/application-sent';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any;
    OneTrust: { OnConsentChanged: (p: object) => {} };
    OnetrustActiveGroups: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OptanonWrapper: any;
  }
}

interface ILanguages {
  [key: string]: {
    [key: string]: string;
  };
}

interface IFrontendTranslation {
  language: string;
  translations: TranslationType[];
}

const MyApp = ({ Component, pageProps }: AppProps) => {
  const languages: ILanguages = {};
  pageProps?.data?.frontendTranslations?.forEach((frontendTranslation: IFrontendTranslation) => {
    languages[frontendTranslation.language] = transformLanguage(frontendTranslation.translations);
  });
  const apolloClient = useApollo(pageProps);
  const configurations = getConfigurations(pageProps?.data?.frontendConfigurations);
  const isBrandedPageType =
    pageProps.pageType === BrandedPageType || pageProps.pageType === ThankyouBrandedPageType;

  const theme = { ...defaultTheme };

  if (pageProps?.data?.landingpageBranded?.color) {
    theme.brandColor = pageProps.data.landingpageBranded.color;
  }

  const socialLinks = getSocialLinks(configurations);
  const trackingKeyService = configurations.trackingKeyService || 'collect.adecco.de';

  return (
    <>
      {configurations.cookieBanner === 'true' ? (
        <>
          <Script
            id="cookiebanner"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-document-language="true"
            type="text/javascript"
            data-domain-script={
              configurations.cookieBannerKey || '03c69971-24c2-4c5b-b4cf-152dbc0c1bb7'
            }
            strategy="beforeInteractive"
          />
          <Script
            id="cookiebanner-push"
            type="text/javascript"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: 'function OptanonWrapper() { }',
            }}
          />
        </>
      ) : null}
      <Script
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://${trackingKeyService}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${configurations.trackingKey}');
          `,
        }}
      />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="preload"
          href="/fonts/houschka-demibold.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/nunito.regular.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Head>
      <GlobalStyle />
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <AppProvider
            withDoi={configurations.withDoi === 'true'}
            withImprint={configurations.withImprint === 'true'}
            withAgeConfirm={configurations.withAgeConfirm === 'true'}
            languages={languages}
            brand="Adecco"
            privacyConsentVersion={configurations.privacyConsentVersion}
            socialLinks={socialLinks}
            withPrivacyPolicyModal={configurations.withPrivacyPolicyModal === 'true'}
            withEmailConfirmation={configurations.withEmailConfirmation === 'true'}
            withTracking={configurations.withTracking === 'true'}
            zipRegExp={configurations.zipRegExp}
            googleMapsApiKey={configurations.googleMapsApiKey}
            varSource={configurations.varSource}
            logoLink={configurations.logoLink}
            withNoAts={configurations.withNoAts === 'true'}
            withJsonSchema={configurations.withJsonSchema === 'true'}
            mobileCVException={configurations.mobileCVException !== 'false'}
          >
            <ModalProvider>
              {!isBrandedPageType && (
                <PageHead
                  key={pageProps.pageType === JobApplyPageType ? 'compact' : 'normal'}
                  compact={
                    configurations.jobSearch !== 'true'
                      ? true
                      : pageProps.pageType === JobApplyPageType
                  }
                />
              )}
              <main className={isBrandedPageType ? 'branded' : undefined}>
                <ErrorBoundary FallbackComponent={Custom500}>
                  <Component {...pageProps} />
                </ErrorBoundary>
              </main>
              <Footer className={isBrandedPageType ? 'branded' : undefined} />
            </ModalProvider>
          </AppProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
};

export default MyApp;
