import { useMemo } from 'react';
import { APOLLO_STATE_PROP_NAME, createApolloClient } from './createApolloClient';
import { initializeApollo } from './initializeApollo';

export const useApollo = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: Record<string, any>
): ReturnType<typeof createApolloClient> => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const client = useMemo(() => initializeApollo(state), [state]);

  return client;
};
