import * as React from 'react';
import styled from 'styled-components';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { lessThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface IFormRow {
  children: React.ReactNode;
  className?: string;
}

const FormRowStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: ${(props) => props.theme.margins.formRow};

  ${lessThan('md')} {
    padding: 0 -${calcrem(15)};
  }
`;

const FormRow: React.FunctionComponent<IFormRow> = ({ children, className }) => (
  <FormRowStyled className={className}>{children}</FormRowStyled>
);

export default FormRow;
