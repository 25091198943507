import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

type HighlightSpeechBubbleType = {
  text: string;
  className?: string;
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 2;
  padding: 12px 24px;
  background-color: ${(props) => props.theme.backgrounds.speechBubbleWrapper};
  box-shadow: ${(props) => props.theme.boxShadow.speechBubbleWrapper};
  border-radius: ${(props) => props.theme.radius.speechBubbleWrapper};
  max-width: 300px;

  ${moreThan('md')} {
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: ${(props) => props.theme.positions.speechBubbleAfterLeft};
      bottom: ${(props) => props.theme.positions.speechBubbleAfterBottom};
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: ${(props) => props.theme.borders.speechBubbleAfter};
      border-color: ${(props) => props.theme.backgrounds.speechBubbleWrapper} transparent
        transparent transparent;
    }
  }
`;

const Text = styled.p`
  text-shadow: ${(props) => props.theme.textShadows.speechBubble};
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.Copy};
  font-weight: ${(props) => props.theme.fontWeights.speechBubble};
  font-size: ${(props) => props.theme.fontSizes.speechBubble};
  line-height: ${(props) => props.theme.lineHeights.speechBubble};
`;

const HighlightSpeechBubble: React.FunctionComponent<HighlightSpeechBubbleType> = ({
  text,
  className,
}) => (
  <Wrapper className={className}>
    <Text>{text}</Text>
  </Wrapper>
);

export default HighlightSpeechBubble;
