export default function getBenefitStringForMetaDescription(benefits: string[]) {
  let result = '';

  if (benefits.length) {
    result += ' →';
    benefits.forEach((benefit) => {
      result += ` ${benefit} ✓`;
    });
  }

  return result;
}
