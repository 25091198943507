import * as React from 'react';
import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface IFormSection {
  children: React.ReactNode;
  className?: string;
}

const FormSectionStyled = styled.div`
  border-top: ${(props) => props.theme.borders.regular};
  margin: ${(props) => props.theme.margins.formSection};
  padding: ${(props) => props.theme.paddings.formSection};

  ${moreThan('md')} {
    margin: ${(props) => props.theme.margins.formSectionWideScreen};
    padding: ${(props) => props.theme.paddings.formSectionWideScreen};
  }
`;

const FormSection: React.FunctionComponent<IFormSection> = ({ children, className }) => (
  <FormSectionStyled className={className}>{children}</FormSectionStyled>
);

export default FormSection;
