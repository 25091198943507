export type IQueryParam = 'varSource' | 'varCampaign' | 'varContent' | 'varMedium';

export const getQueryParam = (name: IQueryParam) => {
  if (typeof window === 'undefined') return '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { dataLayer }: { dataLayer: any } = window;

  return dataLayer?.find((key: { user_type: string }) => key.user_type === 'prospect')?.[name];
};
