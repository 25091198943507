import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

export const getZipCodeHelpText = (currentCountry: string | undefined, zipCodeError: boolean) => {
  const { t, hasTranslation } = useAppContext();
  const zipCodeHelpTextKey = 'form-field-zip-code-help-text';

  if (
    currentCountry?.toLowerCase() === 'de' &&
    zipCodeError &&
    hasTranslation(zipCodeHelpTextKey)
  ) {
    return t(zipCodeHelpTextKey);
  }
  return undefined;
};
