import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import { formhead } from '@adecco/base-app/src/styles/typography';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { useState } from 'react';
import * as React from 'react';

interface Languages {
  value: string;
  label: string;
}

interface Props {
  languages: Array<Languages>;
  currentLang: string;
}

const Label = styled.label`
  ${formhead};
  margin-right: ${calcrem(10)};
`;

const Select = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  font-size: inherit;
  cursor: pointer;

  ${moreThan('md')} {
    margin-top: ${(props) => props.theme.margins.selectWideScreen};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconStyled = styled(Icon)`
  margin-right: ${(props) => props.theme.margins.iconRight};

  ${moreThan('md')} {
    margin: ${(props) => props.theme.margins.iconWideScreen};
  }
`;

const iconLanguage = {
  de: 'flag-de',
  en: 'flag-en',
  pl: 'flag-pl',
};

const SelectLanguage = ({ languages, currentLang }: Props) => {
  const { t, switchLanguage } = useAppContext();

  const [language, setLanguage] = useState(currentLang);

  const handleChangeSelectLanguage: React.ChangeEventHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    switchLanguage(event.target.value);
    setLanguage(event.target.value);
  };

  return (
    <Wrapper>
      <Label htmlFor="language-select">{t('general-language')}:</Label>
      <IconStyled
        // @ts-ignore
        name={iconLanguage[language] ?? 'flag-en'}
        height={18}
        width={18}
        aria-hidden="true"
      />
      <Select
        name="language"
        id="language-select"
        defaultValue={language}
        onChange={handleChangeSelectLanguage}
        data-cy='select-language'
      >
        {languages.map((option: Languages) => (
          <option key={option.value} value={option.value} data-cy='option-language'>
            {option.label}
          </option>
        ))}
      </Select>
    </Wrapper>
  );
};
export default SelectLanguage;
