import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import FormField from '@adecco/base-app/src/components/atoms/FormField/FormField';
import FormRow from '@adecco/base-app/src/components/atoms/FormRow/FormRow';

interface IPlacesAutocomplete {
  apiKey: string;
  setValue: UseFormSetValue<FieldValues>;
}
interface LatLng {
  lat: number;
  lng: number;
}
interface AutocompletionRequest {
  bounds?: [LatLng, LatLng];
  componentRestrictions?: { country: string | string[] };
  location?: LatLng;
  offset?: number;
  radius?: number;
  types?: string[];
}
interface IAddress {
  street?: string;
  city?: string;
  number?: string;
  zipcode?: string;
}
interface IStyles {
  [key: string]: string | { [key: string]: string };
}
interface IAddressProps {
  value: {
    place_id: string;
  };
}
interface IPlaceResult {
  address_components: {
    long_name: string;
    types: string[];
  }[];
}

const PlacesAutocomplete: React.FunctionComponent<IPlacesAutocomplete> = ({ apiKey, setValue }) => {
  const { t } = useAppContext();

  const onAddressChange = async (props: IAddressProps) => {
    const placeId = props.value?.place_id;

    if (placeId) {
      geocodeByPlaceId(placeId).then((results: IPlaceResult[]) => {
        if (results) {
          const components = results[0].address_components;

          const newAddress: IAddress = {
            street: '',
            number: '',
            zipcode: '',
            city: '',
          };

          components.forEach((component) => {
            if (component.types.some((type) => type === 'route')) {
              newAddress.street = component.long_name;
            } else if (component.types.some((type) => type === 'street_number')) {
              newAddress.number = component.long_name;
            } else if (component.types.some((type) => type === 'postal_code')) {
              newAddress.zipcode = component.long_name;
            } else if (component.types.some((type) => type === 'locality')) {
              newAddress.city = component.long_name;
            }
          });

          setValue('street', newAddress.street);
          setValue('number', newAddress.number);
          setValue('zipcode', newAddress.zipcode);
          setValue('city', newAddress.city);
        }
      });
    }
  };

  const autocompletionRequest: AutocompletionRequest = {
    componentRestrictions: {
      country: ['de', 'es'],
    },
  };

  const autocompleteStyles = {
    container: (provided: IStyles) => ({
      ...provided,
    }),
    control: (provided: IStyles) => {
      return {
        ...provided,
        boxShadow: 'inset 2px 2px 3px 0 rgba(0, 0, 0, 0.1)',
        border: '1px solid #CFCFCF',
        padding: '4px 8px',
        '&:hover': {
          border: '1px solid #CFCFCF',
        },
      };
    },
    input: (provided: IStyles) => ({
      ...provided,
    }),
    option: (provided: IStyles) => ({
      ...provided,
    }),
    singleValue: (provided: IStyles) => ({
      ...provided,
    }),
  };

  return (
    <FormRow>
      <FieldGroup size={1}>
        <FormField name="" label={t('type-your-address')}>
          <GooglePlacesAutocomplete
            apiKey={apiKey}
            selectProps={{
              onChange: onAddressChange,
              placeholder: '',
              styles: autocompleteStyles,
            }}
            minLengthAutocomplete={3}
            debounce={300}
            autocompletionRequest={autocompletionRequest}
          />
        </FormField>
      </FieldGroup>
    </FormRow>
  );
};

export default PlacesAutocomplete;
