import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import Paragraph from '@adecco/base-app/src/components/atoms/Paragraph/Paragraph';
import Button from '@adecco/base-app/src/components/atoms/Button/Button';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

const StyledHeadline = styled(Headline)`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.margins.templateHeadline};
`;

const StyledIcon = styled(Icon)`
  margin-right: ${(props) => props.theme.margins.templateIconRight};
`;

const ButtonStyled = styled(Button)`
  width: fit-content;
`;

const Custom500: FunctionComponent = () => {
  const { t } = useAppContext();

  return (
    <Container>
      <StyledHeadline>
        <StyledIcon name="500" width={70} height={70} />
        {t('error-server-error')}
      </StyledHeadline>
      <Paragraph noTransform>{t('error-500-text')}</Paragraph>
      <ButtonStyled variant="small" href={t('header-job-search-url')}>
        {t('general-to-job-search')}
      </ButtonStyled>
    </Container>
  );
};

export default Custom500;
