import * as React from 'react';
import styled from 'styled-components';
import { formhead } from '@adecco/base-app/src/styles/typography';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import SelectLanguage from '@adecco/base-app/src/components/atoms/SelectLanguage/SelectLanguage';
import { useRouter } from 'next/router';
import getConfig from 'next/config';

interface IFormHeader {
  headline: string;
}

interface IHeaderSection {
  line?: boolean;
}

const HeaderSection = styled.div<IHeaderSection>`
  margin: ${(props) => props.theme.margins.header};
  padding: ${(props) => props.theme.paddings.header};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: ${(props) => props.theme.borders.regular};
  }

  ${moreThan('md')} {
    margin: ${(props) => props.theme.margins.headerWideScreen};
    flex-direction: row;
    padding: ${(props) => props.theme.paddings.headerWideScreen};
    &:after {
      display: none;
    }
  }
`;

const FormHeadline = styled.h2`
  ${formhead};
  margin-bottom: 40px;
  ${moreThan('md')} {
    margin-bottom: 0;
  }
`;

const FormLanguage = styled.div`
  display: ${(props) => props.theme.displays.formLanguage};
`;

const languages = [
  { value: 'de', label: 'Deutsch' },
  { value: 'en', label: 'English' },
  { value: 'pl', label: 'Polska' },
];

const FormHeader: React.FunctionComponent<IFormHeader> = ({ headline }) => {
  const { locale } = useRouter();
  const { publicRuntimeConfig } = getConfig();
  const filtertLanguages = publicRuntimeConfig.LANGUAGES.map((key: string) =>
    languages.find((language) => language.value === key)
  );
  const currentLang = locale?.split('-')[0] ?? 'en';

  return (
    <HeaderSection>
      <FormHeadline>{headline}</FormHeadline>
      <FormLanguage>
        <SelectLanguage languages={filtertLanguages} currentLang={currentLang} />
      </FormLanguage>
    </HeaderSection>
  );
};

export default FormHeader;
