import * as React from 'react';
import ElementMap from '@adecco/base-app/src/utilities/ElementMap/ElementMap';
import { DynamicLandingpageElementsEdge, Maybe } from '@adecco/base-app/src/gql/schema/generated';

type ILandingPageComponentRenderer = {
  edges: readonly Maybe<DynamicLandingpageElementsEdge>[] | undefined;
  slug?: string;
  centeredHeadlines?: boolean;
};

const LandingPageComponentRenderer: React.FunctionComponent<ILandingPageComponentRenderer> = ({
  edges,
  slug = '',
  centeredHeadlines,
}) => {
  if (edges && edges.length === 0) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const sortElements = [...edges!].sort((a, b) => {
    return a!.node!.order! - b!.node!.order!;
  })!;

  return (
    <>
      {sortElements!.map((element, index) => (
        <ElementMap
          // eslint-disable-next-line no-underscore-dangle
          key={`${slug}-${element!.node!.__typename}-${index}`}
          {...element!}
          centeredHeadline={centeredHeadlines}
        />
      ))}
    </>
  );
};
export default LandingPageComponentRenderer;
