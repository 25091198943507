import * as React from 'react';
import styled from 'styled-components';
import { panel } from '@adecco/base-app/src/styles/panels';

interface IForm {
  children: React.ReactNode;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  className?: string;
  formName: string;
}

const FormStyled = styled.form`
  ${panel};
  margin-bottom: ${(props) => props.theme.margins.form};
`;

const Form: React.FunctionComponent<IForm> = ({ children, onSubmit, className, formName }) => {
  const transformedName = formName?.replaceAll(' ', '');

  return (
    <FormStyled onSubmit={onSubmit || (() => undefined)} className={className} id={transformedName}>
      {children}
    </FormStyled>
  );
};

export default Form;
