/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string, date?: string) {
  const value = val;

  if (date) {
    document.cookie = `${name}=${value}; expires=${date}; path=/`;
  } else {
    document.cookie = `${name}=${value}; path=/`;
  }
}

export function getCookie(name: string, defaultValue?: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    const part = parts.pop();
    if (part) {
      return part.split(';').shift();
    }
  }

  return defaultValue;
}

export function deleteCookie(name: string) {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

  // Set it
  document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
}
