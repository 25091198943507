import getConfiguration from '@adecco/base-app/src/utilities/getConfiguration/getConfiguration';
import { configurationsObject } from './getConfigurations.types';

const getConfigurations = (
  configuration:
    | readonly (
        | {
            readonly key: string;
            readonly value: string;
          }
        | undefined
      )[]
    | undefined
): configurationsObject => ({
  cookieBanner: getConfiguration(configuration, 'cookie-banner'),
  cookieBannerKey: getConfiguration(configuration, 'cookie-banner-key'),
  jobSearch: getConfiguration(configuration, 'job-search'),
  trackingKey: getConfiguration(configuration, 'tracking-key'),
  withDoi: getConfiguration(configuration, 'withDoi'),
  withImprint: getConfiguration(configuration, 'withImprint'),
  withAgeConfirm: getConfiguration(configuration, 'withAgeConfirm'),
  privacyConsentVersion: getConfiguration(configuration, 'privacyConsentVersion'),
  kununuLink: getConfiguration(configuration, 'kununuLink'),
  xingLink: getConfiguration(configuration, 'xingLink'),
  googleMapsLink: getConfiguration(configuration, 'googleMapsLink'),
  facebookLink: getConfiguration(configuration, 'facebookLink'),
  linkedinLink: getConfiguration(configuration, 'linkedinLink'),
  withPrivacyPolicyModal: getConfiguration(configuration, 'withPrivacyPolicyModal'),
  logoLink: getConfiguration(configuration, 'logoLink'),
  withEmailConfirmation: getConfiguration(configuration, 'withEmailConfirmation'),
  withTracking: getConfiguration(configuration, 'withTracking'),
  zipRegExp: getConfiguration(configuration, 'zipRegExp'),
  googleMapsApiKey: getConfiguration(configuration, 'googleMapsApiKey'),
  varSource: getConfiguration(configuration, 'varSource'),
  trackingKeyService: getConfiguration(configuration, 'tracking-key-service'),
  withNoAts: getConfiguration(configuration, 'withNoAts'),
  withJsonSchema: getConfiguration(configuration, 'withJsonSchema'),
  mobileCVException: getConfiguration(configuration, 'mobileCVException'),
});
export default getConfigurations;
