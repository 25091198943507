import * as React from 'react';
import styled from 'styled-components';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import ApplicationForm from '@adecco/base-app/src/components/organisms/ApplicationForm/ApplicationForm';
import JobHeader from '@adecco/base-app/src/components/organisms/JobHeader/JobHeader';
import ProgressBar from '@adecco/base-app/src/components/molecules/ProgressBar/ProgressBar';
import JobFacts from '@adecco/base-app/src/components/organisms/JobFacts/JobFacts';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { JobAdType } from '@adecco/base-app/src/gql/schema/generated';

export type IJobApplication = {
  jobData: JobAdType;
  id?: string;
  setFormIsDirty?: (value: boolean) => void;
};

const ApplicationJobFacts = styled(JobFacts)`
  display: none;

  ${moreThan('md')} {
    display: block;
  }

  ul[class^='JobFactList'] {
    ${moreThan('md')} {
      grid-template-columns: repeat(3, 1fr);
    }

    ${moreThan('lg')} {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

const JobApplication: React.FunctionComponent<IJobApplication> = ({
  jobData,
  id,
  setFormIsDirty,
}) => {
  const { t } = useAppContext();

  return (
    <Container>
      <ProgressBar
        items={[
          {
            icon: 'heart-line',
            label: t('general-progressbar-search'),
            state: 'done',
          },
          {
            icon: 'edit',
            label: t('general-progressbar-fill-form'),
            state: 'current',
          },
          {
            icon: 'checkmark',
            label: t('general-progressbar-send-application'),
            state: 'future',
          },
        ]}
      />
      <JobHeader
        onlineDate={jobData.onlineDate}
        title={jobData.title}
        clientLogoImage={jobData.clientLogoImage}
        topline
      />
      <ApplicationJobFacts
        workHours={jobData.workHours}
        jobLocations={[...jobData.jobadlocationSet]}
      />
      <ApplicationForm
        branchId={jobData.branchId}
        cvMandatory={jobData.cvMandatory}
        id={id}
        jobLocation={jobData.jobLocation}
        kldbNumber={jobData.kldbNumber}
        occupation={jobData.occupation}
        officeId={jobData.officeId}
        schaltungsId={jobData.schaltungsId}
        setFormIsDirty={setFormIsDirty}
        sourceAdId={jobData.sourceAdId}
        sourceJobId={jobData.sourceJobId}
        whatsappAvailable={jobData.whatsappAvailable}
      />
    </Container>
  );
};

export default JobApplication;
