import * as React from 'react';
import SelectField from '@adecco/base-app/src/components/atoms/SelectField/SelectField';
import { getValuesFromTranslation } from '@adecco/base-app/src/utilities/transformText/getValuesFromTranslation';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import FormRow from '@adecco/base-app/src/components/atoms/FormRow/FormRow';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import { FieldValues, UseFormRegister } from 'react-hook-form';

const numberMonthsConsentValuesKey = 'number-months-consent-values';

const MonthsConsentDropdown: React.FunctionComponent<{
  size: number;
  register: UseFormRegister<FieldValues>;
}> = ({ size, register }) => {
  const { t, hasTranslation } = useAppContext();

  const numberMonthsConsentValues = getValuesFromTranslation(t(numberMonthsConsentValuesKey));

  if (!numberMonthsConsentValues || !hasTranslation(numberMonthsConsentValuesKey)) return null;

  return (
    <FormRow>
      <FieldGroup size={size}>
        <SelectField
          name="numberMonthsConsent"
          register={register('numberMonthsConsent')}
          label={t('number-months-consent-label')}
          values={numberMonthsConsentValues}
          selected="0"
        />
      </FieldGroup>
    </FormRow>
  );
};

export default MonthsConsentDropdown;
