import * as React from 'react';
import styled from 'styled-components';
import { headline1, headline2, headline3, headline4 } from '@adecco/base-app/src/styles/typography';

interface HeadlineProps {
  skin: number;
  centered?: boolean;
}

export const HeadlineStyled = styled.h1<HeadlineProps>`
  ${(props) => (props.skin === 1 ? headline1 : null)};
  ${(props) => (props.skin === 2 ? headline2 : null)};
  ${(props) => (props.skin === 3 ? headline3 : null)};
  ${(props) => (props.skin === 4 ? headline4 : null)};
  ${(props) => (props.centered ? 'text-align: center' : null)};
`;

interface IHeadline {
  /** Content of headline */
  children: React.ReactNode;
  /** Skin of headline */
  skin?: number;
  /** HTML-Skin of headline */
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | null;
  /** Optional class name */
  className?: string;
  centered?: boolean;
}

const Headline: React.FunctionComponent<IHeadline> = ({
  children,
  skin,
  tag,
  className,
  centered = false,
}) => {
  const useSkin = skin || 1;
  let useAs = tag || 'h1';

  // Strange workaround to as-typecheck
  if (!tag) {
    if (skin === 2) {
      useAs = 'h2';
    }
    if (skin === 3) {
      useAs = 'h3';
    }
    if (skin === 4) {
      useAs = 'h4';
    }
    if (skin === 5) {
      useAs = 'h5';
    }
  }

  return (
    <HeadlineStyled className={className} skin={useSkin} as={useAs} centered={centered}>
      {children}
    </HeadlineStyled>
  );
};

Headline.defaultProps = {
  tag: null,
  className: '',
  skin: 1,
};

export default Headline;
