import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { FormElementTypes } from '@adecco/base-app/src/components/molecules/FormElementRenderer/FormElementRenderer.types';

type IUsePatterns = {
  [key in FormElementTypes]?: RegExp | undefined;
};

export const usePatterns = (): IUsePatterns => {
  const { zipRegExp } = useAppContext();

  return {
    'zip-code': zipRegExp ? new RegExp(zipRegExp) : undefined,
  };
};
