import { configurationsObject } from '@adecco/base-app/src/utilities/getConfigurations/getConfigurations.types';

export const getSocialLinks = (configurations: configurationsObject) => {
  return {
    Kununu: configurations.kununuLink,
    Xing: configurations.xingLink,
    GoogleMaps: configurations.googleMapsLink,
    Facebook: configurations.facebookLink,
    LinkedIn: configurations.linkedinLink,
  };
};
