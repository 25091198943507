import * as React from 'react';
import styled from 'styled-components';
import Head from 'next/head';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import ProgressBar, {
  IProgressBarItem,
} from '@adecco/base-app/src/components/molecules/ProgressBar/ProgressBar';
import { panel } from '@adecco/base-app/src/styles/panels';
import LogoButtonRow from '@adecco/base-app/src/components/molecules/LogoButtonRow/LogoButtonRow';
import { IThankYouTeaser } from '@adecco/base-app/src/components/atoms/ThankYouTeaser/ThankYouTeaser';
import ThankYouTeaserList from '@adecco/base-app/src/components/molecules/ThankYouTeaserList/ThankYouTeaserList';
import RecoTeaser from '@adecco/base-app/src/components/molecules/RecoTeaser/RecoTeaser';
import StatusBar from '@adecco/base-app/src/components/atoms/StatusBar/StatusBar';
import { CloudinaryImageType } from '@adecco/base-app/src/gql/schema/generated';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import PlannedSteps from '@adecco/base-app/src/components/molecules/PlannedSteps/PlannedSteps';
import SpacerWithGradient from '@adecco/base-app/src/components/molecules/SpacerWithGradient/SpacerWithGradient';
import {
  BrandedWrapper,
  BrandedPageImgContainer,
  BrandedLandingPageImg,
} from '@adecco/base-app/src/components/templates/BrandedLandingPageApp/BrandedLandingPageApp';
import BrandedBrandBar from '@adecco/base-app/src/components/molecules/BrandedBrandBar/BrandedBrandBar';
import {
  BarWrapper,
  BrandedLandingPageHeadline,
  BrandedLandingPageSubline,
} from '@adecco/base-app/src/components/organisms/BrandedLandingPageHeader/BrandedLandingPageHeader';
import { useLogoButtonRowItems } from '@adecco/base-app/src/hooks/useLogoButtonRow';

export interface IBrandedThankYou {
  recoTeasers: Array<{
    title: string;
    subline: string;
    description: string;
    image: string;
    link: string;
    linkText: string;
    cloudinaryImage?: CloudinaryImageType;
  }>;
  thankyouTeasers: Array<IThankYouTeaser>;
  withStatusBar?: boolean;
  withProgressBar?: boolean;
  twoSteps?: boolean;
  image?: string;
  cloudinaryImage?: CloudinaryImageType;
  brandedlandingpagelogoSet: Array<{
    brandedLogo?: string;
    cloudinaryImage?: CloudinaryImageType;
  }>;
}

const PageContainer = styled.div`
  background-color: white;
  margin: 70px auto;
  max-width: calc(100% - 40px);

  ${moreThan('md')} {
    width: fit-content;
  }
`;

const LogosHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  min-height: 460px;
`;

const ThankYouDePanelContainer = styled(Container)`
  margin-top: 40px;
`;

const ThankYouDePanel = styled.div`
  ${panel};
  margin-inline: auto;
  border: none;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.theme.paddings.thankYou};
  max-width: 800px;
`;

const BrandedThankYouDeSubline = styled(BrandedLandingPageSubline)`
  font-size: ${(props) => props.theme.fontSizes.brandedThankYouDeSubline};
  line-height: ${(props) => props.theme.lineHeights.brandedThankYouDeSubline};
  font-weight: 600;
  margin-top: 40px;
  color: ${({ theme }) => theme.colors.subline};
`;

const BrandedThankYouDe: React.FunctionComponent<IBrandedThankYou> = ({
  recoTeasers,
  thankyouTeasers,
  withStatusBar = false,
  withProgressBar = false,
  twoSteps = false,
  image,
  cloudinaryImage,
  brandedlandingpagelogoSet,
}) => {
  const { t, brand } = useAppContext();
  const logoButtonRowItems = useLogoButtonRowItems();

  const progressItems: Array<IProgressBarItem> = [
    {
      icon: 'heart-line',
      label: t('general-progressbar-search'),
      state: 'done',
    },
    {
      icon: 'edit',
      label: t('general-progressbar-fill-form'),
      state: 'done',
    },
    {
      icon: 'checkmark',
      label: t('general-progressbar-application-sended'),
      state: 'finished',
    },
  ];

  const twoStepsProgressItems: Array<IProgressBarItem> = [
    {
      icon: 'edit',
      label: t('general-progressbar-fill-form'),
      state: 'done',
    },
    {
      icon: 'checkmark',
      label: t('general-progressbar-application-sended'),
      state: 'finished',
    },
  ];

  return (
    <BrandedWrapper>
      <Head>
        <title>{t('thank-you-title')}</title>
      </Head>
      <BrandedPageImgContainer>
        <BrandedLandingPageImg image={image} cloudinaryImage={cloudinaryImage} />
      </BrandedPageImgContainer>
      <PageContainer>
        <LogosHeaderContainer>
          <BarWrapper>
            <BrandedBrandBar brandedLogos={[...brandedlandingpagelogoSet]} />
          </BarWrapper>
          <ThankYouDePanelContainer>
            {withProgressBar && (
              <ProgressBar items={twoSteps ? twoStepsProgressItems : progressItems} />
            )}
            <ThankYouDePanel>
              <BrandedLandingPageHeadline>
                {t('thank-you-panel-headline')}
              </BrandedLandingPageHeadline>
              <BrandedThankYouDeSubline>{t('thank-you-panel-message')}</BrandedThankYouDeSubline>
              {withStatusBar && (
                <StatusBar
                  headline={t('general-status-bar-complete')}
                  status={100}
                  withoutMarginBottom
                />
              )}
            </ThankYouDePanel>
          </ThankYouDePanelContainer>
        </LogosHeaderContainer>

        {brand === 'ProServ' ? <SpacerWithGradient /> : null}
        {recoTeasers.map((teaser, index) => (
          <RecoTeaser
            key={`recoteaser-${index}`}
            headline={teaser.title}
            subheadline={teaser.subline}
            centeredHeadline
            text={teaser.description}
            img={{
              src: teaser.image,
              alt: teaser.title,
            }}
            link={{
              href: teaser.link,
              description: teaser.linkText,
            }}
            cloudinaryImage={teaser.cloudinaryImage}
          />
        ))}
        <PlannedSteps
          centeredHeadline
          items={[
            {
              iconName: 'thx_email_confirmation',
              headline: t('thank-you-panel-step-headline-email'),
              text: t(`thank-you-panel-step-text-account`),
            },
            {
              iconName: 'thx_profile',
              headline: t('thank-you-panel-step-headline-profile'),
              text: t(`thank-you-panel-step-text-profile`),
            },
            {
              iconName: 'thx_contact',
              headline: t('thank-you-panel-step-headline-contact'),
              text: t('thank-you-panel-step-text-status'),
            },
          ]}
        />
        {logoButtonRowItems.length > 0 && (
          <LogoButtonRow centeredHeadline items={logoButtonRowItems} />
        )}
        <ThankYouTeaserList teasers={thankyouTeasers} />
      </PageContainer>
    </BrandedWrapper>
  );
};

export default BrandedThankYouDe;
