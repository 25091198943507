import * as React from 'react';
import BrandedLandingPageHeader from '@adecco/base-app/src/components/organisms/BrandedLandingPageHeader/BrandedLandingPageHeader';
import { LandingpageBrandedType } from '@adecco/base-app/src/gql/schema/generated';
import LandingPageComponentRenderer from '@adecco/base-app/src/components/organisms/LandingPageComponentRenderer/LandingPageComponentRenderer';
import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { LandingPageImg } from '@adecco/base-app/src/components/organisms/LandingPageHeader/LandingPageHeader';

type ILandingPageBranded = LandingpageBrandedType & {
  setFormIsDirty: (value: boolean) => void;
};

export const BrandedWrapper = styled.div`
  position: relative;

  h2 {
    font-weight: 700;
  }
`;

export const BrandedPageImgContainer = styled.div`
  position: absolute;
  top: -70px;
  bottom: 0;
  max-height: 650px;
  min-width: 100%;
  z-index: -1;

  ${moreThan('md')} {
    max-height: 460px;
  }
`;

export const BrandedLandingPageImg = styled(LandingPageImg)`
  height: 100%;
  object-fit: cover;
`;

const BrandedLandingPageApp: React.FunctionComponent<ILandingPageBranded> = ({
  brand,
  bubbleText,
  cloudinaryImage,
  color,
  description,
  elements,
  form,
  image,
  kldb,
  locationLinksTitle,
  sid,
  slug,
  subline,
  title,
  brandedlandingpagelogoSet = [],
  brandedlandingpagelocationsSet = [],
  brandedlandingpagelocationsapplicationSet,
  buzzwords,
  setFormIsDirty,
  monaAiId,
  monaAiPostingId,
}) => {
  return (
    <BrandedWrapper>
      <BrandedPageImgContainer>
        <BrandedLandingPageImg image={image} cloudinaryImage={cloudinaryImage} />
      </BrandedPageImgContainer>
      <BrandedLandingPageHeader
        sid={sid}
        title={title}
        subline={subline}
        description={description}
        color={color}
        bubbleText={bubbleText}
        brandedlandingpagelogoSet={brandedlandingpagelogoSet.slice(0, 4)}
        brandedlandingpagelocationsSet={brandedlandingpagelocationsSet}
        brandedlandingpagelocationsapplicationSet={brandedlandingpagelocationsapplicationSet}
        locationLinksTitle={locationLinksTitle}
        form={form}
        brand={brand}
        kldb={kldb}
        setFormIsDirty={setFormIsDirty}
        buzzwords={buzzwords}
        monaAiId={monaAiId}
        monaAiPostingId={monaAiPostingId}
      />
      <LandingPageComponentRenderer edges={elements?.edges ?? []} slug={slug} centeredHeadlines />
    </BrandedWrapper>
  );
};

export default BrandedLandingPageApp;
