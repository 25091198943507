import { parsePhoneNumber } from 'react-phone-number-input';
import { getTransactionId } from '@adecco/base-app/src/utilities/tracking/transactionid';
import { CreateAppMutationResult } from '@adecco/base-app/src/gql/schema/generated';
import { getQueryParam } from '@adecco/base-app/src/utilities/tracking/query';

interface IValues {
  [key: string]: string | number;
}

export interface IOptions {
  withNoAts?: boolean;
}

export interface ISaveData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: Record<string, any>;
  id?: string;
  countryCode: string;
  privacyConsentVersion: string;
  contextVarSource: string;
  options?: IOptions;
}

export async function saveData({
  mutation,
  values,
  id,
  countryCode,
  contextVarSource,
  options,
  privacyConsentVersion,
}: ISaveData) {
  const withNoAts = options?.withNoAts;

  const data: IValues = {};

  Object.entries(values).forEach(([key, val]) => {
    if (val instanceof Object) {
      data[key] = val.value;
    } else {
      data[key] = val;
    }
  });

  data.jobId = values.sourceAdId || id;
  data.transactionId = getTransactionId();
  data.varSource = data.contactSource || getQueryParam('varSource') || contextVarSource;
  data.varCampaign = getQueryParam('varCampaign');
  data.varMedium = getQueryParam('varMedium');
  const transformedPhone = data.phone ? parsePhoneNumber(data.phone.toString()) : '';
  const countryCallingCode = transformedPhone ? transformedPhone.countryCallingCode : '';

  const defaultValues = {
    city: withNoAts ? 'not-defined' : null,
    desiredJobLocation: withNoAts ? 'not-defined' : null,
    number: withNoAts ? 'not-defined' : null,
    phone: withNoAts ? 'not-defined' : '',
    phoneCountryCode: withNoAts ? 'not-defined' : '',
    street: withNoAts ? 'not-defined' : null,
    zipcode: withNoAts ? '55555' : null,
    whatsappAllowedStatus: false,
  };

  const variables = {
    city: data.city || defaultValues.city,
    countryCode,
    desiredJobLocation:
      data.desiredJobLocation || data.location || defaultValues.desiredJobLocation,
    desiredPosition: data.desiredPosition || null,
    maxCommuteDistanceKm: Number(data.maxCommuteDistanceKm) || null,
    email: data.email,
    firstName: data.firstName,
    greeting: data.greeting || null,
    jobId: data.jobId?.toString() || null,
    lastName: data.lastName,
    number: data.number?.toString() || defaultValues.number,
    phone: transformedPhone ? transformedPhone.nationalNumber : defaultValues.phone,
    phoneCountryCode: countryCallingCode
      ? `+${countryCallingCode}`
      : defaultValues.phoneCountryCode,
    schaltungsId: data.schaltungsId || null,
    sid: data.branchId || data.location || data.sid || null,
    street: data.street?.toString() || defaultValues.street,
    transactionId: data.transactionId || null,
    varCampaign: data.varCampaign || null,
    varContent: data.buzzwords || getQueryParam('varContent') || null,
    varMedium: data.varMedium || null,
    varSource: data.varSource || null,
    varTerm: data.varType || null,
    varType: data.varType,
    zipcode: data.zipcode?.toString() || defaultValues.zipcode,
    above18: data.termsOfUse ?? false,
    isSpontaneous: !data.schaltungsId || data.schaltungsId === '0',
    whatsappAllowedStatus: data.whatsappAllowedStatus || defaultValues.whatsappAllowedStatus,
    marketingPreferencesStatus: data.MarketingOptIn,
    marketingPreferencesType: 'marketingCommunication',
    privacyConsentStatus: data.privacyPolicy,
    privacyConsentType: 'privacyNotice',
    privacyConsentVersion: 'privacyConsentVersion',
    termsAndConditionsStatus: data.termsOfUse,
    fileCv: values?.fileCv ?? null,
    fileOthers: values?.fileOthers ?? null,
    numberMonthsUpdateCheck: Number(data.numberMonthsConsent) ?? null,
  };

  try {
    const result: CreateAppMutationResult = await mutation({
      variables,
    });

    return {
      transactionId: data.transactionId,
      applicationErrors: result?.data?.createApplication?.errors,
    };
  } catch (_error) {
    return {
      error: true,
    };
  }
}
