import * as React from 'react';
import styled from 'styled-components';
import JobFactList from '@adecco/base-app/src/components/molecules/JobFactList/JobFactList';
import { LocationType } from '@adecco/base-app/src/gql/schema/generated';

export type FactLocation = Pick<LocationType, 'location' | 'isMainLocation'>;

export interface IJobFacts {
  workHours?: string | null;
  jobLocations: FactLocation[];
  className?: string;
}

const JobHeaderStyled = styled.div`
  width: 100%;
`;

const JobFacts: React.FunctionComponent<IJobFacts> = ({ workHours, jobLocations, className }) => {
  const facts = [];

  if (workHours) facts.push({ field: 'workHours', value: workHours });

  const locations =
    jobLocations.length > 0
      ? [...jobLocations]
          .sort((_a, b) => (b.isMainLocation ? 1 : -1))
          .map((element) => element.location || '')
          .filter(Boolean)
          .join(', ')
      : '';

  if (locations) facts.push({ field: 'jobLocation', value: locations });

  return facts.length > 0 ? (
    <JobHeaderStyled className={className}>
      <JobFactList facts={facts} />
    </JobHeaderStyled>
  ) : null;
};

export default JobFacts;
