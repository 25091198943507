import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { copytextbase } from '@adecco/base-app/src/styles/typography';
import Icon from '@adecco/base-app/src/components/atoms/Icon/Icon';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import InfoText from '@adecco/base-app/src/components/atoms/InfoText/InfoText';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

interface IFooter {
  className?: string;
}

// TODO Extract to theme if skinning requires values modification
const LINK_CLICK_AREA_PADDING_Y_MBL = 7;
const LINK_CLICK_AREA_PADDING_Y_DSK = 5;
const LINK_CLICK_AREA_PADDING_X = 15;

const footerSectionBase = `
  padding: 0 15px;

  ${moreThan('md')} {
    max-width: ${calcrem(1120)};
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
`;

const FooterStyled = styled.footer`
  overflow: hidden;
  padding-bottom: calc(${calcrem(180)} - ${calcrem(LINK_CLICK_AREA_PADDING_X)});
  padding-top: ${calcrem(70)};

  ${moreThan('md')} {
    padding-top: ${calcrem(100)};
  }

  @media print {
    display: none;
  }
`;

const FooterHeader = styled.section`
  ${footerSectionBase};
  margin-bottom: ${calcrem(40 - LINK_CLICK_AREA_PADDING_X)};
`;

const FooterLogoContainer = styled.h2`
  border-bottom: ${(props) => props.theme.borders.secondFooter};
  padding-bottom: ${calcrem(26)};
  margin-bottom: ${(props) => props.theme.margins.secondFooter};

  ${moreThan('md')} {
    padding-bottom: ${calcrem(24)};
    margin-bottom: ${(props) => props.theme.margins.secondFooterWideScreen};
  }

  svg {
    height: ${calcrem(30)};
  }
`;

const Link = styled.a`
  ${copytextbase};
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0;
`;

const ContactLinkList = styled.ul`
  ${moreThan('md')} {
    display: flex;
  }
`;

const ContactLinkItem = styled.li`
  padding: ${(props) => props.theme.paddings.contactLinkItem};
  width: fit-content;

  &:not(:last-child) {
    margin-bottom: ${(props) => props.theme.margins.contactLinkList};
  }

  ${moreThan('md')} {
    &:not(:last-child) {
      margin-bottom: ${calcrem(0)};
      margin-right: ${(props) => props.theme.margins.contactLinkListWideScreen};
    }
  }
`;

const ContactLink = styled(Link)`
  border-radius: ${(props) => props.theme.radius.contactLinkItem};
  padding: ${(props) => props.theme.paddings.contactLinkItemLink};
  background-color: ${(props) => props.theme.backgrounds.contactLinkItem};
  font-family: ${(props) => props.theme.fonts.Copy};
  font-weight: ${(props) => props.theme.fontWeights.link};
  display: flex;
  flex-direction: row;
  align-items: center;
  
  :hover {
    background-color: ${(props) => props.theme.backgrounds.contactLinkHover};
  }
`;

const ContactLinkIcon = styled(Icon)`
  display: inline-flex;
`;

const ContactLinkText = styled.span`
  color: ${(props) => props.theme.colors.contactLinkItem};
  padding-left: 10px;
  white-space: nowrap;
  font-size: ${(props) => props.theme.fontSizes.contactLinkText};
`;

const FooterMain = styled.div`
  ${footerSectionBase};
  margin-bottom: calc(${calcrem(40)} - ${calcrem(LINK_CLICK_AREA_PADDING_X)});
`;

const PageLinkList = styled.ul`
  margin: 0 ${calcrem(LINK_CLICK_AREA_PADDING_X * -1)};

  ${moreThan('md')} {
    display: grid;
    grid-template-columns: repeat(2, ${calcrem(200)});
  }
`;

const PageLink = styled(Link)`
  display: block;
  font-family: ${(props) => props.theme.fonts.Copy};
  font-weight: ${(props) => props.theme.fontWeights.page};
  margin: 0;
  padding: ${calcrem(LINK_CLICK_AREA_PADDING_Y_MBL)} ${calcrem(LINK_CLICK_AREA_PADDING_X)};
  color: ${(props) => props.theme.colors.footerLink};
  text-decoration: ${(props) => props.theme.textDecorations.footerLink};

  &:hover {
    color: ${(props) => props.theme.colors.footerLinkHover};
  }

  ${moreThan('md')} {
    padding-bottom: ${calcrem(LINK_CLICK_AREA_PADDING_Y_DSK)};
    padding-top: ${calcrem(LINK_CLICK_AREA_PADDING_Y_DSK)};
  }
`;

const PageItem = styled.p<{
  marginBottom?: boolean;
}>`
  display: block;
  font-family: ${(props) => props.theme.fonts.Copy};
  font-size: ${(props) => props.theme.fontSizes.footerCopy};
  font-weight: ${(props) => props.theme.fontWeights.page};
  margin: 0;
  padding: ${calcrem(LINK_CLICK_AREA_PADDING_Y_MBL)} ${calcrem(LINK_CLICK_AREA_PADDING_X)};
  margin-bottom: ${(props) => (props.marginBottom ? props.theme.margins.page : 0)};

  ${moreThan('md')} {
    padding-bottom: ${calcrem(LINK_CLICK_AREA_PADDING_Y_DSK)};
    padding-top: ${calcrem(LINK_CLICK_AREA_PADDING_Y_DSK)};
  }
`;

const FooterFooter = styled.section`
  ${footerSectionBase};
  margin: 0 ${calcrem(-LINK_CLICK_AREA_PADDING_X)};

  ${moreThan('md')} {
    a,
    p {
      padding-left: 0;
    }
  }
`;

const Footer: React.FunctionComponent<IFooter> = ({ className }) => {
  const { withImprint, t } = useAppContext();
  const theme = useTheme();
  const genderText = t('general-all-gender');

  return (
    <FooterStyled className={className}>
      {genderText.trim().length > 1 && (
        <Container>
          <InfoText text={genderText} />
        </Container>
      )}
      <FooterHeader>
        <FooterLogoContainer>
          <Icon name={'logo'} width={125} />
        </FooterLogoContainer>
        <ContactLinkList>
          <ContactLinkItem>
            <ContactLink href={t('footer-tel-link')} target="_blank" rel="noopener">
              <ContactLinkIcon name={'headset'} width={theme.widths.contactLinkIcon} />
              <ContactLinkText>{t('footer-tel-text')}</ContactLinkText>
            </ContactLink>
          </ContactLinkItem>
          <ContactLinkItem>
            <ContactLink href={t('footer-mail-link')} target="_blank" rel="noopener">
              <ContactLinkIcon name={'email'} width={theme.widths.contactLinkIcon} />
              <ContactLinkText>{t('footer-mail')}</ContactLinkText>
            </ContactLink>
          </ContactLinkItem>
          <ContactLinkItem>
            <ContactLink href={t('footer-branches-link')} target="_blank" rel="noopener">
              <ContactLinkIcon name={'pin'} width={theme.widths.contactLinkIcon} />
              <ContactLinkText>{t('footer-branches')}</ContactLinkText>
            </ContactLink>
          </ContactLinkItem>
        </ContactLinkList>
      </FooterHeader>
      <FooterMain role="navigation" aria-label="Secondary">
        <PageLinkList>
          {withImprint && (
            <li>
              <PageLink href={t('footer-imprint-link')} target="_blank">
                {t('footer-imprint')}
              </PageLink>
            </li>
          )}
          <li>
            <PageLink href={t('footer-data-policy-link')} target="_blank">
              {t('footer-data-policy')}
            </PageLink>
          </li>
          <li>
            <PageLink href={t('footer-cookie-policy-link')} target="_blank">
              {t('footer-cookie-policy')}
            </PageLink>
          </li>
          <li>
            <PageLink href={t('footer-terms-of-use-link')} target="_blank">
              {t('footer-terms-of-use')}
            </PageLink>
          </li>
        </PageLinkList>
      </FooterMain>
      <FooterFooter>
        <PageItem>© {t('footer-rights-reserved')}</PageItem>
      </FooterFooter>
    </FooterStyled>
  );
};
export default Footer;
