//
// Please always change in both files(.js for the config, .ts for the routes)
//
interface IRouteNames {
  [key: string]: {
    applyRoute: string;
    applicationSentRoute: string;
  };
}

export const routeNames: IRouteNames = {
  de: {
    applyRoute: 'bewerben',
    applicationSentRoute: 'bewerbung-gesendet',
  },
  en: {
    applyRoute: 'apply',
    applicationSentRoute: 'sent',
  },
  pl: {
    applyRoute: 'stosowac',
    applicationSentRoute: 'wniosek-wyslanie',
  },
};
