import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { Theme } from '@adecco/base-app/src/constants/themes/defaultTheme/themeType';

const defaultTheme: Theme = {
  aligns: {
    jobFactItem: 'center',
  },
  backgrounds: {
    aboutUs: 'rgb(252, 252, 252)',
    benefit: '#ffffff',
    cardIconContainer: '#ffffff',
    checkBox: 'white',
    circleIcon: '#5cb85c',
    faqItem: 'none',
    input: 'none',
    jobListTeaserFooter:
      'radial-gradient(circle, rgba(224, 224, 224, 1) 0%, rgba(255, 255, 255, 0) 100%)',
    logoButton: 'rgb(252, 252, 252)',
    processNumber: '#da291c',
    progressBar: 'transparent',
    progressBarDone: 'rgb(92, 184, 92)',
    progressBarItem: {
      done: 'transparent',
      current: 'transparent',
      future: '#f8f8f8',
      finished: 'transparent',
    },
    salaryScale: '#efefef',
    searchBox: 'linear-gradient(90deg, #da291c 50%, #da291c 100%)',
    smallGhostButton: 'transparent',
    spacer:
      'linear-gradient(178deg, rgba(0, 0, 0, 0) 10%, rgba(244, 244, 244, 1) 10%, rgba(255, 255, 255, 0) 100%)',
    speechBubbleWrapper: '#7cc67c',
    tab: 'linear-gradient(-180deg, rgba(238, 238, 238, 0) 0%, rgba(216, 216, 216, 0.16) 100%)',
    teaser: '#ffffff',
    wrapper: 'rgb(255, 255, 255)',
  },
  blendModes: {
    processNumber: 'initial',
  },
  borders: {
    aboutUs: '1px solid rgba(151, 151, 151, 0.35)',
    backBarLink: '1px solid #e6e6e6',
    backBarLinkBlack: '2px solid #191919',
    black: '1px solid #000',
    bullet: '1px solid #aaa',
    callToAction: '1px solid rgba(239, 46, 36)',
    checkBox: '2px solid #afafaf',
    checkMarkDarkGrey: '0.5px solid #555555',
    checkMarkPrimary: '0.5px solid #da291c',
    circleIconWidth: '1px',
    errorField: '1px solid #da291c',
    form: '1px solid #e6e6e6',
    helpText: '0.5px solid rgb(175, 175, 175)',
    inputField: '1px solid #CFCFCF',
    link: '1px solid #111',
    logoButton: '1px solid #eee',
    materialDown: '1px solid #d5d5d5',
    panel: '1px solid rgb(230, 230, 230)',
    progressBar: '1px solid rgb(230, 230, 230)',
    progressBarItemColor: '#e6e6e6',
    progressBarItemWidth: '1px',
    radioButton: '0.5px solid #555555',
    radioButtonError: '0.5px solid #da291c',
    regular: '1px solid #e6e6e6',
    searchButton: '1.5px solid #FFFFFF',
    secondFooter: '1px solid #c8c7c7',
    smallGhostButton: '1px solid #333',
    speechBubbleAfter: '8px 6px 0 6px',
    teaser: '1px solid #e6e6e6',
    wrapper: '1px solid rgb(225,225,225)',
  },
  boxShadow: {
    boxShadow: '0px 8px 10px 0px rgba(0, 0, 0, 0.08)',
    brandBarWrapper: '0 4px 8px 0 rgba(213, 213, 213, 0.5)',
    cardInfo: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
    checkBox: 'inset 2px 2px 3px 0 rgba(0, 0, 0, 0.1)',
    contact: '0px 4px 6px 0px rgba(0, 0, 0, 0.07)',
    fact: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
    faqItem: '0 4px 6px 0 rgba(0, 0, 0, 0.07)',
    callToAction: '0 5px 15px 0 rgba(218, 41, 28, 0.15)',
    faqItemOpen: '0',
    fileField: 'inset 2px 2px 3px 0 rgba(0, 0, 0, 0.1)',
    helpText: 'inset 2px 2px 3px 0 rgba(0, 0, 0, 0.05)',
    imageTeaser: '0 5px 15px 0 rgba(0, 0, 0, 0.1)',
    input: 'inset 2px 2px 3px 0 rgba(0, 0, 0, 0.1)',
    materialMid: 'inset 2px 2px 3px 0px rgba(0, 0, 0, 0.1)',
    materialUp: '0px 5px 15px 0px rgba(0, 0, 0, 0.1)',
    media: 'none',
    mediaWideScreen: 'none',
    pageHead: '0px -2px 8px 0px rgba(0, 0, 0, 0.22)',
    pageHeadContainer: '0px 2px 4px 0px rgba(233, 233, 233, 0.5)',
    speechBubbleWrapper: '0px 10px 10px 0px rgba(0, 0, 0, 0.11)',
  },
  colors: {
    infoText: '#555555',
    infoTextIcon: '#aaa',
    activeBullet: '#ffffff',
    benefitHeadline: '#333',
    black: '#191919',
    bullet: '#e6e6e6',
    cardInfoHeadline: '#191919',
    checkTextError: '#da291c',
    error: 'rgb(239, 46, 36)',
    faqItemOpenElement: '#1a1919',
    green: '#7cc67c',
    headline: '#191919',
    infoIconBackground: '#fff',
    infoIconEmphasizedBackground: '#fff',
    infoIconEmphasizedForeground: '#191919',
    infoIconForeground: '#aaa',
    inputText: '#333',
    jobLink: '#da291c',
    jobListItem: '#191919',
    label: '#333',
    lightBlack: '#1a1919',
    lighterGrey: '#eee',
    lightestGrey: '#f8f8f8',
    lightGrey: '#e6e6e6',
    location: '#333',
    medGrey: '#CFCFCF',
    paragraph: '#555555',
    primary: '#da291c',
    inputFieldError: '#da291c',
    progressBarIcon: {
      done: 'rgb(85, 85, 85)',
      current: 'rgb(85, 85, 85)',
      future: 'rgb(151, 151, 151)',
      finished: '#FFFFFF',
    },
    progressBarItem: {
      done: '#555555',
      current: '#555555',
      future: '#555555',
      finished: '#555555',
    },
    progressBarItemContent: {
      done: 'rgb(85, 85, 85)',
      current: 'rgb(85, 85, 85)',
      future: 'rgb(151, 151, 151)',
      finished: 'rgb(85, 85, 85)',
    },
    requiredStar: '#da291c',
    rgbblack: '0,0,0',
    smallGhostButton: '#333',
    statusBarYellow: '#ffc15d',
    subline: '#333',
    success: '#5cb85c',
    text: '#333',
    textGrey: '#aaa',
    secondaryButton: '#aaa',
    textlink: 'rgb(58,174,244)',
    contactLink: 'rgb(58,174,244)',
    twoColParagraph: '#e6e6e6',
    white: '#FFFFFF',
    processNumber: '#FFFFFF',
    baseButtonText: '#FFFFFF',
  },
  displays: {
    backBarLink: 'none',
    backToSearchMobileLink: 'inline-block',
    factImage: 'block',
    infoButton: 'row',
    formLanguage: 'flex',
    processNumber: 'flex',
    progressBarDone: 'inline-block',
    jobFactList: 'block',
    searchBox: 'block',
    thankYouTeaserList: 'grid',
    contactDescription: 'none',
  },
  flexDirections: {},
  fonts: {
    cardInfoHeadline: 'Houschka-DemiBold, Helvetica, Arial, sans-serif',
    Copy: 'Nunito, Helvetica, Arial, sans-serif',
    formHead: 'Houschka-DemiBold, Helvetica, Arial, sans-serif',
    formHeadWideScreen: 'Houschka-Medium, Helvetica, Arial, sans-serif',
    Headline: 'Houschka-Medium, Helvetica, Arial, sans-serif',
    HeadlineBold: 'Houschka-DemiBold, Helvetica, Arial, sans-serif',
    input: 'Houschka-DemiBold, Helvetica, Arial, sans-serif',
  },
  fontSizes: {
    benefitHeadline: calcrem(18),
    benefitHeadlineWideScreen: calcrem(24),
    brandedHeadline: calcrem(30),
    brandedHeadlineWideScreen: calcrem(48),
    brandedSubline: calcrem(25),
    brandedSublineWideScreen: calcrem(28),
    brandedThankYouDeSubline: calcrem(28),
    button: calcrem(18),
    buttontext: calcrem(28),
    buttonWideScreen: calcrem(24),
    cardIconHeadline: calcrem(18),
    cardImageHeadline: calcrem(18),
    checkboxLabel: calcrem(18),
    checkText: calcrem(16),
    dataBadge: calcrem(19),
    dateParagraph: calcrem(14),
    dateParagraphWideScreen: calcrem(16),
    errorMessageText: calcrem(16),
    errorMessageTextHeading: calcrem(18),
    factHeadline: calcrem(86),
    factListHeadlineWideScreen: calcrem(40),
    factSubline: calcrem(20),
    faqHeadline: calcrem(32),
    faqItemHeadline: '18px',
    faqItemHeadlineWideScreen: calcrem(24),
    formHead: calcrem(18),
    formHeadWideScreen: calcrem(24),
    headline1: calcrem(32),
    headline1WideScreen: calcrem(48),
    headline2: calcrem(32),
    headline2WideScreen: calcrem(40),
    headline3: calcrem(24),
    headline4: calcrem(18),
    headlineTeaser: calcrem(20),
    headline4Widescreen: calcrem(24),
    imageTeasersHeadlineWideScreen: calcrem(40),
    infoText: calcrem(16),
    jobContentHeadlineWideScreen: calcrem(40),
    jobHeaderHeadline: calcrem(40),
    jobHeaderHeadlineWideScreen: calcrem(48),
    jobSearchListHeadline: '28px',
    jobSearchListHeadlineWideScreen: '32px',
    jobTeaserDate: calcrem(16),
    label: calcrem(18),
    landingPageHeadline: '32px',
    landingPageHeadlineWideScreen: '48px',
    landingPageParagraph: '18px',
    link: calcrem(18),
    linkList: calcrem(20),
    locationList: calcrem(18),
    logoButtonHeadline: '32px',
    logoButtonHeadlineWideScreen: '40px',
    mediaHeadline: '32px',
    mediaHeadlineWideScreen: '40px',
    placeholder: calcrem(18),
    processHeadlineWideScreen: calcrem(40),
    processItemNumber: calcrem(20),
    processSubline: '28px',
    processSublineWideScreen: '32px',
    sidebarButton: calcrem(24),
    smallButton: '18px',
    speechBubble: '20px',
    subline: calcrem(28),
    sublineWideScreen: calcrem(32),
    tab: '20px',
    text: calcrem(16),
    textWideScreen: calcrem(16),
    thankYou: '40px',
    thankYouTeaser: '20px',
    thankYouWideScreen: '48px',
    typographyHeadline2: calcrem(28),
    typographyHeadline2WideScreen: calcrem(32),
  },
  fontStyles: {
    infoDropdown: 'italic',
    infoTextText: 'italic',
  },
  fontWeights: {
    button: '600',
    faqHeadline: '800',
    headline: 'bold',
    jobFactItem: '600',
    link: '600',
    listParagraph: 'bold',
    locationList: '600',
    locationListHeadline: '600',
    page: '100',
    processItemNumber: '600',
    speechBubble: '600',
    tab: '500',
    textLink: 'bold',
    twoColParagraphStrong: '600',
  },
  gaps: {
    jobFactList: '0 1rem',
    factList: `${calcrem(20)} ${calcrem(20)}`,
  },
  headerSpacing: {
    boxShadowOffest: '2px',
    inputCustomHeight: '48px',
    logoHeight: '26px',
    searchBoxFakeHeight: '78px',
    searchBoxFakeHeightWithSearch: '180px',
    searchBoxFakeMarginBottom: '130px',
    searchBoxPaddingBottom: '30px',
    searchBoxPaddingTop: '45px',
    searchIconExtraPadding: '2px',
  },
  heights: {
    checkMark: calcrem(32),
    brandBarAfter: '6px',
    brandBarAfterWideScreen: '8px',
    faqItemOpenElement: '2px',
    input: '46px',
    jobSearchInput: '46px',
    spacerMax: '700px',
    jobFactItemIconContainer: '35px',
  },
  lineHeights: {
    benefitHeadline: calcrem(20),
    benefitHeadlineWideScreen: calcrem(26),
    buttontext: calcrem(32),
    brandedSubline: calcrem(30),
    brandedSublineWideScreen: calcrem(38),
    brandedThankYouDeSubline: calcrem(38),
    checkText: calcrem(22),
    checkTextWideScreen: calcrem(24),
    contactParagraph: '18px',
    dateParagraph: calcrem(16),
    dateParagraphWideScreen: calcrem(18),
    errorText: calcrem(17),
    fact: calcrem(70),
    factSubline: calcrem(26),
    faqHeadline: calcrem(36),
    formHead: calcrem(20),
    formHeadWideScreen: calcrem(24),
    headline1: calcrem(36),
    headline1WideScreen: calcrem(52),
    headline2: calcrem(36),
    headline2WideScreen: calcrem(44),
    headline3: calcrem(24),
    headline3WideScreen: calcrem(28),
    headline4: calcrem(20),
    headline4WideScreen: calcrem(26),
    infoText: calcrem(20),
    jobFactItem: '18px',
    jobHeaderHeadline: calcrem(44),
    jobHeaderHeadlineWideScreen: calcrem(52),
    jobListTeaser: calcrem(16),
    label: calcrem(20),
    landingPageHeadline: '36px',
    landingPageHeadlineWideScreen: '52px',
    locationList: calcrem(28),
    logoButtonHeadline: calcrem(30),
    logoButtonHeadlineWideScreen: calcrem(36),
    paragraph: calcrem(22),
    paragraphWideScreen: calcrem(26),
    placeholder: calcrem(20),
    processItemHeadline: calcrem(20),
    processItemNumber: calcrem(20),
    processSubline: calcrem(30),
    processSublineWideScreen: calcrem(36),
    smallButton: '20px',
    speechBubble: '28px',
    subline: calcrem(30),
    sublineWideScreen: calcrem(36),
    tabsModuleHeadlineWideScreen: '44px',
    text: calcrem(26),
    textWideScreen: calcrem(26),
    typographyHeadline2: calcrem(30),
    typographyHeadline2WideScreen: calcrem(36),
  },
  margins: {
    checkMark: calcrem(4),
    checkMarkWideScreen: calcrem(1),
    aboutUs: '40px',
    aboutUsHeadline: '2px',
    aboutUsImageContainer: '0 0 20px',
    appForm: calcrem(62),
    appFormWideScreen: calcrem(80),
    appInfo: calcrem(6),
    appInfoWideScreen: calcrem(20),
    applyStepsContainer: `0 ${calcrem(-15)} ${calcrem(35)}`,
    applyStepsContainerWideScreen: calcrem(80),
    applyStepsDesktop: '0 20px',
    applyStepsHeadline: '10px',
    applyStepsHeadlineWideScreen: '30px',
    applyStepsSubline: calcrem(20),
    applyStepsSublineWideScreen: calcrem(20),
    backBarLink: '12px',
    benefitCarousel: `0 ${calcrem(-15)} ${calcrem(20)}`,
    benefitHeadline: '30px',
    benefitHeadlineWideScreen: '30px',
    benefitListItems: calcrem(44),
    button: calcrem(20),
    buttonWideScreen: calcrem(24),
    cardIcon: '10px',
    cardIconContainer: '20px',
    checkButton: '1px 10px 0 0',
    clientLandingPageParagraph: '20px',
    contact: '0 auto 22px auto',
    contactHeadlineBottom: '16px',
    contactLinkList: calcrem(20),
    contactLinkListWideScreen: calcrem(34),
    contactPictureBottom: '16px',
    contactWideScreenBottom: '24px',
    container: '0 auto 30px',
    containerWideScreenBottom: '60px',
    dateBadge: '9px',
    dateParagraph: '18px',
    details: '1rem 0',
    errorMessage: '0',
    errorMessageHeading: '0',
    errorMessageHeadingWideScreen: '0 0 20px 0',
    errorMessageWideScreen: '-25px 0 20px 0',
    fact: calcrem(20),
    factHeadline: '30px',
    factHeadlineWideScreen: calcrem(45),
    faqHeadline: '0 0 30px',
    fieldRow: calcrem(14),
    fieldRowWideScreen: calcrem(20),
    fileFieldButton: '25px',
    form: '20px',
    formRow: `0 -${calcrem(5)}`,
    formSection: '0 -10px',
    header: '0 -10px',
    headline: calcrem(30),
    headLine: '0 0 20px',
    headLineBottom: '20px',
    headlineTeaser: '10px',
    iconRight: '4px',
    iconWideScreen: '5px 4px 0 0',
    image: '10px',
    imageTeaser: '30px',
    imageTeaserHeadline: '0 0 30px',
    imageTeaserParagraph: '10px',
    imageWideScreen: '20px',
    infoText: calcrem(8),
    jobContent: '30px',
    jobDetailsButton: '30px',
    jobFactHelpText: '-5px',
    jobFactList: '0px',
    jobFactListWideScreen: '32px',
    jobList: '20px',
    jobListButtonBottom: '10px',
    jobListButtonLeft: '10px',
    jobListButtonRight: '20px',
    jobListButtonTop: '20px',
    jobListTeaserFooterLeft: '-100px',
    jobListTeaserFooterTop: '15px',
    jobListTeaserListItem: '10px',
    jobListTeaserListItemIcon: '15px',
    jobMain: '40px',
    jobSearchList: '50px',
    landingPageHeadline: '10px',
    linkListHeadline: calcrem(30),
    linkListIcon: '15px',
    list: '0 -20px',
    listItem: `${calcrem(15)} 0px`,
    locationList: '10px 0 0 30px',
    locationListLi: '10px',
    logoButton: '0 -10px 40px',
    media: '20px',
    mobileButton: '30px',
    modalContent: '0 40px',
    modalContentP: '1em',
    modalContentP1th: '0.5em',
    modalContentP5th: '1em',
    modalFooter: '0 0 40px 40px',
    modalHeader: '40px 0 1em 40px',
    multiMediaAboveline: '10px',
    optionContainer: '30px',
    page: '20px',
    pageHead: '30px',
    pageHeadWideScreen: '75px',
    pageWideScreen: '80px',
    paragraphBottom: '20px',
    phoneInput: '0 5px',
    phoneInputWideScreen: '0 15px',
    processCarousel: `0 ${calcrem(-15)} 0`,
    processItem: '0 -10px',
    processItemHeadline: calcrem(6),
    progressBar: '40px',
    searchBoxChildren: '24px',
    searchBoxInput: '20px',
    searchBoxInputWideScreen: '0px',
    secondFooter: calcrem(22),
    secondFooterWideScreen: calcrem(20),
    selectWideScreen: '4px',
    sidebarButton: '18px',
    spacer: '0',
    speechBubble: '20px',
    speechBubbleIcon: '2px 10px 0 0',
    statusBar: '20px',
    tab: '10px',
    templateHeadline: '15px',
    templateIconRight: '20px',
    thankYouTeaserImage: '0 -30px',
    twoColTextHeadline: '30px',
  },
  opacities: {
    buttonHover: '0.3',
  },
  overflows: {
    thankYouTeaser: 'hidden',
  },
  paddings: {
    container: '0 15px',
    defaultButton: '25px 50px',
    fact: calcrem(30),
    factWideScreen: `${calcrem(32)} ${calcrem(30)} ${calcrem(36)}`,
    faqItemClose: `${calcrem(26)} ${calcrem(105)} ${calcrem(26)} ${calcrem(75)}`,
    faqItemOpen: `${calcrem(26)} ${calcrem(105)} ${calcrem(46)} ${calcrem(75)}`,
    fieldGroup: `0 ${calcrem(5)}`,
    fieldGroupWideScreen: `0 ${calcrem(15)}`,
    fileUploadHeadline: `0 ${calcrem(5)}`,
    fileUploadHeadlineWideScreen: `0 ${calcrem(13)}`,
    formField: calcrem(14),
    formFieldWideScreen: calcrem(30),
    formSection: `${calcrem(20)} ${calcrem(20)} ${calcrem(14)}`,
    formSectionWideScreen: `${calcrem(24)} ${calcrem(20)} ${calcrem(4)}`,
    ghostButtonWideScreen: '15px 20px',
    header: `${calcrem(16)} ${calcrem(20)} ${calcrem(18)}`,
    headerWideScreen: `${calcrem(20)} ${calcrem(30)} ${calcrem(24)}`,
    imageTeaserWrapper: '15px 20px 20px',
    infoDropDownWideScreen: calcrem(45),
    jobFactItem: '10px 0',
    jobFactItemWideScreen: '10px 0',
    jobFactListFirstItem: '0',
    landingPageImg: '30px',
    leadFormSection: '24px',
    leadFormSectionWideScreen: '30px',
    linkgeneratorForm: '10px',
    panel: '5px 10px',
    processItemContainer: `${calcrem(12)} ${calcrem(12)} ${calcrem(30)}`,
    processItemContainerWideScreen: `${calcrem(20)} 0 ${calcrem(10)}`,
    progressBarContainer: '0',
    progressBarIcon: '10px 10px 10px 0',
    progressBarIconWideScreen: '10px 20px 10px 0',
    smallButton: '10px 16px',
    spacer: '70px 0',
    spacerWideScreen: '70px 0',
    thankYou: '40px',
    thankYouTeaser: '0 30px 25px',
    twoColParagraph: '20px',
    twoColText: '50px',
  },
  positions: {
    doneIcon: 'auto 5px 10px auto',
    doneIconWideScreen: 'auto 10px 10px auto',
    jobListFooterLineLeft: '50%',
    processNumberBottom: '23px',
    processNumberBottomWideScreen: '14px',
    processNumberLeft: '17px',
    processNumberLeftWideScreen: '14px',
    speechBubbleAfterBottom: '-8px',
    speechBubbleAfterLeft: '20px',
  },
  radius: {
    aboutUsImg: '5px',
    baseButton: '15px',
    button: '6px',
    cardInfo: '8px',
    checkBox: '2px',
    contactIcon: '50%',
    dateBadge: '8px',
    faqItem: '8px',
    fileField: '8px',
    image: '10px',
    imageTeaser: '10px',
    input: '5px',
    landingPageHeader: '8px',
    link: '6px',
    logoButton: '6px',
    media: '10px',
    mediaWideScreen: '8px',
    pageHead: '0px 0px 22px 22px',
    panel: '10px',
    processItemImage: '10px 10px 0 0',
    processNumber: '50%',
    progressBar: '8px',
    radioButton: '24px',
    rounded: '50%',
    smallButton: '6px',
    speechBubbleWrapper: '10px',
    statusBar: '4px',
    tab: '5px 5px 0 0',
    teaser: '8px',
    wrapper: '6px',
  },
  textAligns: {
    factHeadline: 'left',
  },
  textDecorations: {
    contactLink: 'none',
    location: 'underline',
  },
  textShadows: {
    speechBubble: '0 1px 2px rgba(0, 0, 0, 0.22)',
  },
  widths: {
    brandedSpeechBubble: '220px',
    contactLinkIcon: 24,
    checkMark: calcrem(32),
    container: calcrem(1120),
    jobListTeaserFooterLeft: '200px',
    processNumber: '35px',
    spacer: '100%',
    spacerMax: '100%',
    jobFactItemIconContainer: '35px',
  },
  zIndex: {
    header: '11',
    inputOverlap: '5',
    modal: '12',
    searchBox: '10',
  },
};

export default defaultTheme;
