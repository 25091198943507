import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
import { useCreateAppMutation } from '@adecco/base-app/src/gql/schema/generated';
import Form from '@adecco/base-app/src/components/atoms/Form/Form';
import LegalNoticeModal from '@adecco/base-app/src/components/molecules/LegalNoticeModal/LegalNoticeModal';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { saveData } from '@adecco/base-app/src/utilities/forms/savedata';
import LegalCheckboxes from '@adecco/base-app/src/components/molecules/LegalCheckboxes/LegalCheckboxes';
import FormRow from '@adecco/base-app/src/components/atoms/FormRow/FormRow';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import Button from '@adecco/base-app/src/components/atoms/Button/Button';
import FormSection from '@adecco/base-app/src/components/atoms/FormSection/FormSection';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { FormElementRenderer } from '@adecco/base-app/src/components/molecules/FormElementRenderer/FormElementRenderer';
import {
  AppInfoText,
  AppButton,
} from '@adecco/base-app/src/components/organisms/ApplicationForm/ApplicationForm';
import FormHeader from '@adecco/base-app/src/components/atoms/FormHeader/FormHeader';
import FileUploadFormElement from '@adecco/base-app/src/components/molecules/FileUploadFormElement/FileUploadFormElement';
import { validatedFiles } from '@adecco/base-app/src/utilities/forms/validateFiles';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import {
  IErrors,
  useApplicationFieldErrors,
} from '@adecco/base-app/src/hooks/useApplicationFieldErrors';
import { FormRendererType } from './FormRenderer.types';
import { getModalHeaderTitle, getModalContent } from '@adecco/base-app/src/utilities/getModalData';
import { QueryKeys, useGetQueryString } from '@adecco/base-app/src/hooks/useRouterQueries';
import { monaAi } from '@adecco/base-app/src/utilities/forms/monaAi';
import { useBrowserStorage } from '@adecco/base-app/src/hooks/useBrowserStorage';

const LeadFormSection = styled(FormSection)`
  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-top: none;
    padding-bottom: ${(props) => props.theme.paddings.leadFormSection};

    ${moreThan('md')} {
      padding-bottom: ${(props) => props.theme.paddings.leadFormSectionWideScreen};
    }
  }
`;

const ButtonStyled = styled(Button)`
  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.leadFormSectionButton};
    line-height: ${(props) => props.theme.lineHeights.leadFormSectionButton};
    min-height: ${(props) => props.theme.heights.leadFormSectionButton};
  }
`;

const FormRenderer: React.FunctionComponent<FormRendererType> = ({
  bigSubmit = false,
  desiredPosition,
  formHeader = false,
  formStructure,
  isSalesforce = false,
  kldb,
  locations,
  sid,
  submitRoute,
  varType,
  varSource,
  withDesiredJobCookie,
  withFileUpload,
  setFormIsDirty,
  pageType,
  formName,
  withSendEmail,
  branches,
  buzzwords,
  monaAiId,
  monaAiPostingId,
}) => {
  const forms = useForm();
  const router = useRouter();
  const qs = useGetQueryString();

  const {
    withDoi,
    withAgeConfirm,
    brand,
    t,
    privacyConsentVersion,
    withEmailConfirmation,
    varSource: contextVarSource,
    withNoAts,
  } = useAppContext();

  const [modalLegalNoticeOpen, setModalLegalNoticeOpen] = useState(false);
  const [ageConfirmed, setAgeConfirmed] = useState(!withAgeConfirm);
  const [isSubmitted, setSubmit] = useState(false);
  const [createApplication] = useCreateAppMutation();
  const [errorMessage, setErrorMessage] = useState(false);
  const errorMessageRef = useRef<HTMLDivElement>(null);
  const [applicationErrors, setApplicationErrors] = useState<IErrors>([]);
  const theme = useTheme();
  const { privacyPolicy, termsOfUse } = forms.getValues();
  const { setItem } = useBrowserStorage();
  useApplicationFieldErrors({ errors: applicationErrors, setErrorMessage, formMethods: forms });

  useEffect(() => {
    if (errorMessage) {
      if (errorMessageRef?.current) {
        window.scrollTo(0, errorMessageRef.current.offsetTop - window.screen.height / 2);
      }
    }
  }, [errorMessage]);

  const ageCheckboxHandler = () => {
    setAgeConfirmed(!ageConfirmed);
    forms.setValue('ageconfirm', !ageConfirmed);
  };

  const submitClickHandler = () => {
    if (!termsOfUse || !privacyPolicy) {
      setModalLegalNoticeOpen(true);
    }
  };

  const onSubmit = async (values: FieldValues) => {
    let onSubmitError = false;

    setErrorMessage(false);

    if (isSubmitted) {
      return;
    }

    setSubmit(true);

    const sendValues = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      desiredPosition: desiredPosition ?? values.desiredPosition ?? '',
      maxCommuteDistanceKm: values.distance,
      varType,
      varSource,
      sid: sid ?? '',
      // eslint-disable-next-line prefer-destructuring
      ...values,
      fileCv: values.file1,
      fileOthers: values.file4,
      buzzwords,
      brand,
    };

    try {
      if (monaAiId) {
        const { applicantId } = await monaAi({
          ...sendValues,
          externalID: monaAiId,
          jobPostingID: monaAiPostingId,
        });

        if (applicantId) {
          setItem('transaction_id', applicantId);
          return;
        }
      }

      if (!isSalesforce) {
        if (withDesiredJobCookie) {
          router.query[QueryKeys.desiredPosition] = values.desiredPosition;
        }

        if (!validatedFiles(values.file1) || !validatedFiles(values.file4)) {
          forms.setFocus('file1');
          return;
        }

        const { transactionId, applicationErrors, error } = await saveData({
          mutation: createApplication,
          values: sendValues,
          id: '0',
          countryCode: router.locale ?? '',
          privacyConsentVersion,
          contextVarSource,
          options: { withNoAts },
        });

        if (error) {
          setErrorMessage(true);
          onSubmitError = true;
          return;
        }

        if (applicationErrors) {
          setApplicationErrors(applicationErrors);
          const errorsFields = applicationErrors.map((error) => error?.field);

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'form_error',
            event_category: errorsFields.join(', '),
            event_action: pageType || 'na',
          });
          return;
        }

        if (withDoi && values.MarketingOptIn === true) {
          await fetch('/api/salesforceDoi', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: values.email, source: brand }),
          });
        }

        if (withSendEmail && withEmailConfirmation) {
          await fetch('/api/sendEmail', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
            }),
          });
        }

        if (transactionId) {
          setItem('transaction_id', transactionId);
        }
      } else {
        await fetch('/api/salesforce', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...values, Source: brand }),
        });
      }
    } finally {
      setSubmit(false);

      if (!onSubmitError) {
        const hasCV = values.file1?.length > 0 ? 'cv' : 'na';
        const hasOthers = values.file4?.length > 0 ? 'doc' : 'na';
        const fileFieldType = withFileUpload?.optional === true ? 'optional' : 'required';
        const event_category = withFileUpload ? [hasCV, hasOthers, fileFieldType].join('_') : 'na';
        // Setting the location for tracking in ClientLandingPage applications
        if (values.location) {
          const locationName = locations?.find(
            (location) => location.sid === values.location
          )?.name;
          if (locationName) router.query[QueryKeys.job_city] = locationName;
        }

        setItem('lp_data', { ...values, KLDB: kldb, buzzwords, monaAiId, monaAiPostingId, brand });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'form_submit',
          event_category,
          event_action: formName || 'na',
        });

        router.push({ pathname: submitRoute, query: router.query }, `${submitRoute}${qs}`);
      }
    }
  };

  const onError = (errors: FieldValues) => {
    const errorsFields = Object.keys(errors);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'form_error',
      event_category: errorsFields.join(', '),
      event_action: pageType || 'na',
    });
  };

  React.useEffect(() => {
    if (forms.formState.isDirty) {
      if (setFormIsDirty) setFormIsDirty(true);
    }
  }, [forms.formState.isDirty]);

  return (
    <>
      <FormProvider {...forms}>
        <Form onSubmit={forms.handleSubmit(onSubmit, onError)} formName={formName!}>
          {forms.formState.errors && Object.entries(forms.formState.errors).length ? (
            <ErrorMessage
              text={t('error-please-correct')}
              heading
              elementName={formName || 'form'}
            />
          ) : null}
          {formHeader ? <FormHeader headline={t('form-headline')} /> : null}
          <LeadFormSection>
            {formStructure.map((formRow, index) => (
              <FormRow key={`${submitRoute}-${index}`}>
                {formRow.map((formElement, elementIndex) => (
                  <FormElementRenderer
                    key={`${formElement.type}-${elementIndex}`}
                    type={formElement.type}
                    inputType={formElement.inputType}
                    register={forms.register}
                    required={formElement.required}
                    errors={forms.formState.errors}
                    size={formRow.length}
                    getValues={forms.getValues}
                    control={forms.control}
                    locationFields={
                      locations?.length !== 0
                        ? locations?.map((location) => ({
                            name: location.name,
                            value: location.sid,
                          }))
                        : undefined
                    }
                    defaultValue={formElement.defaultValue}
                    branches={branches}
                  />
                ))}
              </FormRow>
            ))}
          </LeadFormSection>
          {withFileUpload && (
            <LeadFormSection>
              <FileUploadFormElement
                optionalHeadline={t('form-field-optional-add-files')}
                errors={{
                  file1: !!forms.formState.errors.file1,
                  file4: !!forms.formState.errors.file4,
                }}
                isOptional={withFileUpload.optional}
              />
            </LeadFormSection>
          )}
          <LeadFormSection>
            <LegalCheckboxes
              register={forms.register}
              setValue={forms.setValue}
              errors={forms.formState.errors}
              ageCheckboxHandler={ageCheckboxHandler}
            />
            <FormRow>
              {bigSubmit ? (
                <>
                  <FieldGroup size={theme.sizes?.bigSubmitFieldGroup || 1 / 2}>
                    <AppButton
                      type="button"
                      variant="primary"
                      isLoading={isSubmitted}
                      onClick={() => submitClickHandler()}
                    >
                      {t('form-button-apply-now')}
                    </AppButton>
                  </FieldGroup>
                  <FieldGroup size={theme.sizes?.bigSubmitFieldGroup || 1 / 2}>
                    <AppInfoText text={t('form-info-required')} iconName="information-filled" />
                  </FieldGroup>
                </>
              ) : (
                <FieldGroup size={1}>
                  <ButtonStyled
                    type="button"
                    variant="small"
                    isLoading={isSubmitted}
                    onClick={() => submitClickHandler()}
                  >
                    {t('form-button-interested')}
                  </ButtonStyled>
                </FieldGroup>
              )}
            </FormRow>
          </LeadFormSection>
          {errorMessage && (
            <FormSection>
              <ErrorMessage
                text={t('application-error-message')}
                elementName={formName || 'form'}
              />
              <div ref={errorMessageRef} />
            </FormSection>
          )}
        </Form>
      </FormProvider>
      <LegalNoticeModal
        headerTitle={getModalHeaderTitle(privacyPolicy, termsOfUse)}
        content={getModalContent(privacyPolicy, termsOfUse)}
        mainButtonTitle={t('modal-age-confirmation-button-confirm')}
        closeModal={() => {
          setModalLegalNoticeOpen(false);
        }}
        isOpen={modalLegalNoticeOpen}
      />
    </>
  );
};

export default FormRenderer;
