import * as React from 'react';
import styled from 'styled-components';
import Icon, { IconStyled, IIcon } from '@adecco/base-app/src/components/atoms/Icon/Icon';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface ICircleIcon extends IIcon {
  background?: string;
}

interface IIconStyled {
  border?: string;
  background?: string;
}

const CircleIconStyled = styled.span<IIconStyled>`
  display: inline-block;
  width: 28px;
  height: 28px;
  border: ${(props) => props.theme.borders.circleIconWidth} solid
    ${(props) => props.border || props.theme.colors.textGrey};
  border-radius: ${(props) => props.theme.radius.rounded};
  background-color: ${(props) => (props.background ? props.background : 'transparent')};

  ${moreThan('md')} {
    width: 48px;
    height: 48px;

    ${IconStyled} {
      width: 24px;
      height: 24px;
    }
  }
`;

const IconContent = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const IconIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`;

const CircleIcon: React.FunctionComponent<ICircleIcon> = ({ background, ...props }) => (
  <CircleIconStyled border={props.fill} background={background}>
    <IconContent>
      <IconIcon {...props} />
    </IconContent>
  </CircleIconStyled>
);

export default CircleIcon;
