import { TranslationType } from '@adecco/base-app/src/gql/schema/generated';

export const transformLanguage = (translations: Array<TranslationType>) => {
  const transformedLanguage = {};
  translations.forEach((translation: TranslationType) => {
    // @ts-ignore
    transformedLanguage[translation.key] = translation.value;
  });

  return transformedLanguage;
};
