import { useRouter } from 'next/router';
import { useEffect } from 'react';
import getConfig from 'next/config';
import transformJobUrl from '@adecco/base-app/src/utilities/transformJobUrl';
import { routeNames } from '@adecco/base-app/src/constants/routeNames/routeNames';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRedirect = (data: any, routeType: string | null) => {
  const { isReady, query, push } = useRouter();
  const { id, detail } = query;
  const { publicRuntimeConfig } = getConfig();

  const currentCountry = publicRuntimeConfig.COUNTRY;
  const applyRoute = routeNames[currentCountry].applyRoute || '';
  const sentRoute = routeNames[currentCountry].applicationSentRoute || '';

  const transformedJobUrl = transformJobUrl(
    data?.jobad?.sourceJobId ?? '',
    data?.jobad?.occupation ?? '',
    data?.jobad?.jobLocation ?? '',
    data?.jobad?.kldbNumber ?? ''
  );

  useEffect(() => {
    if (isReady && `/job/${id}/${detail}` !== transformedJobUrl) {
      let pathname = '';
      if (routeType === 'applyRoute') {
        pathname = `${transformedJobUrl}/${applyRoute}`;
      } else if (routeType === 'sentRoute') {
        pathname = `${transformedJobUrl}/${sentRoute}`;
      } else {
        pathname = transformedJobUrl;
      }

      push(
        {
          pathname,
          query,
        },
        pathname
      );
    }
  }, [query, isReady]);
};
