import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

export const getModalContent = (privacyPolicy: boolean, termsOfUse: boolean) => {
  const { t } = useAppContext();

  let modalContent = null;
  if (!privacyPolicy && !termsOfUse) {
    modalContent = t('modal-privacy-age-confirmation-content');
  } else if (!termsOfUse) {
    modalContent = t('modal-age-confirmation-content');
  } else if (!privacyPolicy) {
    modalContent = t('modal-privacy-confirmation-content');
  }

  return modalContent;
};

export const getModalHeaderTitle = (privacyPolicy: boolean, termsOfUse: boolean) => {
  const { t } = useAppContext();

  let modalHeaderTiltle = null;
  if (!privacyPolicy && !termsOfUse) {
    modalHeaderTiltle = t('modal-privacy-age-confirmation-title');
  } else if (!termsOfUse) {
    modalHeaderTiltle = t('modal-age-confirmation-title');
  } else if (!privacyPolicy) {
    modalHeaderTiltle = t('modal-privacy-confirmation-title');
  }

  return modalHeaderTiltle;
};
