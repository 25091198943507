import * as React from 'react';
import styled from 'styled-components';
import { labeltext } from '@adecco/base-app/src/styles/typography';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import HelpText from '@adecco/base-app/src/components/atoms/HelpText/HelpText';

interface IFormRow {
  label?: string;
  name: string;
  required?: boolean;
  redStar?: boolean;
  children: React.ReactNode;
  className?: string;
  noPaddingBottom?: boolean;
  helpText?: string;
}

const FormFieldStyled = styled.div<{ noPaddingBottom?: boolean }>`
  width: 100%;
  padding-bottom: ${(props) => (props.noPaddingBottom ? 0 : props.theme.paddings.formField)};

  ${moreThan('md')} {
    padding-bottom: ${(props) =>
      props.noPaddingBottom ? 0 : props.theme.paddings.formFieldWideScreen};
  }
`;

const FormFieldLabel = styled.label`
  ${labeltext};
  display: block;
  padding-bottom: ${calcrem(8)};
  width: 100%;

  ${moreThan('md')} {
    height: ${(props) => `calc(${props.theme.fontSizes.label} + ${calcrem(8)})`};
  }
`;

interface IStar {
  red?: boolean;
}

export const RequiredStar = styled.span<IStar>`
  color: ${(props) => (props.red ? props.theme.colors.requiredStar : props.theme.colors.label)};
`;

const FormField: React.FunctionComponent<IFormRow> = ({
  label,
  name,
  required,
  redStar,
  children,
  className,
  noPaddingBottom,
  helpText,
}) => (
  <FormFieldStyled className={className} noPaddingBottom={noPaddingBottom}>
    {label ? (
      <FormFieldLabel htmlFor={name}>
        {label}
        {required && <RequiredStar red={redStar}> *</RequiredStar>}
        {helpText ? <HelpText text={helpText} /> : null}
      </FormFieldLabel>
    ) : null}
    {children}
  </FormFieldStyled>
);

export default FormField;
