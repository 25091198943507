import * as React from 'react';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import TextField from '@adecco/base-app/src/components/atoms/TextField/TextField';
import { emailPattern } from '@adecco/base-app/src/utilities/forms/regExPatterns';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { EmailInputType } from './FormElementRenderer.types';
import { configInputFields } from './configInputFields';

const EmailInput: React.FunctionComponent<EmailInputType> = ({
  type,
  register,
  errors,
  size,
  required = false,
  getValues,
}) => {
  const { t } = useAppContext();

  return (
    <FieldGroup size={1 / size}>
      {type === 'email' ? (
        <>
          <TextField
            label={t(configInputFields[type].label)}
            name={configInputFields[type].name}
            register={register(configInputFields[type].name, {
              required,
              pattern: emailPattern,
            })}
            error={!!errors[configInputFields[type].name]}
            required={required}
          />
          {errors[configInputFields[type].name] && (
            <ErrorMessage
              text={t(configInputFields[type].error)}
              elementName={configInputFields[type].name}
            />
          )}
        </>
      ) : null}
      {type === 'email-validation' ? (
        <>
          <TextField
            label={t(configInputFields[type].label)}
            name={configInputFields[type].name}
            register={register(configInputFields[type].name, {
              required,
              pattern: emailPattern,
              validate: {
                sameEmailadress: () => {
                  const values = getValues();
                  return values[configInputFields['email-validation'].name]
                    ? values[configInputFields.email.name]?.toLowerCase() ===
                        values[configInputFields['email-validation'].name]?.toLowerCase()
                    : true;
                },
              },
            })}
            error={!!errors[configInputFields[type].name]}
            required={required}
          />
          {errors[configInputFields[type].name] && (
            <ErrorMessage
              text={t(configInputFields[type].error)}
              elementName={configInputFields[type].name}
            />
          )}
        </>
      ) : null}
    </FieldGroup>
  );
};

export default EmailInput;
