import * as React from 'react';
import { LandingpageKldbType } from '@adecco/base-app/src/gql/schema/generated';
import LandingPageComponentRenderer from '@adecco/base-app/src/components/organisms/LandingPageComponentRenderer/LandingPageComponentRenderer';
import LandingPageHeader from '@adecco/base-app/src/components/organisms/LandingPageHeader/LandingPageHeader';

type ILandingPageKldb = LandingpageKldbType & {
  setFormIsDirty?: (value: boolean) => void;
};

const LandingPageKLDBApp: React.FunctionComponent<ILandingPageKldb> = ({
  title,
  subline,
  description,
  image,
  kldb,
  elements,
  cloudinaryImage,
  formType,
  sid,
  form,
  setFormIsDirty,
  kldblandingpagelocationsapplicationSet,
  buzzwords,
  monaAiId,
  monaAiPostingId,
}) => (
  <>
    <LandingPageHeader
      groupId={kldb}
      title={title}
      subline={subline}
      description={description}
      image={image}
      cloudinaryImage={cloudinaryImage}
      formType={formType}
      sid={sid}
      // @ts-ignore
      form={form}
      setFormIsDirty={setFormIsDirty}
      locations={[...kldblandingpagelocationsapplicationSet]}
      buzzwords={buzzwords}
      monaAiId={monaAiId}
      monaAiPostingId={monaAiPostingId}
    />
    <LandingPageComponentRenderer edges={elements?.edges ?? []} slug={title} />
  </>
);

export default LandingPageKLDBApp;
