import * as React from 'react';
import styled from 'styled-components';
import LogoButton from '@adecco/base-app/src/components/atoms/LogoButton/LogoButton';
import { IconType } from '@adecco/base-app/src/components/atoms/Icon/iconMap';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import Paragraph from '@adecco/base-app/src/components/atoms/Paragraph/Paragraph';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

export interface ILogoButtonItem {
  logo: IconType;
  alt: string;
  href: string;
}

export interface ILogoButton {
  items: Array<ILogoButtonItem>;
  centeredHeadline?: boolean;
}

const HeadlineStyled = styled(Headline)`
  font-size: ${(props) => props.theme.fontSizes.logoButtonHeadline};
  font-family: ${(props) => props.theme.fonts.HeadlineBold};
  line-height: ${(props) => props.theme.lineHeights.logoButtonHeadline};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.logoButtonHeadlineWideScreen};
    line-height: ${(props) => props.theme.lineHeights.logoButtonHeadlineWideScreen};
  }
`;

const LogoButtonStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: ${(props) => props.theme.margins.logoButton};
`;

const LogoButtonItem = styled.a`
  padding: 10px;
`;

const StyledParagraph = styled(Paragraph)`
  font-size: ${(props) => props.theme.fontSizes.logoButtonRowParagraph};
  line-height: ${(props) => props.theme.lineHeights.logoButtonRowParagraph};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.logoButtonRowParagraphWideScreen};
    line-height: ${(props) => props.theme.lineHeights.logoButtonRowParagraphWideScreen};
  }
`;

const LogoButtonRow: React.FunctionComponent<ILogoButton> = ({ items, centeredHeadline }) => {
  const { t } = useAppContext();
  return (
    <Container>
      <HeadlineStyled centered={centeredHeadline} skin={2}>
        {t('thank-you-panel-improvement-headline')}
      </HeadlineStyled>
      <StyledParagraph margin>{t('thank-you-panel-improvement-message')}</StyledParagraph>
      <LogoButtonStyled>
        {items.map(({ logo, alt, href }, index) => {
          return (
            <LogoButtonItem key={`item-${index}`} href={href} target="_blank" title={alt}>
              <LogoButton name={logo} />
            </LogoButtonItem>
          );
        })}
      </LogoButtonStyled>
    </Container>
  );
};
export default LogoButtonRow;
