const transformUrlSteps = (urlStep: string): string => {
  let url = urlStep
    .replace(/[,]|[/&()§]/g, '')
    .toLowerCase()
    .normalize('NFD');

  const replacements = {
    ß: 'ss',
    '\\.': '-',
    '\\s': '-',
    '-{2,}': '-',
    '[\u0300-\u036f]': '',
  };

  Object.keys(replacements).forEach((repl) => {
    const re = new RegExp(repl, 'g');
    // @ts-ignore
    url = url.replace(re, replacements[repl]);
  });

  return url;
};

const transformJobUrl = (
  sourceAdId: string,
  occupation: string,
  jobLocation: string,
  kldbNumber: string
): string =>
  `/job/${sourceAdId}/${transformUrlSteps(occupation)}-${transformUrlSteps(
    jobLocation
  )}-${kldbNumber}`;

export default transformJobUrl;
